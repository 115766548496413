import { getAllLeaderBoard } from "Api/leaderBoard";
import { Trophy } from "Assets/Images";
import { Button } from "Components";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Colors } from "Themes";

const LeaderBoard = () => {
  const [myScore, setMyScore] = useState(0);
  const [leaderBoards, setLeaderBoards] = useState([]);
  const [topThree, setTopThree] = useState([]);

  const navigate = useNavigate();

  const array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }

    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  const getLeaderBoard = async () => {
    const { my_score, leaderboard } = await getAllLeaderBoard();

    const formatTopThree = leaderboard.slice(0, 3).map((board, idx) => {
      const height = `${(board.score / leaderboard[0].score) * 100 - 13}%`;
      return {
        ...board,
        position: idx + 1,
        height
      };
    });

    setMyScore(my_score);
    setLeaderBoards(leaderboard.slice(3));
    setTopThree(array_move(formatTopThree, 0, 1));
  };

  useEffect(() => {
    getLeaderBoard();
  }, []);

  return (
    <section>
      <div className="flex items-center mb-4">
        <h1
          className="
          border-b-8
          text-xl
          border-ileads-yellow
          inline-block
          pl-1
          pr-2
        "
        >
          Leaderboard
        </h1>
        <Button
          className="font-bold !mr-0 !ml-5 font-normal"
          width={75}
          text="Kembali"
          onClick={() => navigate(-1)}
          bgColor={Colors.lightBlue}
          fontSize={14}
          paddingVertical={8}
          textColor={Colors.white}
        />
      </div>

      <section
        className={`
          rounded-2xl
          relative
          flex
          flex-col
          justify-center
          items-center
          bg-white
          shadow-card
          px-[160px]
          py-6
        `}
      >
        <p className="text-sm text-center leading-8 text-ileads-mainblue font-brandon">
          Tunjukan semangatmu dan nikmati berbagai macam hadiah! Tiga orang yang
          mendudukin peringkat <strong>#1, #2, dan #3</strong> akan mendapatkan
          hadiah menarik setiap bulannya. Perolehan poin akan di-update setiap
          hari.
        </p>

        <span className="bg-ileads-bgblue w-[355px] my-6 text-center rounded-full text-ileads-lightblue py-2">
          Poin kamu saat ini: {myScore || 0} poin!
        </span>

        <Link className="text-ileads-lightblue mb-10" to="/get-point">
          Cara mendapatkan poin.
          <span className="text-sm ml-1 w-6 h-6 bg-[#E0E0E0] inline-flex justify-center items-center rounded-full">
            ?
          </span>
        </Link>

        <div className="flex w-[300px] h-[161px]">
          {topThree.map((topUser, idx) => (
            <div
              className="w-[calc(100%/3)] h-full group flex flex-col justify-end"
              key={idx}
            >
              <span
                className={`block mb-1 text-xs text-center ${
                  topUser.position === 1
                    ? "text-ileads-green"
                    : "text-ileads-mainblue"
                }`}
              >
                {topUser.user_fullname}
              </span>
              <div
                className="
                  bg-ileads-yellow
                  group-first:rounded-br-none
                  group-first:rounded-r-none
                  group-last:rounded-bl-none
                  group-last:rounded-tl-none
                  group-even:rounded-br-none
                  group-even:rounded-bl-none
                  flex
                  py-6
                  flex-col
                  items-center
                  justify-center
                  rounded-xl
                "
                style={{ height: topUser.height }}
              >
                <span className="text-sm font-brandon_bold">
                  #{topUser.position}
                </span>
                {topUser.position === 1 && <img src={Trophy} alt="" />}
                <span className="text-xs font-brandon_bold">
                  {topUser.score} point
                </span>
              </div>
            </div>
          ))}
        </div>

        <table className="w-64 mt-8 border-collapse border-spacing-6">
          {leaderBoards.map((board, idx) => (
            <tr key={board.user_id}>
              <td className="py-2 w-12 text-sm font-brandon_bold">
                <strong>#{idx + 4}</strong>
              </td>
              <td className="py-2 w-20 text-xs font-brandon_bold">
                <strong>{board.score} poin</strong>
              </td>
              <td className="py-2 text-sm font-brandon">
                <span>{board.user_fullname}</span>
              </td>
            </tr>
          ))}
        </table>
      </section>
    </section>
  );
};

export default LeaderBoard;
