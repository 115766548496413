import styled from "styled-components";

import { Colors } from "../../Themes";

const Wrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

const TagWrapper = styled.div`
  position: absolute;
  background-color: ${Colors.mainBlue};
  height: 120px;
  padding: 10px;
  left:45vw;
  display: flex;
  align-items: flex-end;
  border-bottom-left-radius: 75px;
  border-bottom-right-radius: 75px;
`;

const FormWrapper = styled.div `
  padding: 30px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormInnerWrapper = styled.form `
  padding: 85px 97px 85px 97px;
`;

const Title = styled.p`
  font-size: 30px;
  font-family: 'Brandon-Medium';
  color: ${Colors.darkBlue};
  text-align: center;
  border-bottom: 7px solid ${Colors.yellow};
`;

const Subtitle = styled(Title)`
  font-size: 20px;
  color: ${Colors.mainBlue};
  border-bottom: 0px;
  margin-top: 25px;
  margin-bottom: 90px;
`;

const InputSpacer = styled.div`
  margin-top: 12px;
`;

const DropdownContainer = styled.div`
  width: 350px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const AddTeamBtnContainer = styled.div`
  margin-top: 40px;
  margin-left: 13px;
`;

const ButtonSpacer = styled.div`
  margin-top: 80px;
`;

const DeleteTeamWrapper = styled.div`
  position: absolute;
  margin-top: 28px;
  z-index: 100;
  display: flex;
  justify-content: flex-start;
`;

export {
  ButtonSpacer,
  Wrapper,
  TagWrapper,
  FormWrapper,
  FormInnerWrapper,
  Title,
  Subtitle,
  InputSpacer,
  DropdownContainer,
  RowContainer,
  AddTeamBtnContainer,
  DeleteTeamWrapper
};
