import { API } from "Api";
import { CalendarPen, HappyIcon } from "Assets/Images";
import { Button, CircularLoader, DropdownInput, Image, Modal, Underlined, Upload } from "Components";
import { Constants } from "Constants";
import { useYupValidationResolver } from "Hooks";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { UpdateJournalCoachSchema, UpdateJournalCoacheeSchema } from "Schemas";
import { Colors } from "Themes";
import { DropdownOptions } from "Utils";
import { ContentWrapper, DropdownContainer } from "./index.styles";
import "react-datepicker/dist/react-datepicker.css";
import { createJournal, getDetailJournal, updateJournal } from "Api/coachingJournal";
import { uploadAttachment } from "Api/upload";

const {
  CoachingJournalTypes,
  ErrorMessage: { GENERAL }
} = Constants;

export default function CoachingJournalNotesPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const alert = useAlert();
  const [needUpdate, setNeedUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [detail, setDetail] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [doneFeedback, setDoneFeedback] = useState(false);
  const resolver = useYupValidationResolver(UpdateJournalCoachSchema);
  const {
    control,
    formState: { isSubmitting, errors },
    setValue,
    getValues,
    handleSubmit,
    clearErrors
  } = useForm({ resolver });

  const handleBtnAction = () => {
    navigate("coachee", {
      state: {
        list: detail.jl_learner,
        learner: detail.jl_learner_fullname.map((name) => ({ jl_fullname: name })),
        title: detail.journal_title,
        date: detail.journal_date,
        isCoachee: state?.isCoachee
      }
    });
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const setJournalCoacheeData = (data) => {
    setValue("content", data.jl_content);
    setValue("lessonsLearned", data.jl_lesson_learned);
    setValue("recommendationForCoachee", data.journal_recommendation_for_coachee);
  };

  const setJournalCoachData = (data) => {
    setValue("content", data.journal_content);
    setValue("strength", data.journal_strength);
    setValue("improvement", data.journal_improvement);
    setValue("recommendationForCoachee", data.journal_recommendation_for_coachee);
    setValue("label", data.journal_label);
  };

  const setJournalData = (data) => {
    setValue("title", data.journal_title);
    setValue("date", data.journal_date);
    setValue("type", data.journal_type);

    if (state?.isCoachee) {
      setJournalCoacheeData(data);
    } else {
      setJournalCoachData(data);
    }
  };

  const toggleEdit = () => {
    setIsEditable(!isEditable);

    if (isEditable) {
      clearErrors();
      setJournalData(detail);
    }
  };

  const getJournalFeedback = async () => {
    try {
      const { data: responseData } = await getDetailJournal(state?.journalId);
      if (responseData.my_feedback && !Object.values(responseData.my_feedback).includes("")) {
        setDoneFeedback(true);
      }
    } catch (err) {
      setDoneFeedback(false);
    }
  };

  const getDetail = async () => {
    setLoading(true);
    try {
      const { data: responseData } = await getDetailJournal(state?.journalId);
      setFile(responseData?.journal_documents_url[0] || null);

      setDetail(responseData);
      setJournalData(responseData);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      setNeedUpdate(false);
    }
  };

  useEffect(() => {
    if (state?.title) {
      setValue("title", state.title);
      setValue("date", state.created_at);
      setValue("type", state.type);
    }
    // if (state?.isCoachee) return;
    getDetail();
    getJournalFeedback();
  }, [needUpdate, state, setValue]);

  const onSubmit = async (payload) => {
    try {
      if (file && typeof file !== "string") {
        const formData = new FormData();
        formData.append("files", file);
        const { urls } = await uploadAttachment(formData);
        setValue("documentsUrl", urls);
      }

      await updateJournal(state?.journalId, {
        ...payload,
        documentsUrl: getValues().documentsUrl
      });
      setShowModal(true);
    } catch (err) {
      alert.show(err.response.data.message || GENERAL);
    } finally {
      setNeedUpdate(true);
      setIsEditable(false);
    }
  };

  const renderLoader = () => (
    <div className="flex items-center justify-center my-12">
      <CircularLoader size={30} color={Colors.yellow} unfilledColor={Colors.darkBlue} />
    </div>
  );

  const renderHeader = () => (
    <div className="mb-3">
      <div className="flex items-center w-full">
        <div className="mr-6">
          <Underlined>Coaching Journal Overview</Underlined>
        </div>
        <Button
          className="!mx-0"
          fontSize={20}
          text={isEditable ? "Batal" : "Edit"}
          width={70}
          paddingVertical={8}
          height={34}
          bgColor={isEditable ? Colors.red : Colors.lightBlue}
          onClick={toggleEdit}
        />
        <Button
          className="!mr-0 ml-auto"
          fontSize={20}
          text="Kembali"
          width={82}
          paddingVertical={8}
          height={34}
          bgColor={Colors.lightBlue}
          onClick={handleNavigateBack}
        />
      </div>
    </div>
  );

  const renderCoachForm = () => (
    <>
      <span className="text-ileads-mainblue font-brandon_bold text-lg">
        Dari sesi coaching, apa yang sudah coachee lakukan dengan <span className="text-ileads-lightblue">efektif</span>
        ?
      </span>
      <Controller
        name="strength"
        control={control}
        rules={{ maxLength: 2500 }}
        render={({ field }) => (
          <>
            <div
              className={`textarea-${errors["strength"] ? "error" : "primary"} bg-ileads-${
                isEditable ? "white" : "bgblue"
              } flex items-center space-x-1.5 px-2 mt-2 mb-1`}
            >
              <textarea
                cols="40"
                rows="3"
                value={field.value}
                onChange={(e) => {
                  if (e.target.value.length <= 2500) field.onChange(e);
                  else field.onChange(e.target.value.slice(0, 2500));
                }}
                className="input-password-custom text-left text-ileads-mainblue w-full text-ellipsis font-brandon"
                name="strength"
                disabled={!isEditable}
              />
            </div>
            <div className="flex items-end justify-end mb-3">
              <p className="font-brandon text-[11px]">{field.value?.length || 0}/2500</p>
            </div>
          </>
        )}
      />
      <span className="text-ileads-mainblue font-brandon_bold text-lg">
        Dari sesi coaching, kualitas apa yang dapat coachee <span className="text-ileads-lightblue">tingkatkan</span>{" "}
        lagi?
      </span>
      <Controller
        name="improvement"
        control={control}
        rules={{ maxLength: 2500 }}
        render={({ field }) => (
          <>
            <div
              className={`textarea-${errors["improvement"] ? "error" : "primary"} bg-ileads-${
                isEditable ? "white" : "bgblue"
              } flex items-center space-x-1.5 px-2 mt-2 mb-1`}
            >
              <textarea
                cols="40"
                rows="3"
                value={field.value}
                onChange={(e) => {
                  if (e.target.value.length <= 2500) field.onChange(e);
                  else field.onChange(e.target.value.slice(0, 2500));
                }}
                className="input-password-custom text-left text-ileads-mainblue w-full text-ellipsis font-brandon"
                name="improvement"
                disabled={!isEditable}
              />
            </div>
            <div className="flex items-end justify-end mb-3">
              <p className="font-brandon text-[11px]">{field.value?.length || 0}/2500</p>
            </div>
          </>
        )}
      />
    </>
  );

  const renderCoacheeForm = () => (
    <>
      <span className={`font-brandon_bold text-ileads-${errors["lessonsLearned"] ? "red" : "mainblue"} text-lg`}>
        Tulislah “
        <span className={`text-ileads-${errors["lessonsLearned"] ? "red" : "lightblue"} `}>lessons learned</span>”-mu di
        coaching session ini.
      </span>
      <Controller
        name="lessonsLearned"
        control={control}
        rules={{ maxLength: 2500 }}
        render={({ field }) => (
          <>
            <div
              className={`textarea-${errors["lessonsLearned"] ? "error" : "primary"} bg-ileads-${
                isEditable ? "white" : "bgblue"
              } flex items-center space-x-1.5 px-2 mt-2 mb-1`}
            >
              <textarea
                cols="40"
                rows="3"
                value={field.value}
                onChange={(e) => {
                  if (e.target.value.length <= 2500) field.onChange(e);
                  else field.onChange(e.target.value.slice(0, 2500));
                }}
                className="input-password-custom text-left text-ileads-mainblue w-full text-ellipsis font-brandon"
                name="lessonsLearned"
                disabled={!isEditable}
              />
            </div>
            <div className="flex items-end justify-end mb-3">
              <p className="font-brandon text-[11px]">{field.value?.length || 0}/2500</p>
            </div>
          </>
        )}
      />
    </>
  );

  const renderJournalContent = () => (
    <>
      <span className="text-ileads-mainblue font-brandon_bold text-lg">
        Apa yang <span className="text-ileads-lightblue">dibicarakan</span> saat coaching?
      </span>
      <Controller
        name="content"
        control={control}
        rules={{ maxLength: 2500 }}
        render={({ field }) => (
          <>
            <div
              className={`textarea-${errors["content"] ? "error" : "primary"} bg-ileads-${
                isEditable ? "white" : "bgblue"
              } flex items-center space-x-1.5 px-2 mt-2 mb-1`}
            >
              <textarea
                cols="40"
                rows="3"
                value={field.value}
                onChange={(e) => {
                  if (e.target.value.length <= 2500) field.onChange(e);
                  else field.onChange(e.target.value.slice(0, 2500));
                }}
                className="input-password-custom text-left text-ileads-mainblue w-full text-ellipsis font-brandon"
                name="content"
                disabled={!isEditable}
              />
            </div>
            <div className="flex items-end justify-end mb-3">
              <p className="font-brandon text-[11px]">{field.value?.length || 0}/2500</p>
            </div>
            <Upload
              onChange={(value) => {
                if (!(value instanceof File)) setValue("documentsUrl", "");
                setFile(value);
              }}
              onError={() => {}}
              isDownload={!isEditable}
              disabled={(state?.isCoachee || (!detail?.journal_documents_url?.length && !isEditable)) && !state.title}
              value={detail?.journal_documents_url}
              max={2}
              accept=".docx, .pdf, .xls, .xlsx, .ppt, .pptx"
              fileDesc=".docx, .pdf, .xls, dan .ppt"
              // disabled={!isEditable}
            />
          </>
        )}
      />
    </>
  );

  const renderJournalCommitment = () => (
    <>
      <span className="text-ileads-mainblue font-brandon_bold text-lg">
        Dari sesi coaching, <span className="text-ileads-lightblue">apa rekomendasi saya untuk coachee</span>?
      </span>
      <Controller
        name="recommendationForCoachee"
        control={control}
        rules={{ maxLength: 2500 }}
        render={({ field }) => (
          <>
            <div
              className={`textarea-${errors["recommendationForCoachee"] ? "error" : "primary"} bg-ileads-${
                isEditable ? "white" : "bgblue"
              } flex items-center space-x-1.5 px-2 mt-2 mb-1`}
            >
              <textarea
                cols="40"
                rows="3"
                value={field.value}
                onChange={(e) => {
                  if (e.target.value.length <= 2500) field.onChange(e);
                  else field.onChange(e.target.value.slice(0, 2500));
                }}
                className="input-password-custom text-left text-ileads-mainblue w-full text-ellipsis font-brandon"
                name="recommendationForCoachee"
                disabled={!isEditable}
              />
            </div>
            <div className="flex items-end justify-end mb-3">
              <p className="font-brandon text-[11px]">{field.value?.length || 0}/2500</p>
            </div>
          </>
        )}
      />
    </>
  );

  const renderJournalType = () => (
    <>
      <span className="font-brandon_bold text-ileads-mainblue text-lg">Pilih kategori coaching:</span>
      <Controller
        name="type"
        control={control}
        render={({ field }) => (
          <>
            {isEditable && !state.title ? (
              <DropdownContainer>
                <DropdownInput
                  type={"secondary"}
                  options={DropdownOptions.convertToOptions(CoachingJournalTypes, "name", "key")}
                  value={field.value}
                  onSelect={(objValue) => {
                    field.onChange(objValue.value);
                  }}
                  name={"type"}
                  placeholder={field.value}
                  wrapperClassName="w-60"
                  textClassName="input-password-custom"
                  fontSize={16}
                />
              </DropdownContainer>
            ) : (
              <div className="input-primary bg-ileads-bgblue flex items-center space-x-1.5 px-2 w-60 mt-2">
                <span className="input-password-custom text-center text-ileads-mainblue w-full text-ellipsis font-brandon">
                  {field.value}
                </span>
              </div>
            )}
            {field.value === "Others" && (
              <Controller
                name="label"
                control={control}
                render={({ field: label }) => (
                  <div className="flex flex-row mt-2 mb-6 w-60">
                    <div
                      className={`input-${
                        errors["label"] ? "error" : "primary"
                      } flex items-center space-x-1.5 px-4 w-96 bg-ileads-bgblue`}
                    >
                      <input
                        disabled
                        value={label.value}
                        placeholder="Tulis kategori coaching disini."
                        onChange={label.onChange}
                        className="input-password-custom text-left text-ileads-mainblue w-full text-ellipsis font-brandon"
                        name="label"
                      />
                    </div>
                  </div>
                )}
              />
            )}
          </>
        )}
      />
    </>
  );

  return (
    <div>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        title="Sukses!"
        desc="Catatan telah sukses diedit!"
        icon={<img className="w-[160px]" src={HappyIcon} alt="" />}
        buttons={
          <Button
            type="button"
            onClick={() => setIsEditable(false)}
            text="Kembali ke Catatan"
            bgColor={Colors.green}
            width={300}
            paddingVertical={12}
            fontSize={20}
          />
        }
      />
      {renderHeader()}
      <ContentWrapper
        className={`border-2 ${Object.keys(errors).length ? "border-ileads-danger-3" : "border-transparent"}`}
        onSubmit={handleSubmit(onSubmit)}
      >
        {Object.keys(errors)}
        {!!Object.keys(errors).length && (
          <h3 className="text-center text-ileads-danger-3 mb-2">
            Ups! Sepertinya ada kolom yang belum diisi! Silahkan dicek kembali dan isi semua kolom yang tersedia!
          </h3>
        )}
        {loading ? (
          renderLoader()
        ) : (
          <>
            <span className="font-brandon_bold text-ileads-mainblue text-lg">
              Tuliskan <span className="text-ileads-lightblue">judul</span> sesi coaching
            </span>
            <div className="flex flex-row mt-2 mb-6">
              <div className="input-primary bg-ileads-bgblue flex items-center space-x-1.5 px-2 w-60">
                <Controller
                  name="title"
                  control={control}
                  render={({ field }) => (
                    <input
                      value={field.value}
                      onChange={field.onChange}
                      className="input-password-custom text-center text-ileads-mainblue w-full text-ellipsis font-brandon"
                      disabled={true}
                      name="title"
                    />
                  )}
                />
              </div>
              <div className="ml-4 items-center justify-center flex">
                <span className="text-ileads-mainblue">dengan</span>
              </div>
              <div className="my-auto ml-4">
                <span className="text-ileads-mainblue font-brandon">
                  {state?.learner.map((item) => item?.jl_fullname).join(", ")}
                </span>
              </div>
            </div>
            <span className="font-brandon_bold text-ileads-mainblue text-lg">
              Pilih <span className="text-ileads-lightblue">tanggal</span> sesi coaching
            </span>
            <div className="flex flex-row mt-2 mb-6">
              <div className="my-auto mr-2">
                <Image src={CalendarPen} width={23} height={23} />
              </div>
              <div className="input-primary bg-ileads-bgblue flex items-center space-x-1.5 px-2 w-60">
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <div className="input-password-custom text-center text-ileads-mainblue w-full cursor-pointer">
                      <DatePicker
                        disabled={true}
                        selected={field.value ? new Date(field.value) : new Date()}
                        onChange={field.onChange}
                        customInput={<span className="font-brandon">{moment(field.value).format("DD MMMM YYYY")}</span>}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
            {renderJournalContent()}
            {renderCoachForm()}
            {renderJournalCommitment()}
            {renderJournalType()}
            {state?.isCoachee ? (
              <>
                {!doneFeedback && (
                  <div className={`mb-12 mt-${Object.keys(errors).length ? 4 : 24} flex items-center justify-center`}>
                    <button
                      type={!isEditable ? "button" : "submit"}
                      onClick={() => {
                        if (!isEditable) {
                          handleBtnAction();
                        }
                      }}
                      className={`bg-ileads-${
                        isEditable || state?.isCoachee ? "green" : "lightblue"
                      } text-white text-center py-1.5 rounded-btn px-4 whitespace-nowrap cursor-pointer`}
                    >
                      {isEditable && isSubmitting && (
                        <div className="px-12 py-1">
                          <CircularLoader size={20} color={Colors.green} unfilledColor={Colors.white} />
                        </div>
                      )}
                      {!isSubmitting && (
                        <span className="font-brandon_medium">
                          {isEditable
                            ? "Simpan Perubahan"
                            : state?.isCoachee
                            ? "Lakukan Feedback"
                            : "Lihat Catatan Coachee"}
                        </span>
                      )}
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div className={`mb-12 mt-${Object.keys(errors).length ? 4 : 24} flex items-center justify-center`}>
                <button
                  type={!isEditable ? "button" : "submit"}
                  onClick={() => {
                    if (!isEditable) {
                      handleBtnAction();
                    }
                  }}
                  className={`bg-ileads-${
                    isEditable || state?.isCoachee ? "green" : "lightblue"
                  } text-white text-center py-1.5 rounded-btn px-4 whitespace-nowrap cursor-pointer`}
                >
                  {isEditable && isSubmitting && (
                    <div className="px-12 py-1">
                      <CircularLoader size={20} color={Colors.green} unfilledColor={Colors.white} />
                    </div>
                  )}
                  {!isSubmitting && (
                    <span className="font-brandon_medium">
                      {isEditable
                        ? "Simpan Perubahan"
                        : state?.isCoachee
                        ? "Lakukan Feedback"
                        : "Lihat Catatan Coachee"}
                    </span>
                  )}
                </button>
              </div>
            )}
          </>
        )}
      </ContentWrapper>
    </div>
  );
}
