import { Button } from "Components";
import { Constants } from "Constants";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Colors } from "Themes";

const GetPoint = () => {
  const navigate = useNavigate();

  const renderPoints = (lists) => {
    return (
      <>
        <span className="bg-ileads-bgblue px-3 rounded-full text-ileads-lightblue py-2">
          Coaching activities.
        </span>
        <ul>
          {lists.map((list, idx) => (
            <li
              key={idx}
              className="flex items-center justify-between mb-3 last:mb-0"
            >
              <span className="text-ileads-mainblue text-sm">{list.title}</span>
              <Button
                className="!mr-0 !ml-5 font-normal whitespace-nowrap px-4"
                text={list.value}
                onClick={() => navigate(-1)}
                bgColor={Colors.green}
                fontSize={14}
                paddingVertical={5}
                textColor={Colors.white}
              />
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <section>
      <div className="flex items-center mb-4">
        <h1
          className="
          border-b-8
          text-xl
          border-ileads-yellow
          inline-block
          pl-1
          pr-2
        "
        >
          Cara Mendapatkan Poin
        </h1>
        <Button
          className="!mr-0 !ml-5 font-normal"
          width={75}
          text="Kembali"
          onClick={() => navigate(-1)}
          bgColor={Colors.lightBlue}
          fontSize={14}
          paddingVertical={8}
          textColor={Colors.white}
        />
      </div>

      <section
        className={`
          rounded-2xl
          relative
          flex
          flex-col
          justify-center
          items-center
          bg-white
          shadow-card
          py-10
        `}
      >
        <div className="w-[355px] mx-auto flex flex-col gap-y-6">
          {renderPoints(Constants.coachingActivitiesPoint)}
          {renderPoints(Constants.feedPostPoint)}
        </div>
      </section>
    </section>
  );
};

export default GetPoint;
