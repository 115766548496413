import React from "react";

import {
  ErrorMessage,
  EyeIconContainer,
  Label,
  RowContainer,
  StyledInput
} from "./index.styles";
import { Image } from "..";
import { EyeClose, EyeOpen } from "../../Assets/Images";
import { Constants } from "../../Constants";

const { InputType: { PASSWORD, TEXT } } = Constants;

export default function Input({
  type,
  label,
  inputWidth,
  value,
  onChange,
  name,
  error
}) {
  const [inputType, setInputType] = React.useState(type);

  const handleSwitchInputType = () => {
    if (inputType === TEXT) {
      setInputType(type);
    } else {
      setInputType(TEXT);
    }
  };

  return (
    <div>
      <Label>{ label }</Label>
      <RowContainer>
        <StyledInput
          type= { inputType || TEXT }
          inputWidth={ inputWidth }
          value={ value }
          onChange={ onChange }
          name={ name }
          error={ error }
        />
        { type === PASSWORD && (
          <EyeIconContainer onClick={ handleSwitchInputType }>
            <Image
              src={
                inputType === TEXT ?
                  EyeOpen : EyeClose
              }
              width={ 20 }
              height={ 20 }
            />
          </EyeIconContainer>
        ) }
      </RowContainer>
      { error && <ErrorMessage>{ error?.message }</ErrorMessage> }
    </div>
  );
}
