import Cup from "Assets/Icons/group-cup.svg";
import Jeruk from "Assets/Icons/group-jeruk.svg";
import Pizza from "Assets/Icons/group-pizza.svg";
import Shoe from "Assets/Icons/group-shoe.svg";
import Tree from "Assets/Icons/group-tree.svg";
import { HappyIcon, SadIcon, SickIcon, SurprisedIcon } from "Assets/Images";

import { positions, transitions } from "react-alert";

export const Constants = {
  AlertOptions: {
    offset: "30px",
    position: positions.TOP_RIGHT,
    timeout: 3000,
    transition: transitions.FADE,
    type: "error"
  },
  ErrorMessage: {
    GENERAL: "Please Try Again"
  },
  InputType: {
    PASSWORD: "password",
    TEXT: "text"
  },
  UserPositions: [
    {
      id: "cp",
      name: "Counter Part (CP)"
    },
    {
      id: "cl",
      name: "Culture Leader (CL)"
    },
    {
      id: "ca",
      name: "Culture Agent (CA)"
    },
    {
      id: "nwt",
      name: "Non Winning Team (NWT)",
    }
  ],
  CoachingJournalTypes: [
    {
      id: 0,
      key: "KPI coaching",
      name: "KPI coaching"
    },
    {
      id: 0,
      key: "Project Culture coaching",
      name: "Project Culture coaching"
    },
    {
      id: 0,
      key: "Others",
      name: "Others"
    }
  ],
  groupIcons: [
    {
      icon: Jeruk,
      name: "jeruk"
    },
    {
      icon: Cup,
      name: "cup"
    },
    {
      icon: Pizza,
      name: "pizza"
    },
    {
      icon: Shoe,
      name: "shoe"
    },
    {
      icon: Tree,
      name: "tree"
    }
  ],
  moodTracker: [
    {
      icon: HappyIcon,
      title: "Senang"
    },
    {
      icon: SadIcon,
      title: "Sedih"
    },
    {
      icon: SurprisedIcon,
      title: "Terkejut"
    },
    {
      icon: SickIcon,
      title: "Sakit"
    }
  ],
  coachingActivitiesPoint: [
    {
      title: "Tambah coaching journal.",
      value: "+2"
    },
    {
      title: "Penilaian feedback dari coachee.",
      value: "Average score"
    },
    {
      title:
        "Rata-rata dari self-reflection feedback dan feedback dari coachee.",
      value: "Average score"
    }
  ],
  feedPostPoint: [
    {
      title: "Membuat post di Feed.",
      value: "+2"
    },
    {
      title: "Jumlah komen dari rekan kerja lain di post Feed",
      value: "(+1/komen)"
    },
    {
      title:
        "Jumlah komen yang dikirimkan di post Feed yang dibuat oleh rekan  kerja lain",
      value: "(+1/komen)"
    }
  ],
  feedGuidlines: [
    {
      title: "Apa saja konten yang dapat dipublikasikan pada forum feed?",
      rules: [
        "Kegiatan yang menunjukan dirimu sebagai  seorangJUARA. Apapun kegiatan kamu  yang selaras dengan 4 prinsip Budaya Juara dapat di -post.",
        "Aktivitas rekan kerjamu yang selaras dengan 4 prinsip Budaya Juara.Saling meninggalkan komentar yang positif dan membangun kepada sesama rekan kerja. Karena mendapatkan dukungan dari rekan kerja tuh berarti banget, ya gak?!"
      ]
    },
    {
      title: "Apa saja yang tidak diperkenankan di dalam forum Feed?",
      rules: [
        "Semua konten yang berhubungan dengan SARA (Suku, Agama, Ras, dan Antargolongan) tidak diperbolehkan di dalam feed.",
        "Konten yang tidak mengandung 4 prinsip Budaya Juara dan menginspirasi orang lain untuk lebih baik."
      ],
      extra:
        "Konten atau komentar yang merendahkan, mengadu domba, dan fitnah tidak diperbolehkan. Berilah kritik atau komentar yang membangun sehingga diskusi menjadi lebih seru, menguntungkan, dan menginspirasi."
    },
    {
      title:
        "Team iLEAD berhak untuk menghapus konten dan memblokir user dalam jangka waktu tertentu. Yuk kita patuhi ground rules-nya!",
      rules: []
    }
  ]
};
