/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { createFeed, getAllFeeds, getFeedCategories } from "Api/feed";
import { uploadAttachment } from "Api/upload";
import CloseIcon from "Assets/Icons/close-icon.svg";
import {
  DropdownArrowDown,
  DropdownArrowUp,
  ImageUploadIcon,
  PlayIcon,
  VideoUploadIcon
} from "Assets/Images";
import { PostCard } from "Components";
import Image from "Components/Image";
import { useUsers } from "Context/UsersStateManagement";
import { useInfiniteScroll, useOnClickOutside } from "Hooks";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";


const Feed = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [message, setMessage] = useState("");
  const [filesData, setFilesData] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [feeds, setFeeds] = useState([]);
  const [dataInfo, setDataInfo] = useState({});
  const [acceptType, setAcceptType] = useState("");
  const [openDropdown, setOpenDropdown] = useState(false);
  const [videoPlayed, setVideoPlayed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const { loadMoreRef, page, setPage } = useInfiniteScroll(
    0,
    dataInfo.total_pages,
    isLoading
  );
  const ref = useRef(null);
  const videoRef = useRef(null);
  const fileRef = useRef(null);
  const alertComponent = useAlert();
  const { user, feedDeleted } = useUsers();
  useOnClickOutside(ref, () => setOpenDropdown(false));

  const dropdownOptionClasses = `
    transition-all
    absolute
    border-2
    border-ileads-lightblue
    px-4
    py-3
    bg-white
    rounded-br-2xl
    w-[101.1%]
    top-10
    -left-[2px]
    rounded-bl-2xl
    ${openDropdown ? "opacity-100" : "opacity-0 -translate-y-10 -scale-y-50"}
  `;

  const handleOpenFile = (type) => {
    if (type === "video") setAcceptType("video/*");
    else setAcceptType("image/*");
  };

  const handleControlVideo = () => {
    setVideoPlayed(!videoPlayed);
    if (videoRef.current.paused) videoRef.current.play();
    else videoRef.current.pause();
  };

  const handleDeleteFile = (selected) => {
    setFilesData(filesData.filter((file) => file !== selected));
  };

  const formatFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => resolve({ file, preview: reader.result });
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileChange = async ({ target }) => {
    const { files: listFiles } = target;
    const files = Array.from(listFiles);
    const tmpFiles = [];

    if (!files.some((file) => /(image|video)/.test(file.type))) return;

    for (const file of files) {
      const dataFile = await formatFile(file);
      tmpFiles.push(dataFile);
    }

    if (acceptType.includes("video")) {
      setVideoPlayed(false);
      setFilesData(tmpFiles);
    }
    if (acceptType.includes("image") && filesData.length !== 3)
      setFilesData([
        ...filesData.filter((file) => file.preview.includes("image")),
        ...tmpFiles
      ]);

    setAcceptType("");
  };

  const handleCreateFeed = async () => {
    const fd = new FormData();
    const files = [];
    let images_url;

    for (const { file } of filesData) {
      files.push(file);
      fd.append("files", file);
    }

    try {
      setIsUploading(true);
      if (files.length) images_url = await uploadAttachment(fd);
      await createFeed({
        description: message,
        images_url: images_url ? images_url.urls : "",
        type_id: selectedCategory.feed_type_id
      });

      alertComponent.success("Feed berhasil dibuat!");

      setSelectedCategory(null);
      setMessage("");
      setFilesData([]);

      const { data } = await getAllFeeds({ sort: "desc", limit: 10 });

      setFeeds([...data, ...feeds]);
    } catch (error) {
      alertComponent.show(error.response.data.message);
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    if (fileRef?.current && acceptType) fileRef.current.click();
  }, [acceptType]);

  useEffect(() => {
    const getCategoryData = async () => {
      try {
        setIsLoading(true);
        const data = await getFeedCategories();
        const { meta } = await getAllFeeds({ sort: "desc", limit: 10 });
        setDataInfo(meta);

        setCategoryOptions(data);
      } catch (error) {
        alertComponent.show(error.response.data.message);
      } finally {
        setIsLoading(false);
      }
    };

    getCategoryData();
  }, []);

  useEffect(() => {
    if (!page) return;

    const getFeedData = async () => {
      try {
        setIsLoading(true);
        const { data } = await getAllFeeds({ sort: "desc", limit: 10, page });

        setFeeds([...feeds, ...data]);
      } catch (error) {
        alertComponent.show(error.response.data.message);
      } finally {
        setIsLoading(false);
      }
    };

    getFeedData();
  }, [page]);

  useEffect(() => {
    if (!feedDeleted) return;

    const getFeedData = async () => {
      try {
        setIsLoading(true);
        const { data } = await getAllFeeds({ sort: "desc", limit: 10, page: 1 });
        setPage(1);
        setFeeds(data);
      } catch (error) {
        alertComponent.show(error.response.data.message);
      } finally {
        setIsLoading(false);
      }
    };

    getFeedData();
  }, [feedDeleted]);

  return (
    <section>
      <h1
        className="
          border-b-8
          text-xl
          border-ileads-yellow
          inline-block
          pl-1
          pr-2
          mb-4
        "
      >
        Feed
      </h1>

      <div className="feed_uploader p-5 bg-white rounded-2xl shadow-card">
        <textarea
          className="
            w-full
            px-4
            py-2
            text-lg
            bg-ileads-bgblue
            border-none
            focus:outline-none
            resize-none
            rounded-2xl
            h-40
          "
          maxLength={500}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Mau cerita tentang apa nih?"
        />
        <div className="flex items-end justify-end mb-[10px]">
          <p className="font-brandon text-[11px]">{message.length || 0}/500</p>
        </div>

        <div className="flex justify-between items-center">
          <div className="flex gap-x-2 items-center">
            <input
              type="file"
              className="hidden"
              ref={fileRef}
              accept={acceptType}
              multiple={!acceptType.includes("video")}
              onChange={handleFileChange}
            />
            <button
              onClick={() => handleOpenFile("image")}
              className="rounded-md w-[60px] h-[60px] transition-opacity hover:opacity-50 bg-ileads-green"
            >
              <img className="mx-auto" src={ImageUploadIcon} alt="" />
            </button>
            <button
              onClick={() => handleOpenFile("video")}
              className="rounded-md w-[60px] h-[60px] transition-opacity hover:opacity-50 bg-ileads-lightblue"
            >
              <img className="mx-auto" src={VideoUploadIcon} alt="" />
            </button>
            {filesData.map((file, idx) => (
              <div className="relative" key={idx}>
                {file.preview.includes("image") ? (
                  <img
                    className="w-[72px] h-[50px] rounded-md object-cover contrast-50"
                    src={file.preview}
                    alt=""
                  />
                ) : (
                  <div className="relative" onClick={handleControlVideo}>
                    <img
                      className={`transition-all absolute z-10 cursor-pointer w-5 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 ${
                        !videoPlayed ? "block" : "hidden"
                      }`}
                      src={PlayIcon}
                      alt=""
                    />
                    <video
                      onEnded={() => setVideoPlayed(false)}
                      ref={videoRef}
                      src={file.preview}
                      className={`transition-all w-[72px] h-[50px] object-cover rounded-md ${
                        !videoPlayed ? "contrast-50" : ""
                      }`}
                    />
                  </div>
                )}

                <button
                  className="
                    w-[22px]
                    h-[22px]
                    flex
                    items-center
                    justify-center
                    rounded-full
                    absolute
                    -bottom-2
                    -right-2 bg-ileads-danger-3
                  "
                  onClick={() => handleDeleteFile(file)}
                >
                  <Image src={CloseIcon} />
                </button>
              </div>
            ))}
          </div>

          <button
            onClick={handleCreateFeed}
            disabled={!message.length || !selectedCategory || isUploading}
            className={`transition-colors rounded-full text-white px-5 py-2 text-xl h-fit ${
              !message.length || !selectedCategory || isUploading
                ? "bg-gray-200"
                : "bg-ileads-green"
            }`}
          >
            {isUploading ? "Uploading..." : "Update"}
          </button>

          <div
            ref={ref}
            onClick={() => setOpenDropdown(!openDropdown)}
            className={`transition-all relative min-w-[363px] border-2 border-ileads-lightblue bg-white ${
              openDropdown ? "rounded-tl-2xl rounded-tr-2xl" : "rounded-2xl"
            } py-[10px]`}
          >
            <span className="text-sm text-center block text-[#BDBDBD]">
              {selectedCategory
                ? selectedCategory.feed_type_name
                : "Pilih kategori"}
            </span>
            <img
              className="absolute top-3 w-5 right-2"
              src={openDropdown ? DropdownArrowUp : DropdownArrowDown}
              alt=""
            />

            <ul className={dropdownOptionClasses}>
              {categoryOptions.map((category) => (
                <li
                  key={category.feed_type_id}
                  onClick={() => {
                    setSelectedCategory(
                      selectedCategory?.feed_type_id !== category.feed_type_id
                        ? category
                        : null
                    );
                    setOpenDropdown(false);
                  }}
                  className={`cursor-pointer transition-all w-fit block py-1 px-2 ${
                    category.feed_type_id === selectedCategory?.feed_type_id
                      ? "bg-ileads-green text-white py-1 px-2 rounded-full"
                      : ""
                  }`}
                >
                  {category.feed_type_name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <article
        className="p-5 bg-white rounded-2xl shadow-card mt-5"
        id="feed-container"
      >
        <span
          className="
            text-sm
            px-4
            py-[10px]
            border-2
            border-ileads-lightblue
            bg-ileads-bgblue
            rounded-xl block w-fit mx-auto mb-12
            font-brandon
          "
        >
          Sebelum posting, lihat dulu yuk konten apa yang bisa di-post di Feed!{" "}
          <Link to="/feeds/guideline" className="font-brandon_bold">
            Baca guideline Feed.
          </Link>
        </span>

        {feeds.map((feed) => (
          <PostCard
            canDelete={user.user_id === feed.feed_author_id}
            post={feed}
            key={feed.feed_id}
          />
        ))}

        {isLoading && (
          <p className="flex items-center mt-20 mb-5 justify-center text-ileads-mainblue">
            <span className="flex h-6 w-6 items-center justify-center relative mr-2">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-ileads-lightblue opacity-75" />
              <span className="relative inline-flex rounded-full h-4 w-4 bg-ileads-mainblue" />
            </span>
            Loading more feeds...
          </p>
        )}

        <div ref={loadMoreRef} />
      </article>
    </section>
  );
};

export default Feed;
