export const StorageAPI = {
  getItem: key => {
    return localStorage.getItem(key);
  },
  removeItem: key => {
    return localStorage.removeItem(key);
  },
  setItem: (key, value) => {
    return localStorage.setItem(key, value);
  }
};
