import React from "react";

import { LoaderWrapper, StyledButton } from "./index.styles";
import { CircularLoader } from "..";

export default function Button({
  type,
  text,
  textColor,
  onClick,
  bgColor,
  width,
  height,
  paddingVertical,
  fontSize,
  borderColor,
  isLoading,
  disabled,
  className,
  loaderMainColor
}) {
  return (
    <StyledButton
      className={`${className} ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
      disabled={isLoading || disabled}
      type={type}
      onClick={onClick}
      bgColor={bgColor}
      textColor={textColor}
      width={width}
      height={height}
      fontSize={fontSize}
      borderColor={borderColor}
      paddingVertical={paddingVertical}
    >
      {!isLoading ? (
        <span className="font-brandon_bold">{text}</span>
      ) : (
        <LoaderWrapper>
          <CircularLoader size={fontSize} color={loaderMainColor} unfilledColor={bgColor} />
        </LoaderWrapper>
      )}
    </StyledButton>
  );
}
