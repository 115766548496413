import React from "react";
import { useAlert } from "react-alert";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  ButtonSpacer,
  FormInnerWrapper,
  FormWrapper,
  HintOuterWrapper,
  Subtitle,
  TagWrapper,
  Title,
  Wrapper
} from "./index.styles";
import { API } from "../../Api";
import { PlaneIcon } from "../../Assets/Images";
import { AuthHeader, AuthHint, Button, Card, Image, Input } from "../../Components";
import { Constants } from "../../Constants";
import { useYupValidationResolver } from "../../Hooks";
import { ForgotPasswordSchema } from "../../Schemas";
import { Colors } from "../../Themes";

const {
  ErrorMessage: { GENERAL }
} = Constants;

export default function ForgotPasswordPage({ page }) {
  const navigate = useNavigate();
  const alert = useAlert();
  const resolver = useYupValidationResolver(ForgotPasswordSchema);
  const {
    control,
    formState : { isSubmitting, errors },
    handleSubmit
  } = useForm({ resolver });

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const onSubmit = async formData => {
    try {
      await API.forgotPassword(formData);
      alert.success("Password berhasil dikirimkan ke e-mail Anda");
    } catch (err) {
      alert.show(err.response.data.message || GENERAL);
    } finally {
      handleNavigateBack();
    }
  };

  const renderHeader = () => (
    <React.Fragment>
      <TagWrapper>
        <Image
          src={ PlaneIcon }
          width={ 117 }
          height={ 117 }
          alt={ "iLead-Plane" }
        />
      </TagWrapper>
      <AuthHeader />
    </React.Fragment>
  );

  const renderBackToHomepage = () => (
    <button
      type="button"
      onClick={ handleNavigateBack }
      className="flex w-full justify-center mt-8">
      <span className="text-center text-ileads-lightblue font-brandon_medium text-xl">
        Kembali ke halaman awal
      </span>
    </button>
  );

  const renderCompleteForm = () => (
    <FormInnerWrapper onSubmit={ handleSubmit(onSubmit) }>
      <Title>Selamat datang di iLEAD!</Title>
      <Subtitle>
        Lupa password Anda? Tenang. Masukan alamat e-mail yang Anda registrasikan di iLEAD. Kami akan segera mengirimkan password sementara yang dapat Anda gunakan untuk login.
      </Subtitle>
      <Controller
        name="email"
        control={ control }
        render={ ({ field }) => (
          <Input
            label={ "Alamat e-mail:" }
            inputWidth={ 522 }
            value={ field.value }
            onChange={ field.onChange }
            name={ "email" }
            error={ errors["email"] }
          />
        ) }
      />
      <ButtonSpacer />
      <Button
        type={ "submit" }
        text={ "Kirim Password" }
        bgColor={ Colors.green }
        width={ 290 }
        paddingVertical={ 10 }
        fontSize={ 22 }
        loaderMainColor={ Colors.white }
        isLoading={ isSubmitting }
      />
      { renderBackToHomepage() }
    </FormInnerWrapper>
  );

  return (
    <Wrapper>
      { renderHeader() }
      <FormWrapper>
        <Card
          isUpperCard
          content={ renderCompleteForm() }
        />
      </FormWrapper>
      <HintOuterWrapper>
        <AuthHint />
      </HintOuterWrapper>
    </Wrapper>
  );
}
