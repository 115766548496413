/* eslint-disable jsx-a11y/label-has-associated-control */
import { createIdea, getGroupDetails } from "Api/ideaPools";
import { Button } from "Components";
import { useUsers } from "Context/UsersStateManagement";
import { upperFirst } from "lodash";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link, useParams } from "react-router-dom";
import { Colors } from "Themes";

const BrainstormDetail = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [poolDetails, setPoolDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isMember, setIsMember] = useState(false);

  const alertComponent = useAlert();
  const { group_id } = useParams();
  const {
    user: { user_id }
  } = useUsers();

  const getDataPools = async () => {
    const pools = await getGroupDetails(group_id);

    setPoolDetails(pools);
  };

  const formatTitle = (key) => {
    return upperFirst(`${key.substring(0, key.length - 2)}-${key.substring(key.length - 2)}!`);
  };

  const handleSubmitIdea = async () => {
    try {
      setIsLoading(true);

      await createIdea({
        brainstormGroupId: group_id,
        description,
        title
      });
      await getDataPools();
      alertComponent.success("Berhasil membuat idea!");
      setTitle("");
      setDescription("");
      setErrorMessage(null);
    } catch ({ response }) {
      setErrorMessage(response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDataPools();
  }, []);

  useEffect(() => {
    setIsMember(poolDetails?.detail?.members.some((member) => member.bgm_member_id === user_id));
  }, [poolDetails, user_id]);

  return (
    <section>
      <div className="flex items-center mb-4">
        <h1
          className="
            border-b-8
            text-xl
            border-ileads-yellow
            inline-block
            pl-1
            pr-2
          "
        >
          {!isMember ? "Brainstorm-ed!" : "Tambah Ide"}
        </h1>
        <Link to="/idea-pools" className="ml-5 text-sm py-2 px-4 bg-ileads-lightblue text-white rounded-full">
          Kembali
        </Link>
      </div>

      {isMember ? (
        <section
          className="
            p-5
            pl-[117px]
            bg-white
            rounded-2xl
            shadow-card
            relative
          "
        >
          <div className="mb-3">
            <label className="block mb-2">Judul idenya apa nih?</label>
            <input
              value={title}
              maxLength={30}
              disabled={isLoading}
              onChange={({ target }) => setTitle(target.value)}
              className="bg-ileads-bgblue rounded-xl py-3 px-4 w-full border-none outline-none"
            />
            <div className="flex items-end justify-end">
              <p className="font-brandon text-[11px]">{title.length || 0}/30</p>
            </div>
          </div>
          <div className={`${errorMessage ? "" : "mb-6"}`}>
            <label className="block mb-2">Tulislah deskripsi idemu.</label>
            <textarea
              value={description}
              disabled={isLoading}
              maxLength={500}
              onChange={({ target }) => setDescription(target.value)}
              className="bg-ileads-bgblue rounded-xl py-3 px-4 w-full resize-none h-28 border-none focus:ring-0"
            />
            <div className="flex items-end justify-end">
              <p className="font-brandon text-[11px]">{description.length || 0}/2500</p>
            </div>
          </div>
          {errorMessage ? <span className="block mt-2 mb-6 text-ileads-red">{errorMessage}</span> : null}
          <Button
            text="Submit"
            onClick={handleSubmitIdea}
            bgColor={Colors.green}
            width={106}
            disabled={!title || !description}
            fontSize={20}
            paddingVertical={!isLoading ? 3 : 8}
            isLoading={isLoading}
            loaderMainColor={Colors.white}
          />
        </section>
      ) : null}

      {poolDetails &&
        Object.entries(poolDetails).map(([key, value], idx) => {
          if (key === "detail") return;
          else
            return (
              <section key={idx}>
                <h1
                  className={`
                    border-b-8
                    text-xl
                    border-ileads-yellow
                    pl-1
                    pr-2
                    my-6
                    ${!isMember && idx === 0 ? "hidden" : "inline-block"}
                  `}
                >
                  {formatTitle(key)}
                </h1>

                <article
                  className={`
                    p-5
                    px-40
                    bg-white
                    rounded-2xl
                    shadow-card
                    relative
                    min-h-[180px]
                    
                    ${value.length ? "grid grid-cols-5 gap-4" : "flex justify-center items-center"}
                  `}
                >
                  {value.length ? (
                    value.map((brainstorm) => (
                      <Link
                        to={`/idea-pools/${group_id}/details/${brainstorm.ip_id}`}
                        key={brainstorm.ip_id}
                        className="
                        note
                        relative
                        px-4
                        pt-1
                        pb-8
                        bg-ileads-mainblue
                        break-words
                        h-28
                        mt-5
                        transition-all
                        hover:scale-105
                        hover:shadow-2xl
                      "
                        style={{
                          background: brainstorm.ip_color
                        }}
                      >
                        <div
                          className=" absolute -top-5 left-0 w-[calc(100%-20px)] h-6"
                          style={{ background: brainstorm.ip_color }}
                        />
                        <div
                          className="
                          border-solid
                          border-l-0
                          border-l-transparent
                          border-r-[20px]
                          border-r-transparent
                          border-b-[20px]
                          absolute
                          right-0
                          -top-5
                          z-10
                        "
                          style={{ borderBottomColor: brainstorm.ip_shadow }}
                        />
                        {brainstorm.ip_title}
                      </Link>
                    ))
                  ) : (
                    <h2 className="text-2xl whitespace-nowrap">Belum ada {formatTitle(key)} idea</h2>
                  )}
                </article>
              </section>
            );
        })}
    </section>
  );
};

export default BrainstormDetail;
