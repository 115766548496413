import clientAPI from "./clientApi";

export const getGroupLists = async (params) => {
  const { data } = await clientAPI().get("/v1/brainstorm-group", { params });

  return data;
};

export const getGroupDetails = async (id) => {
  const {
    data: { data }
  } = await clientAPI().get(`/v1/brainstorm-group/${id}/idea-pools`);

  return data;
};

export const getIdeaDetails = async (id) => {
  const {
    data: { data }
  } = await clientAPI().get(`/v1/idea-pools/${id}`);

  return data;
};

export const voteIdea = async (payload) => {
  const { data } = await clientAPI().post("/v1/vote-idea-pools", payload);

  return data;
};

export const selectIdea = async (payload) => {
  const { data } = await clientAPI().post("/v1/select-idea-pools", payload);

  return data;
};

export const sendIdea = async (payload) => {
  const { data } = await clientAPI().post(
    "/v1/send-selected-idea-pools",
    payload
  );

  return data;
};

export const deleteIdea = async (id) => {
  const { data } = await clientAPI().delete(`/v1/idea-pools/${id}`);

  return data;
};

export const createIdea = async (payload) => {
  const { data } = await clientAPI().post("/v1/idea-pools", payload);

  return data;
};

export const createGroup = async (payload) => {
  const { data } = await clientAPI().post("/v1/brainstorm-group", payload);

  return data;
};
