import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { StorageAPI } from "../../Utils";

export default function UnprotectedLayout() {
  const userToken = StorageAPI.getItem("token");

  if (userToken) {
    return <Navigate to="/homepage" />;
  }
  
  return <Outlet />;
}
