import styled, { css } from "styled-components";

import { Colors } from "../../Themes";

const Wrapper = styled.div`
  padding-bottom: 20px;
`;

const CardWrapper = styled.div`
  background-color: ${Colors.white};
  height: auto;
  padding: 20px;
  border-radius: 25px;
`;

const CardWrapperPages = styled.div`
  background-color: ${Colors.white};
  padding: 20px;
  border-radius: 25px;
`;

const ContentWrapper = styled.div`
  background-color: ${Colors.white};
  padding: 20px 20px 30px;
  width: 100%;
  border-radius: 25px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const DateWrapper = styled.div`
  background-color: ${Colors.darkBlue};
  padding: 20px;
  width: 100%;
  height: 100%;
  color: ${Colors.white};
  border-radius: 25px;
  text-align: right;
  position: relative;
`;

export { Wrapper, CardWrapper, CardWrapperPages, ContentWrapper, DateWrapper };
