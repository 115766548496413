import debounce  from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";
import { API, convertResponseApi } from "../../../Api";
import { DropdownArrowDown, DropdownArrowUp, SearchIcon } from "../../../Assets/Images";
import { Image } from "../../../Components";
import { useUsers } from "../../../Context/UsersStateManagement";
import { CardWrapperPages } from "../../Homepage/index.styles";
export default function ChangeTeam() {
  const { user } = useUsers();
  const [showOption, setShowOption] = useState({
    team1: false,
    team2: false,
    team3: false
  });
  const [filteredOption, setFilteredOption] = useState({
    team1: [],
    team2: [],
    team3: []
  });
  const [state, setState] = useState({
    oldDiv1: "",
    newDiv1: "",
    oldDiv2: "",
    newDiv2: "",
    oldDiv3: "",
    newDiv3: ""
  });
  const [idState, setIdState] = useState({
    oldDiv1: "",
    newDiv1: "",
    oldDiv2: "",
    newDiv2: "",
    oldDiv3: "",
    newDiv3: ""
  });
  const [keywords, setKeywords] = useState({
    team1: "",
    team2: "",
    team3: ""
  });

  const getTeamListInit = async (keyword = "") => {
    const response = await API.teamList(keyword);
    const data = convertResponseApi(response);
    setFilteredOption(prev => ({
      team1: response.status === 200 && data || [],
      team2: response.status === 200 && data || [],
      team3: response.status === 200 && data || []
    }));
  };
  const onSearchTeam = (teamName, keyword) => {
    setKeywords(prev => ({
      ...prev,
      [teamName]: keyword
    }));
    onDebounceSearch(teamName, keyword);
  };
  const onDebounceSearch = useCallback(debounce(async (teamName, keyword) => {
    const response = await API.teamList(keyword);
    const data = convertResponseApi(response);
    setFilteredOption(prev => ({
      ...prev,
      [teamName]: response.status === 200 && data || []
    }));
  }, 800), []);

  const onSelect = (option, objName, teamName) => {
    setIdState(prev => ({
      ...prev,
      [objName]: option.id
    }));
    setState(prev => ({
      ...prev,
      [objName]: option.name
    }));
    setShowOption(prev => ({
      ...prev,
      [teamName]: false
    }));
    setKeywords(prev => ({
      ...prev,
      [teamName]: ""
    }));
  };

  const submit = async() => {
    const response = await API.changeTeam(idState);
    if(response.status === 200) window.location.href = `${process.env.PUBLIC_URL}/settings`;
  };

  useEffect(() => {
    initData();

    return () => {

    };
  }, [user]);

  useEffect(() => {
    getTeamListInit();
  }, []);

  function initData() {
    setState(prev => ({
      oldDiv1: user.team1_name,
      oldDiv2: user.team2_name,
      oldDiv3: user.team3_name,
      newDiv1: user.team1_name,
      newDiv2: user.team2_name,
      newDiv3: user.team3_name
    }));
    setIdState(prev => ({
      ...prev,
      oldDiv1: user.team1_id,
      oldDiv2: user.team2_id,
      oldDiv3: user.team3_id,
      newDiv1: user.team1_id,
      newDiv2: user.team2_id,
      newDiv3: user.team3_id
    }));
  }

  const toggleOption = (name, value) => {
    setShowOption(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="flex flex-col">
      <div className="flex mb-3">
        <div className="flex flex-col space-y-1">
          <p className="text-xl">Ganti Team</p>
          <div className="bg-ileads-yellow h-2"></div>
        </div>
      </div>

      <CardWrapperPages>
        <div className="grid place-items-center h-card-content">
          <div className="flex flex-col space-y-4">
            <div className="flex justify-center" style={ { width: 340 } }>
              <p className="text-sm text-center">
                Kamu bisa menggantikan dan menambahkan team Anda di sini. Setelah mengubah atau menambahkan team, silakan tekan tombol <span className="text-ileads-lightblue">Submit</span> di bawah.
              </p>
            </div>

            { /* team 1 */ }
            <div className="flex flex-col relative">
              <div>
                <p className="text-lg">Team 1:</p>
                <div className={ `flex justify-between items-center py-2 pl-6 pr-2 border border-ileads-lightblue rounded-input ${showOption.team1 && "rounded-b-none border-b-0"}` } style={ { width: 340 } }>
                  <p className="text-lg">{ state.newDiv1 }</p>
                  <button onClick={ () => toggleOption("team1", !showOption.team1) }>
                    <Image width={ 19 } height={ 19 } src={ !showOption.team1 ? DropdownArrowDown : DropdownArrowUp } alt="dropdown-arrow" />
                  </button>
                </div>
              </div>

              { /* list option */ }
              { showOption.team1 && (
                <div className="flex flex-col space-y-2 pl-3 pr-2 rounded-input border border-t-0 py-2 rounded-t-none border-ileads-lightblue">
                  <div>
                    <div className="flex justify-between items-center">
                      <input type="text" value={ keywords.team1 } className="border-none search" placeholder="Pilih salah satu" onChange={ (e) => onSearchTeam("team1", e.target.value) } />
                      <Image
                        src={ SearchIcon }
                        width={ 22 }
                        height={ 22 }
                      />
                    </div>
                    <hr className="mr-1 ml-3 mt-1" />
                  </div>
                  { /* list option */ }
                  <div className={ `space-y-1.5 pl-3 overflow-y-auto h-16 list-option flex flex-col justify-start ${!filteredOption.team1.length && "h-6"}` }>
                    { filteredOption.team1.length ? (
                      filteredOption.team1.map((option, id) => (
                        <button key={ id } onClick={ () => onSelect(option, "newDiv1", "team1") } className="text-lg text-left">
                          { option.name }
                        </button>
                      ))
                    ) : <p className="text-center">No results found</p>
                    }
                  </div>
                </div>

              ) }
            </div>

            { /* team 2 */ }
            <div className="flex flex-col relative">
              <div>
                <p className="text-lg">Team 2:</p>
                <div className={ `flex justify-between items-center py-2 pl-6 pr-2 border border-ileads-lightblue rounded-input ${showOption.team2 && "rounded-b-none border-b-0"}` } style={ { width: 340 } }>
                  <p className="text-lg">{ state.newDiv2 }</p>
                  <button onClick={ () => toggleOption("team2", !showOption.team2) }>
                    <Image width={ 19 } height={ 19 } src={ !showOption.team2 ? DropdownArrowDown : DropdownArrowUp } alt="dropdown-arrow" />
                  </button>
                </div>
              </div>

              { /* list option */ }
              { showOption.team2 && (
                <div className="flex flex-col space-y-2 pl-3 pr-2 rounded-input border border-t-0 py-2 rounded-t-none border-ileads-lightblue">
                  <div>
                    <div className="flex justify-between items-center">
                      <input type="text" className="border-none search" placeholder="Pilih salah satu" value={ keywords.team2 } onChange={ (e) => onSearchTeam("team2", e.target.value) } />
                      <Image
                        src={ SearchIcon }
                        width={ 22 }
                        height={ 22 }
                      />
                    </div>
                    <hr className="mr-1 ml-3 mt-1" />
                  </div>
                  { /* list option */ }
                  <div className={ `space-y-1.5 pl-3 overflow-y-auto h-16 list-option flex flex-col justify-start ${!filteredOption.team2.length && "h-6"}` }>
                    { filteredOption.team2.length ? (
                      filteredOption.team2.map((option, id) => (
                        <button key={ id } onClick={ () => onSelect(option, "newDiv2", "team2") } className="text-lg text-left">
                          { option.name }
                        </button>
                      ))
                    ) : <p className="text-center">No results found</p>
                    }
                  </div>
                </div>

              ) }
            </div>

            { /* team 3 */ }
            <div className="flex flex-col relative">
              <div>
                <p className="text-lg">Team 3:</p>
                <div className={ `flex justify-between items-center py-2 pl-6 pr-2 border border-ileads-lightblue rounded-input ${showOption.team3 && "rounded-b-none border-b-0"}` } style={ { width: 340 } }>
                  <p className="text-lg">{ state.newDiv3 || "Pilih salah satu" }</p>
                  <button onClick={ () => toggleOption("team3", !showOption.team3) }>
                    <Image width={ 19 } height={ 19 } src={ !showOption.team3 ? DropdownArrowDown : DropdownArrowUp } alt="dropdown-arrow" />
                  </button>
                </div>
              </div>

              { /* list option */ }
              { showOption.team3 && (
                <div className="flex flex-col space-y-2 pl-3 pr-2 rounded-input border border-t-0 py-2 rounded-t-none border-ileads-lightblue">
                  <div>
                    <div className="flex justify-between items-center">
                      <input type="text" className="border-none search" placeholder="Pilih salah satu" value={ keywords.team3 } onChange={ (e) => onSearchTeam("team3", e.target.value) } />
                      <Image
                        src={ SearchIcon }
                        width={ 22 }
                        height={ 22 }
                      />
                    </div>
                    <hr className="mr-1 ml-3 mt-1" />
                  </div>
                  { /* list option */ }
                  <div className={ `space-y-1.5 pl-3 overflow-y-auto h-16 list-option flex flex-col justify-start ${!filteredOption.team3.length && "h-6"}` }>
                    { filteredOption.team3.length ? (
                      filteredOption.team3.map((option, id) => (
                        <button key={ id } onClick={ () => onSelect(option, "newDiv3", "team3") } className="text-lg text-left">
                          { option.name }
                        </button>
                      ))
                    ) : <p className="text-center">No results found</p>
                    }
                  </div>
                </div>

              ) }
            </div>

            <div className="flex justify-center">
              <button className="bg-ileads-green text-white py-2.5 text-center rounded-btn w-56 ml-2" onClick={ submit }>Submit</button>
            </div>
          </div>
        </div>
      </CardWrapperPages>
    </div>
  );
}
