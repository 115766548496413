/* eslint-disable */
import { APIRequest } from "./config";

export const API = {
  /*========================
    AUTH
  =========================*/
  resendOtp: (postData) => APIRequest("v1/user/resend-otp", "POST", postData),
  signIn: (postData) => APIRequest("v1/signin", "POST", postData),
  signInVerify: (postData) => APIRequest("v1/signin/verify", "POST", postData),
  signUp: (postData) => APIRequest("v1/signup", "POST", postData),
  signUpVerify: (postData) => APIRequest("v1/signup/verify", "POST", postData),
  updateProfile: (userId, patchData) => APIRequest(`v1/user/${userId}`, "PATCH", patchData),
  forgotPassword: (postData) => APIRequest("v1//forgot-password", "POST", postData),

  /* upload */
  upload: (postData) => APIRequest("v2/upload", "POST", postData, true),

  /*========================
    TEAM
  =========================*/
  teamList: (search) => APIRequest(`v1/team?search=${search}&limit=300`, "GET"),

  /*========================
    COACHING JOURNALS
  =========================*/
  coachingJournalCreate: (data) => APIRequest("v1/journal", "POST", data),
  coachingJournalList: (limit, page) => APIRequest(`v2/journal?limit=${limit || 5}&page=${page}`, "GET"),
  coachingJournalDetail: (id) => APIRequest(`v1/journal/${id}`, "GET"),
  coachingJournalUpdate: (id, patchData) => APIRequest(`v1/journal/${id}`, "PATCH", patchData),
  coachingJournalFeedback: (id) => APIRequest(`v1/journal/${id}/feedback`, "GET"),
  coachingJournalFeedbackAdd: (postData) => APIRequest("v1/feedback", "POST", postData),

  /*========================
    SETTINGS
  =========================*/
  userList: (page, search) => APIRequest(`v1/user?search=${search}&limit=10&page=${page}`, "GET"),
  userProfile: () => APIRequest(`v2/user/profile`, "GET"),
  userFeed: () => APIRequest("v2/feed?sort=desc&limit=1&page=1", "GET"),
  changePassword: (postData) => APIRequest("v1/change-password", "PATCH", postData),
  changePosition: (postData) => APIRequest("v1/user/request-change-position", "POST", postData),
  changeTeam: (postData) => APIRequest("v1/user/request-change-division", "POST", postData)
};

export const convertResponseApi = (response) => response.data.data;
