import styled from "styled-components";

import { Colors } from "../../Themes";

const Label = styled.p`
  font-size: 20px;
  font-family: 'Brandon-Medium';
  color: ${Colors.darkBlue};
  margin-bottom: 9px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledInput = styled.input`
  width: ${({ inputWidth }) => inputWidth}px;
  border-radius: 30px;
  height: 40px;
  background-color: ${Colors.bgBlue};
  border: 1px solid ${Colors.bgBlue};
  padding: 0px 20px 0px 20px;
  font-family: 'Brandon-Regular';
  font-size: 20px;
  color: ${Colors.mainBlue};
  border: ${({ error }) => error ? `2px solid ${Colors.red}` : `1px solid ${Colors.bgBlue}`};
  &:focus {
    outline: ${({ error }) => error && "none"};
  }
`;

const EyeIconContainer = styled.div`
  margin-left: -40px;
  margin-top: auto;
  margin-bottom: auto;
`;

const ErrorMessage = styled.p `
  font-size: 18px;
  font-family: 'Brandon-Medium';
  color: ${Colors.red};
  margin-bottom: 9px;
`;

export {
  EyeIconContainer,
  Label,
  StyledInput,
  ErrorMessage,
  RowContainer
};
