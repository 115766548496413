import axios from "axios";
import React, { useState } from "react";
import { API, convertResponseApi } from "../../../Api";
import { EyeClose, EyeOpen, InvisiblePasswordIcons } from "../../../Assets/Images";
import { Image } from "../../../Components";
import { CardWrapperPages } from "../../Homepage/index.styles";

export default function ChangePassword() {
  const [passObj, setPassObj] = useState({
    oldPassword: "",
    newPassword: "",
    confPassword: ""
  });
  const [typePassObj, setTypePassObj] = useState({
    oldPassword: "password",
    newPassword: "password",
    confPassword: "password"
  });
  const [showErr, setShowErr] = useState({
    oldPassword: false,
    confPassword: false
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPassObj({ ...passObj, [name]: value });
  };

  const togglePassword = (name) => {
    setTypePassObj({ ...typePassObj, [name]: typePassObj[name] === "password" && "text" || "password" });
  };

  const submit = async () => {
    if (validateConfPassword()) {
      try {
        let body = {
          oldPassword: passObj.oldPassword,
          password: passObj.newPassword
        };
        const response = await API.changePassword(body);
        if (response.status === 200) window.location.href = `${process.env.PUBLIC_URL}/settings`;
      } catch (error) {
        setShowErr(prev => ({
          ...prev,
          oldPassword: true
        }));
      }
    }
  };

  const validateConfPassword = () => {
    let result = true;
    result = passObj.confPassword !== passObj.newPassword;
    setShowErr(prev => ({ ...prev, confPassword: passObj.confPassword !== passObj.newPassword }));
    return !result;
  };

  return (
    <div className="flex flex-col ">
      <div className="flex mb-3">
        <div className="flex flex-col space-y-1">
          <p className="text-xl">Ganti Password</p>
          <div className="bg-ileads-yellow h-2"></div>
        </div>
      </div>
      <CardWrapperPages>
        <div className="flex flex-col">
          <div className="flex py-10 justify-center">
            <div className="flex flex-col space-y-3.5">
              { showErr.confPassword && (<div className="flex justify-center" style={ { width: 380 } }>
                <p className="text-lg text-center text-ileads-danger-3">Waduh. Password baru yang Anda masukkan tidak sama.
                  Mohon sesuaikan password baru yang hendak diisi.</p>
              </div>) }
              { showErr.oldPassword && (<div className="flex justify-center" style={ { width: 380 } }>
                <p className="text-lg text-center text-ileads-danger-3">Waduh. Password lama yang Anda masukkan salah.
                  Mohon isi password lama Anda dengan benar.</p>
              </div>) }
              { /* password lama */ }
              <div className="flex flex-col space-y-1.5">
                <label htmlFor="oldPassword" className="text-lg">Password lama:</label>
                <div className={ `rounded-input border-2 border-ileads-lightblue h-10 flex items-center space-x-1.5 px-2 ${showErr.oldPassword && "border-ileads-danger-3"}` } style={ { width: 380 } }>
                  <input type={ typePassObj.oldPassword } name="oldPassword" onChange={ (e) => handleChange(e) } value={ passObj.oldPassword } className="input-password-custom text-center w-full" />
                  <button onClick={ () => togglePassword("oldPassword") }>
                    <Image width={ 26 } height={ 22 } src={ typePassObj.oldPassword === "password" && EyeClose || EyeOpen } />
                  </button>
                </div>
              </div>

              { /* password baru */ }
              <div className="flex flex-col space-y-1.5">
                <label htmlFor="newPassword" className="text-lg">Password baru:</label>
                <div className={ `rounded-input border-2 border-ileads-lightblue h-10 flex items-center space-x-1.5 px-2 ${showErr.confPassword && "border-ileads-danger-3"}` } style={ { width: 380 } }>
                  <input type={ typePassObj.newPassword } name="newPassword" onChange={ (e) => handleChange(e) } value={ passObj.newPassword } className="input-password-custom text-center w-full" />
                  <button onClick={ () => togglePassword("newPassword") }>
                    <Image width={ 26 } height={ 22 } src={ typePassObj.newPassword === "password" && EyeClose || EyeOpen } />
                  </button>
                </div>
              </div>

              { /* confirm password baru */ }
              <div className="flex flex-col space-y-1.5">
                <label htmlFor="confPassword" className="text-lg">Tulis ulang password barumu:</label>
                <div className={ `rounded-input border-2 border-ileads-lightblue h-10 flex items-center space-x-1.5 px-2 ${showErr.confPassword && "border-ileads-danger-3"}` } style={ { width: 380 } }>
                  <input type={ typePassObj.confPassword } name="confPassword" onChange={ (e) => handleChange(e) } value={ passObj.confPassword } className="input-password-custom text-center w-full" />
                  <button onClick={ () => togglePassword("confPassword") }>
                    <Image width={ 26 } height={ 22 } src={ typePassObj.confPassword === "password" && EyeClose || EyeOpen } />
                  </button>
                </div>
                <div className="flex justify-center" style={ { width: 380 } }>
                  <p className="text-sm text-center">Password minimal 8 karakter, memiliki huruf besar dan kecil, serta memiliki angka dan simbol (!, %, &, dkk.)</p>
                </div>
              </div>
            </div>

          </div>
          <div className="flex justify-center mt-6">
            <button className="bg-ileads-green text-white py-2.5 text-center rounded-btn w-56 ml-2" onClick={ submit }>Ganti password</button>
          </div>
        </div>
      </CardWrapperPages>
    </div>
  );
}
