import React from "react";
import { useAlert } from "react-alert";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  ButtonSpacer,
  FormInnerWrapper,
  FormWrapper,
  HintOuterWrapper,
  InputSpacer,
  Subtitle,
  TagWrapper,
  Title,
  Wrapper
} from "./index.styles";
import { API } from "../../Api";
import { PlaneIcon } from "../../Assets/Images";
import { AuthHeader, AuthHint, Button, Card, Image, Input } from "../../Components";
import { Constants } from "../../Constants";
import { useYupValidationResolver } from "../../Hooks";
import { LoginSchema, RegisterSchema } from "../../Schemas";
import { Colors } from "../../Themes";
import { StorageAPI } from "../../Utils";

const {
  ErrorMessage: { GENERAL },
  InputType: { PASSWORD }
} = Constants;

export default function LoginRegisterPage({ page }) {
  const navigate = useNavigate();
  const alert = useAlert();
  const resolver = useYupValidationResolver(page === "login" ? LoginSchema : RegisterSchema);
  const {
    control,
    formState : { isSubmitting, errors },
    getValues,
    handleSubmit
  } = useForm({ resolver });

  const handleNavigateForgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleLoginPostSubmit = async ({ token, is_verify, need_update_profile }) => {
    const email = getValues("email");
    StorageAPI.setItem("token", token);
  
    if (!is_verify || need_update_profile) {
      const { data: { data: responseData } } =
        await API.resendOtp({ email });
      navigate("/otp-verification", { state: { ...responseData, email, need_update_profile } });
    } else {
      navigate("/homepage");
    }
  };

  const handlePostSubmit = async responseData => {
    const email = getValues("email");
    if (page === "login") {
      await handleLoginPostSubmit(responseData);
    } else {
      navigate("/otp-verification", { state: { ...responseData, email, need_update_profile: true } });
    }
  };

  const onSubmit = async formData => {
    try {
      const { data: { data: responseData } } = page === "login" ? 
        await API.signIn({ ...formData, fcm_token: "" }) :
        await API.signUp({ ...formData, fcm_token: "" });
      await handlePostSubmit(responseData);
    } catch (err) {
      alert.show(err.response.data.message || GENERAL);
    }
  };

  const renderHeader = () => (
    <React.Fragment>
      <TagWrapper>
        <Image
          src={ PlaneIcon }
          width={ 117 }
          height={ 117 }
          alt={ "iLead-Plane" }
        />
      </TagWrapper>
      <AuthHeader />
    </React.Fragment>
  );

  const renderRegisterForm = () => (
    <React.Fragment>
      <InputSpacer />
      <Controller
        name="confirmEmail"
        control={ control }
        render={ ({ field }) => (
          <Input
            label={ "Masukkan kembali alamat e-mail:" }
            inputWidth={ 522 }
            value={ field.value }
            onChange={ field.onChange }
            name={ "confirmEmail" }
            error={ errors["confirmEmail"] }
          />
        ) }
      />
      <InputSpacer />
      <Controller
        name="password"
        control={ control }
        render={ ({ field }) => (
          <Input
            type={ PASSWORD }
            label={ "Password baru:" }
            inputWidth={ 522 }
            value={ field.value }
            onChange={ field.onChange }
            name={ "password" }
            error={ errors["password"] }
          />
        ) }
      />
    </React.Fragment>
  );

  const renderLoginForm = () => (
    <React.Fragment>
      <InputSpacer />
      <Controller
        name="password"
        control={ control }
        render={ ({ field }) => (
          <Input
            type={ PASSWORD }
            label={ "Password:" }
            inputWidth={ 522 }
            value={ field.value }
            onChange={ field.onChange }
            name={ "password" }
            error={ errors["password"] }
          />
        ) }
      />
    </React.Fragment>
  );

  const renderForgotPassword = () => (
    <button
      type="button"
      onClick={ handleNavigateForgotPassword }
      className="flex w-full justify-center mt-8">
      <span className="text-center text-ileads-lightblue font-brandon_medium text-xl">
        Lupa Password
      </span>
    </button>
  );

  const renderCompleteForm = () => (
    <FormInnerWrapper onSubmit={ handleSubmit(onSubmit) }>
      <Title>Selamat datang di iLEAD!</Title>
      <Subtitle>
        {
          page === "login" ?
            "Silakan masuk menggunakan e-mail Anda yang sudah terdaftar." :
            "Silakan masukan alamat e-mail aktif Anda untuk registrasi."
        }
      </Subtitle>
      <Controller
        name="email"
        control={ control }
        render={ ({ field }) => (
          <Input
            label={ "Alamat e-mail:" }
            inputWidth={ 522 }
            value={ field.value }
            onChange={ field.onChange }
            name={ "email" }
            error={ errors["email"] }
          />
        ) }
      />
      { page === "login" ? renderLoginForm() : renderRegisterForm() }
      <ButtonSpacer />
      <Button
        type={ "submit" }
        text={ page.charAt(0).toUpperCase() + page.slice(1) }
        bgColor={ Colors.green }
        width={ 290 }
        paddingVertical={ 10 }
        fontSize={ 22 }
        loaderMainColor={ Colors.white }
        isLoading={ isSubmitting }
      />
      { page === "login" && renderForgotPassword() }
    </FormInnerWrapper>
  );

  return (
    <Wrapper>
      { renderHeader() }
      <FormWrapper>
        <Card
          isUpperCard
          content={ renderCompleteForm() }
        />
      </FormWrapper>
      <HintOuterWrapper>
        <AuthHint />
      </HintOuterWrapper>
    </Wrapper>
  );
}
