import Axios from "axios";

const Headers = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json"
};
const HeadersFormData = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "multipart/form-data"
};

export const request = (authToken, isFormData = false) => isFormData ? Axios.create({
  headers: {
    ...HeadersFormData,
    "Authorization": `Bearer ${authToken}`
  },
  timeout: 30000
}) : Axios.create({
  headers: {
    ...Headers,
    "Authorization": `Bearer ${authToken}`
  },
  timeout: 30000
});
