import { useCallback, useEffect, useRef, useState } from "react";

export const useInfiniteScroll = (initialPage, stopValue, isLoading) => {
  const [page, setPage] = useState(initialPage);
  const loadMoreRef = useRef(null);

  const handleObserver = useCallback(
    ([target]) => {
      if (target.isIntersecting && page < stopValue && !isLoading)
        setPage((prev) => prev + 1);
    },
    [page, stopValue, isLoading]
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };

    const observer = new IntersectionObserver(handleObserver, option);

    if (loadMoreRef.current) observer.observe(loadMoreRef.current);

    return () => {
      observer.disconnect();
    };
  }, [handleObserver]);

  return { loadMoreRef, page, setPage };
};
