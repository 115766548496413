import React from "react";
import { useAlert } from "react-alert";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { ContentWrapper } from "./index.styles";
import { API } from "../../Api";
import { CircularLoader } from "../../Components";
import { Constants } from "../../Constants";
import { useYupValidationResolver } from "../../Hooks";
import { AddCoachingJournalFeedbackSchema } from "../../Schemas";
import { Colors } from "../../Themes";
import "./index.styles.css";

const {
  ErrorMessage: { GENERAL }
} = Constants;

export default function CoachingJournalFeedbackAddPage() {
  const { state } = useLocation();
  const alert = useAlert();
  const navigate = useNavigate();
  const resolver = useYupValidationResolver(AddCoachingJournalFeedbackSchema);
  const {
    control,
    formState : { isSubmitting, errors },
    handleSubmit
  } = useForm({
    resolver
  });

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const onSubmit = async data => {
    try {
      state?.journalId ?
        await API.coachingJournalFeedbackAdd({
          journalId: state.journalId,
          questions: data
        }) : await API.coachingJournalCreate({
          ...state,
          questions: data
        });
      state?.journalId ?
        alert.success("Feedback berhasil diberikan") :
        alert.success("Coaching journal berhasil ditambahkan");
    } catch (err) {
      alert.show(err.response.data.message || GENERAL);
    } finally {
      navigate("/coaching-journals");
    }
  };

  return (
    <div>
      <div className="flex flex-col pr-5 relative">
        <div className="flex">
          <div>
            <p className="font-brandon_bold text-ileads-mainblue text-lg">
              { state?.journalId ? "Feedback untuk coach" : "Self-Reflection" }
            </p>
            <div className={ `bg-ileads-yellow h-2 w-${state?.journalId ? 48 : 32} mt-1` } />
          </div>
          <button 
            onClick={ handleNavigateBack }
            className="bg-ileads-lightblue text-white text-center py-1.5 rounded-btn px-4 whitespace-nowrap mb-2 ml-4">
            <span className="font-brandon_medium">Kembali</span>
          </button>
        </div>
        <ContentWrapper className="mt-4 text-center" onSubmit={ handleSubmit(onSubmit) }>

          <p className={ `font-brandon_medium my-5 text-xl text-ileads-${ Object.keys(errors).length > 0 ? "red" : "mainblue" }` }>
            { Object.keys(errors).length > 0 ?
              "Hmm. Sepertinya ada yang terlewat, nih. Mohon isi semua bagian untuk melanjutkan." :
              "Berilah rating pada pernyataan berikut ini sesuai dengan sesi coaching yang sudah Anda lakukan."
            }
          </p>

          <p className="font-brandon mt-5 text-ileads-mainblue">
            Penting! Catatan coaching Anda belum tersimpan sampai Anda klik  Submit  di bawah halaman ini
          </p>
          
          <div className="flex items-center justify-center my-5">
            <div className="bg-ileads-yellow h-2 w-32" />
          </div>

          <div className="mx-40 space-y-4">
            <Controller
              name="q1"
              control={ control }
              rules={ { maxLength: 500 } }
              render={ ({ field }) => (
                <div className="flex flex-col">
                  { !state.journalId ? (
                    <p className={ `font-brandon text-ileads-${errors["q1"] ? "red" : "mainblue"}` }>
                      “Dalam skala 1 - 5, seberapa baik saya sudah membangun <strong className="font-brandon_medium">rapport </strong>
                      atau <strong className="font-brandon_medium">kedekatan di awal sesi</strong>? ”
                    </p>
                  ) : (
                    <p className={ `font-brandon text-ileads-${errors["q1"] ? "red" : "mainblue"}` }>
                      “Dalam skala 1 - 5, seberapa baik coach-mu sudah membangun <strong className="font-brandon_medium">rapport </strong>
                      atau <strong className="font-brandon_medium">kedekatan di awal sesi</strong>? ”
                    </p>
                  ) }
                  <div className="flex justify-between justify-center space-x-6 my-5">
                    { Array.from({ length: 5 }, (_, i) => i + 1).map((item, index) => (
                      <div key={ index }>
                        <div className="flex flex-col items-center mb-4">
                          <div className="rounded-full border-2 border-ileads-lightblue">
                            <input
                              checked={ field.value == item }
                              type="checkbox"
                              name={ `checkbox-${item}-${index}` }
                              className={ "w-8 h-8 text-ileads-lightblue bg-ileads-bgblue" }
                              onChange={ () => field.onChange(item) }
                            />
                          </div>
                          <label
                            htmlFor="default-checkbox-1"
                            className="font-brandon_medium text-mainblue mt-2"
                          >
                            { item }
                          </label>
                        </div>
                      </div>
                    )) }
                  </div>
                </div>
              ) }
            />

            <Controller
              name="q2"
              control={ control }
              rules={ { maxLength: 500 } }
              render={ ({ field }) => (
                <div className="flex flex-col">
                  { !state.journalId ? (
                    <p className={ `font-brandon text-ileads-${errors["q2"] ? "red" : "mainblue"}` }>
                      “Dalam skala 1 - 5, seberapa baik saya sudah membantu coachee
                      menentukan <strong className="font-brandon_medium">outcome</strong>?”
                    </p>
                  ) : (
                    <p className={ `font-brandon text-ileads-${errors["q2"] ? "red" : "mainblue"}` }>
                      “Dalam skala 1 - 5, seberapa baik coach-mu sudah membantu saya sebagai coachee
                      menentukan <strong className="font-brandon_medium">outcome</strong>?”
                    </p>
                  ) }
                  <div className="flex justify-between justify-center space-x-6 my-5">
                    { Array.from({ length: 5 }, (_, i) => i + 1).map((item, index) => (
                      <div key={ index }>
                        <div className="flex flex-col items-center mb-4">
                          <div className="rounded-full border-2 border-ileads-lightblue">
                            <input
                              checked={ field.value == item }
                              type="checkbox"
                              name={ `checkbox-${item}-${index}` }
                              className={ "w-8 h-8 text-ileads-lightblue bg-ileads-bgblue" }
                              onChange={ () => field.onChange(item) }
                            />
                          </div>
                          <label
                            htmlFor="default-checkbox-1"
                            className="font-brandon_medium text-mainblue mt-2"
                          >
                            { item }
                          </label>
                        </div>
                      </div>
                    )) }
                  </div>
                </div>
              ) }
            />

            <Controller
              name="q3"
              control={ control }
              rules={ { maxLength: 500 } }
              render={ ({ field }) => (
                <div className="flex flex-col">
                  { !state.journalId ? (
                    <p className={ `font-brandon text-ileads-${errors["q3"] ? "red" : "mainblue"}` }>
                      “Dalam skala 1 - 5, seberapa baik saya sudah mempraktekan { " " }
                      <strong className="font-brandon_medium">active listening</strong> atau <strong className="font-brandon_medium">mendengar aktif</strong> saat sesi berlangsung?”
                    </p>
                  ) : (
                    <p className={ `font-brandon text-ileads-${errors["q3"] ? "red" : "mainblue"}` }>
                      “Dalam skala 1 - 5, seberapa baik coach-mu sudah mempraktekan { " " }
                      <strong className="font-brandon_medium">active listening</strong> atau <strong className="font-brandon_medium">mendengar aktif</strong> saat sesi berlangsung?”
                    </p>
                  ) }
                  <div className="flex justify-between justify-center space-x-6 my-5">
                    { Array.from({ length: 5 }, (_, i) => i + 1).map((item, index) => (
                      <div key={ index }>
                        <div className="flex flex-col items-center mb-4">
                          <div className="rounded-full border-2 border-ileads-lightblue">
                            <input
                              checked={ field.value == item }
                              type="checkbox"
                              name={ `checkbox-${item}-${index}` }
                              className={ "w-8 h-8 text-ileads-lightblue bg-ileads-bgblue" }
                              onChange={ () => field.onChange(item) }
                            />
                          </div>
                          <label
                            htmlFor="default-checkbox-1"
                            className="font-brandon_medium text-mainblue mt-2"
                          >
                            { item }
                          </label>
                        </div>
                      </div>
                    )) }
                  </div>
                </div>
              ) }
            />


            <Controller
              name="q4"
              control={ control }
              rules={ { maxLength: 500 } }
              render={ ({ field }) => (
                <div className="flex flex-col">
                  { !state.journalId ? (
                    <p className={ `font-brandon text-ileads-${errors["q4"] ? "red" : "mainblue"}` }>
                      “Dalam skala 1 - 5, seberapa baik saya sudah mengajukan { " " }
                      <strong className="font-brandon_medium">powerful questions</strong> atau <strong className="font-brandon_medium">pertanyaan yang menggugah</strong> pada saat sesi berlangsung?
                    </p>
                  ) : (
                    <p className={ `font-brandon text-ileads-${errors["q4"] ? "red" : "mainblue"}` }>
                      “Dalam skala 1 - 5, seberapa baik coach-mu sudah mengajukan { " " }
                      <strong className="font-brandon_medium">powerful questions</strong> atau <strong className="font-brandon_medium">pertanyaan yang menggugah</strong> pada saat sesi berlangsung?
                    </p>
                  ) }
                  <div className="flex justify-between justify-center space-x-6 my-5">
                    { Array.from({ length: 5 }, (_, i) => i + 1).map((item, index) => (
                      <div key={ index }>
                        <div className="flex flex-col items-center mb-4">
                          <div className="rounded-full border-2 border-ileads-lightblue">
                            <input
                              checked={ field.value == item }
                              type="checkbox"
                              name={ `checkbox-${item}-${index}` }
                              className={ "w-8 h-8 text-ileads-lightblue bg-ileads-bgblue" }
                              onChange={ () => field.onChange(item) }
                            />
                          </div>
                          <label
                            htmlFor="default-checkbox-1"
                            className="font-brandon_medium text-mainblue mt-2"
                          >
                            { item }
                          </label>
                        </div>
                      </div>
                    )) }
                  </div>
                </div>
              ) }
            />

            <Controller
              name="q5"
              control={ control }
              rules={ { maxLength: 500 } }
              render={ ({ field }) => (
                <div className="flex flex-col">
                  { !state.journalId ? (
                    <p className={ `font-brandon text-ileads-${errors["q5"] ? "red" : "mainblue"}` }>
                      “Dalam skala 1 - 5, seberapa baik saya sudah menggali <strong className="font-brandon_medium">insights </strong>
                      atau <strong className="font-brandon_medium">pembelajaran</strong> yang coachee dapatkan selama sesi
                      berlangsung?”
                    </p>
                  ) : (
                    <p className={ `font-brandon text-ileads-${errors["q5"] ? "red" : "mainblue"}` }>
                      “Dalam skala 1 - 5, seberapa baik coach-mu sudah menggali <strong className="font-brandon_medium">insights </strong>
                      atau <strong className="font-brandon_medium">pembelajaran</strong> yang saya dapatkan selama sesi
                      berlangsung?”
                    </p>
                  ) }
                  <div className="flex justify-between justify-center space-x-6 my-5">
                    { Array.from({ length: 5 }, (_, i) => i + 1).map((item, index) => (
                      <div key={ index }>
                        <div className="flex flex-col items-center mb-4">
                          <div className="rounded-full border-2 border-ileads-lightblue">
                            <input
                              checked={ field.value == item }
                              type="checkbox"
                              name={ `checkbox-${item}-${index}` }
                              className={ "w-8 h-8 text-ileads-lightblue bg-ileads-bgblue" }
                              onChange={ () => field.onChange(item) }
                            />
                          </div>
                          <label
                            htmlFor="default-checkbox-1"
                            className="font-brandon_medium text-mainblue mt-2"
                          >
                            { item }
                          </label>
                        </div>
                      </div>
                    )) }
                  </div>
                </div>
              ) }
            />

            <Controller
              name="q6"
              control={ control }
              rules={ { maxLength: 500 } }
              render={ ({ field }) => (
                <div className="flex flex-col">
                  { !state.journalId ? (
                    <p className={ `font-brandon text-ileads-${errors["q6"] ? "red" : "mainblue"}` }>
                      “Dalam skala 1 - 5, seberapa baik saya sudah membantu coachee
                      untuk menyampaikan <strong className="font-brandon_medium">komitmen</strong> di akhir sesi?”
                    </p>
                  ) : (
                    <p className={ `font-brandon text-ileads-${errors["q6"] ? "red" : "mainblue"}` }>
                      “Dalam skala 1 - 5, seberapa baik coach-mu sudah membantu saya sebagai coachee
                      untuk menyampaikan <strong className="font-brandon_medium">komitmen</strong> di akhir sesi?”
                    </p>
                  ) }
                  <div className="flex justify-between justify-center space-x-6 my-5">
                    { Array.from({ length: 5 }, (_, i) => i + 1).map((item, index) => (
                      <div key={ index }>
                        <div className="flex flex-col items-center mb-4">
                          <div className="rounded-full border-2 border-ileads-lightblue">
                            <input
                              checked={ field.value == item }
                              type="checkbox"
                              name={ `checkbox-${item}-${index}` }
                              className={ "w-8 h-8 text-ileads-lightblue bg-ileads-bgblue" }
                              onChange={ () => field.onChange(item) }
                            />
                          </div>
                          <label
                            htmlFor="default-checkbox-1"
                            className="font-brandon_medium text-mainblue mt-2"
                          >
                            { item }
                          </label>
                        </div>
                      </div>
                    )) }
                  </div>
                </div>
              ) }
            />
            <div className={ "mb-12 mt-4 flex items-center justify-center" }>
              <button 
                type={ "submit" }
                className={ "bg-ileads-green text-white text-center py-1.5 rounded-btn px-4 whitespace-nowrap cursor-pointer" }>
                { isSubmitting && (
                  <div className="px-12 py-1">
                    <CircularLoader
                      size={ 20 }
                      color={ Colors.green }
                      unfilledColor={ Colors.white }
                    />
                  </div>
                ) }
                { !isSubmitting && (
                  <span className="font-brandon_medium">Submit</span>
                ) }
              </button>
            </div>
          </div>
        </ContentWrapper>
      </div>
    </div>
  );
}
