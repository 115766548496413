const colorProperties = [
  {
    background: "bg-ileads-yellow",
    color: "text-black"
  },
  {
    background: "bg-ileads-green",
    color: "text-black"
  },
  {
    background: "bg-ileads-lightblue",
    color: "text-black"
  },
  {
    background: "bg-ileads-mainblue",
    color: "text-white"
  },
  {
    background: "bg-ileads-darkblue",
    color: "text-white"
  }
];

export const choiceLabels = {
  likert_1: [
    "Jauh di <strong>bawah</strong> standar",
    "Di <strong>bawah</strong> standar",
    "<strong>Sesuai</strong> standar, <strong>belum</strong> konsisten",
    "<strong>Sesuai</strong> standar, <strong>konsisten</strong>",
    "Di <strong>atas</strong> standar, <strong>konsisten</strong>"
  ].map((v, idx) => ({ text: v, ...colorProperties[idx] })),

  likert_2: ["Sangat tidak sesuai", "Tidak sesuai", "Kurang sesuai", "Sesuai", "Sangat sesuai"].map((v, idx) => ({
    text: v,
    ...colorProperties[idx]
  }))
};
