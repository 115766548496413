import React from "react";

const Underlined = ({ children, className, isHover, disabled }) => {
  const hoverClass = "transition-colors border-transparent" + (disabled ? "" : " hover:border-ileads-yellow");
  const disabledClass = disabled ? " text-ileads-lightgrey" : "";
  const classes =
    `border-b-[16px] font-brandon_bold text-2xl ${
      isHover ? hoverClass : "border-ileads-yellow"
    } w-fit block capitalize pl-1 pr-2` +
    (className ? ` ${className}` : "") +
    disabledClass;

  return <h1 className={classes}>{children}</h1>;
};

export default Underlined;
