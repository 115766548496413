/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { createGroup, getGroupLists } from "Api/ideaPools";
import { getAllUsers } from "Api/user";
import Silang from "Assets/Icons/silang.svg";
import { DropdownArrowDown, DropdownArrowUp } from "Assets/Images";
import { Constants } from "Constants";
import { useUsers } from "Context/UsersStateManagement";
import { useInfiniteScroll, useOnClickOutside } from "Hooks";
import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";

const IdeaPools = () => {
  const [name, setName] = useState("");
  const [members, setMembers] = useState([]);
  const [user, setUser] = useState({});
  const [group, setGroup] = useState(null);
  const [icon, setIcon] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const alertComponent = useAlert();
  const dropdownRef = useRef(null);
  const { user: userData } = useUsers();
  const {
    loadMoreRef: loadMoreRefGroup,
    page: groupPage,
    setPage: setGroupPage
  } = useInfiniteScroll(1, group?.meta?.total_pages, isLoading);
  const { loadMoreRef, page, setPage } = useInfiniteScroll(
    1,
    user?.meta?.total_pages,
    isLoading
  );
  useOnClickOutside(dropdownRef, () => setShowDropdown(false));

  const dropdownOptionClasses = `
    transition-all
    absolute
    border-2
    border-t-0
    border-ileads-mainblue
    px-4
    py-3
    bg-white
    rounded-br-2xl
    w-full
    max-h-80
    z-10
    overflow-y-auto
    rounded-bl-2xl
    ${showDropdown ? "opacity-100" : "opacity-0 -translate-y-10 -scale-y-50"}
  `;

  const getBrainstorm = async (params) => {
    try {
      setIsLoading(true);
      let data = {};
      const groupData = await getGroupLists({ limit: 10, page: 1, ...params });

      for (const bGroup of groupData.data.brainstorm_group) {
        const groupDate = moment(bGroup.bg_created_at).calendar({
          sameDay: "[Initiated Today]",
          lastDay: "[Initiated Yesterday]",
          lastWeek: "[Initiated] dddd",
          sameElse: "DD/MM/YYYY"
        });
        const borderColor = `#${((Math.random() * 0xffffff) << 0).toString(
          16
        )}`;

        Object.assign(data, {
          ...data,
          [groupDate]: data[groupDate]
            ? [...data[groupDate], { ...bGroup, borderColor }]
            : [{ ...bGroup, borderColor }]
        });
      }

      setGroup({
        ...groupData,
        data: group?.data ? { ...group.data, ...data } : data
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getUsers = async (params) => {
    try {
      setIsLoading(true);

      const userResponse = await getAllUsers({ limit: 10, ...params });
      setUser({
        ...userResponse,
        data:
          user?.data && !params.search
            ? [...user.data, ...userResponse.data]
            : userResponse.data
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchMember = debounce(async (e) => {
    setPage(1);
    const userResponse = await getAllUsers({ search: e.target.value });
    setUser(userResponse);
  }, 500);

  const handleSearchFocus = useCallback(async () => {
    setShowDropdown(true);

    if (user?.data?.length) return;

    await getUsers();
  }, [user]);

  const handleSelectUser = (selected) => {
    if (members.length >= 10) setMembers(members.shift());
    if (members.some((member) => member.id === selected.id))
      setMembers(members.filter((member) => member.id !== selected.id));
    else setMembers([...members, selected]);
  };

  const submitGroup = async () => {
    await createGroup({
      icon,
      initiator_id: userData.user_id,
      member_ids: [...members.map((member) => member.id), userData.user_id],
      name
    });

    alertComponent.success(`Berhasil membuat ${name} Group`);

    await getBrainstorm();

    setGroupPage(1);
    setName("");
    setMembers([]);
    setIcon("");
    setShowDropdown(false);
  };

  useEffect(() => {
    if (page > 1) getUsers({ page });
  }, [page]);

  useEffect(() => {
    if (groupPage > 1) getBrainstorm({ page: groupPage });
  }, [groupPage]);

  useEffect(() => {
    getBrainstorm();
  }, []);

  useEffect(() => {
    const usersContainer = document.getElementById("users-container");
    const chevron = dropdownRef.current.querySelector("#chevron");

    chevron.style.top = `${
      dropdownRef.current.children[0].offsetHeight - 25
    }px`;

    usersContainer.style.top = `${
      dropdownRef.current.children[0].offsetHeight - 2
    }px`;
  }, [members]);

  return (
    <section className="text-ileads-mainblue">
      <h1
        className="
          border-b-8
          text-xl
          border-ileads-yellow
          inline-block
          pl-1
          pr-2
          h
          mb-4
        "
      >
        Brainstorming Groups
      </h1>

      <section
        className="
          p-5
          pl-[117px]
          grid
          grid-cols-2
          gap-[68px]
          bg-white
          rounded-2xl
          shadow-card
          relative
        "
      >
        <div className="w-full">
          <div className="mb-4">
            <label htmlFor="name" className="text-lg mb-2 inline-block">
              Tulis nama{" "}
              <strong className="text-ileads-lightblue">
                brainstorming group
              </strong>{" "}
              Anda.
            </label>
            <input
              type="text"
              className="
                w-full
                text-ileads-mainblue
                rounded-2xl
                px-4
                border-2
                focus:ring-0
                focus:ring-transparent
                focus:border-ileads-mainblue
                border-ileads-mainblue
              "
              maxLength={50}
              onChange={(e) => setName(e.target.value)}
              value={name}
              placeholder="Masukan nama group anda"
              name="name"
              id="name"
            />
            <div className="flex items-end justify-end">
              <p className="font-brandon text-[11px]">{name.length || 0}/50</p>
            </div>
          </div>
          <div className="relative">
            <label htmlFor="member_ids" className="text-lg mb-2 inline-block">
              Siapa saja{" "}
              <strong className="text-ileads-lightblue">
                anggota brainstorming group
              </strong>{" "}
              ini?
            </label>
            <div className="relative" ref={dropdownRef}>
              <div
                className={`
                  flex
                  flex-wrap
                  items-center
                  pl-4
                  pr-8
                  focus:border-ileads-mainblue
                  border-ileads-mainblue
                  border-2
                  ${
                    showDropdown
                      ? "rounded-tl-2xl rounded-tr-2xl"
                      : "rounded-2xl"
                  }
                `}
              >
                <div className="flex flex-wrap gap-2 mt-3">
                  {members.map((member) => (
                    <span
                      className="
                        text-xs
                        p-2
                        w-fit
                        bg-ileads-green
                        rounded-full
                        text-white
                      "
                      key={member.id}
                    >
                      {member.fullname}
                      <img
                        onClick={() => handleSelectUser(member)}
                        src={Silang}
                        className="w-3 inline ml-2 cursor-pointer"
                        alt=""
                      />
                    </span>
                  ))}
                </div>
                <input
                  type="text"
                  className="
                    w-full
                    text-ileads-mainblue
                    border-none
                    focus:ring-0
                    focus:ring-transparent
                  "
                  name="member_ids"
                  placeholder="Pilih anggota group (max. 10 orang)"
                  onChange={handleSearchMember}
                  onFocus={handleSearchFocus}
                  id="member_ids"
                />
              </div>
              <img
                className="absolute w-3 right-4"
                src={showDropdown ? DropdownArrowUp : DropdownArrowDown}
                id="chevron"
                alt=""
              />
              <ul id="users-container" className={dropdownOptionClasses}>
                {user?.data?.map((user) => (
                  <li
                    key={user.id}
                    onClick={() => handleSelectUser(user)}
                    className={`
                      transition-all
                      p-2
                      mb-2
                      last:mb-0
                      w-fit
                      flex
                      items-center
                      ${
                        members.some((member) => member.id === user.id)
                          ? "bg-ileads-green rounded-full text-white"
                          : "text-ileads-mainblue"
                      }
                    `}
                    role="button"
                  >
                    {user.fullname}
                    {members.some((member) => member.id === user.id) && (
                      <img className="ml-2" src={Silang} alt="" />
                    )}
                  </li>
                ))}
                <li ref={loadMoreRef} />
              </ul>
            </div>
          </div>
        </div>

        <div>
          <div>
            <span className="block">
              Pilih <strong className="text-ileads-lightblue">icon</strong>{" "}
              untuk group ini!
            </span>

            <div className="grid items-center grid-cols-5 gap-4">
              {Constants.groupIcons.map((group, idx) => (
                <div key={idx}>
                  <img
                    onClick={() => setIcon(group.name)}
                    className={`
                      h-16
                      cursor-pointer
                      mx-auto
                      transition-all
                      hover:grayscale-0
                      ${icon !== group.name ? "grayscale" : "grayscale-0"}
                    `}
                    src={group.icon}
                    alt={group.name}
                  />
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={submitGroup}
            disabled={!name || !members.length || !icon}
            className={`transition-all text-white px-8 py-2 rounded-full absolute bottom-5 right-8 ${
              !name || !members.length || !icon
                ? "bg-gray-200"
                : "bg-ileads-green"
            }`}
          >
            Buat
          </button>
        </div>
      </section>

      <article
        className="
          mt-5
          py-7
          px-[38px]
          bg-white
          rounded-2xl
          shadow-card
        "
      >
        {group &&
          Object.entries(group.data).map(([key, groupsLists]) => (
            <div key={key} className="group">
              <div className="flex items-center my-4 group-first:mt-0">
                <div className="h-[3px] bg-ileads-lightblue w-full" />
                <span className="whitespace-nowrap ml-2 text-sm text-ileads-lightblue">
                  {key}
                </span>
              </div>

              {groupsLists.map((list) => (
                <div
                  key={list.bg_id}
                  className={`
                    py-2
                    px-5
                    transition-all
                    text-ileads-mainblue
                    flex
                    items-center
                    mb-2
                    border-[5px]
                    rounded-2xl
                    ${
                      list.bg_is_selected
                        ? "bg-[#E0E0E0] text-[#4F4F4F] justify-center cursor-not-allowed"
                        : "bg-white justify-between hover:scale-105 hover:shadow-2xl"
                    }
                  `}
                  style={{
                    borderColor: list.bg_is_selected
                      ? "#4F4F4F"
                      : list.borderColor
                  }}
                >
                  <div className="flex items-center">
                    {Constants.groupIcons.map((gIcon, idx) => {
                      return (
                        list.bg_icon === gIcon.name && (
                          <img
                            className={`w-12 h-12 mr-4 ${
                              list.bg_is_selected ? "saturate-0" : ""
                            }`}
                            src={gIcon.icon}
                            alt={gIcon.name}
                            key={idx}
                          />
                        )
                      );
                    })}
                    <div className="pr-4">
                      <span className="text-lg font-brandon_bold" style={{ wordBreak: "break-word" }}>
                        {list.bg_name}
                      </span>
                      <label className="block text-sm font-light">
                        {list.bg_initiator_name}
                      </label>
                    </div>
                  </div>

                  {!list.bg_is_selected ? (
                    <Link
                      className="border-b-[5px] transition-colors whitespace-nowrap border-ileads-yellow hover:text-ileads-green"
                      to={`/idea-pools/${list.bg_id}`}
                    >
                      Masuk Grup
                    </Link>
                  ) : null}
                </div>
              ))}

              <div ref={loadMoreRefGroup} />
            </div>
          ))}
      </article>
    </section>
  );
};

export default IdeaPools;
