import { ReactComponent as DropdownIcon } from "Assets/Icons/dropdown-icon.svg";
import { Button } from "Components";
import { useOnClickOutside } from "Hooks";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Colors } from "Themes";

const QuestionnaireInputField = ({
  value,
  onChange = () => {},
  onReachBottom = () => {},
  label,
  customNoItems,
  isDisabled,
  placeholder,
  isEditable,
  className,
  items
}) => {
  const [disabled, setIsDisabled] = useState(isDisabled);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const containerRef = useRef(null);
  const dropdownListContainer = useRef(null);
  const inputRef = useRef(null);

  const filteredItems = useMemo(() => {
    if (!items?.length) return [];
    else if (search) return items.filter(({ text }) => text.toLowerCase().includes(search.toLowerCase()));
    else return items;
  }, [search, items]);

  useOnClickOutside(containerRef, () => {
    if (items?.length) {
      if (isEditable && showDropdown) setIsDisabled(!disabled);
      if (showDropdown) {
        setSearch("");
        inputRef.current.value = selected?.text || placeholder;
      }

      setShowDropdown(false);
    }
  });

  const handleDropdownScroll = useCallback(
    (e) => {
      const currentScroll = e.target.scrollTop;
      const scrollArea = e.target.scrollHeight - e.target.offsetHeight;

      if (currentScroll === scrollArea) onReachBottom();
    },
    [onReachBottom]
  );

  useEffect(() => {
    if (!value || !items?.length) return;
    const prefillValue = items.find((item) => item.value === value);

    setSelected(prefillValue);
    inputRef.current.value = prefillValue?.text || placeholder;
  }, [value, items, placeholder]);

  useEffect(() => {
    if (!dropdownListContainer?.current) return;
    const dropdownList = dropdownListContainer.current;

    dropdownList.addEventListener("scroll", handleDropdownScroll);

    return () => dropdownList.removeEventListener("scroll", handleDropdownScroll);
  }, [handleDropdownScroll]);

  return (
    <div className={`w-full${className ? ` ${className}` : ""}`}>
      <label className="text-sm font-brandon_bold">{label}</label>
      <div className="relative mt-2" ref={containerRef}>
        <input
          ref={inputRef}
          disabled={disabled}
          placeholder={!selected?.text ? placeholder : selected.text}
          value={!items?.length ? value : ""}
          onChange={(e) => {
            !items?.length ? onChange(e.target.value) : setSearch(e.target.value);
          }}
          onClick={() => {
            if (items?.length && !showDropdown) setShowDropdown(!showDropdown);
          }}
          className={`text-sm px-3 py-2 rounded-full w-full h-7 pr-16 ${
            disabled ? "bg-ileads-bgblue" : "bg-transparent border-2 border-ileads-darkblue"
          }`}
        />
        {!!items?.length && (
          <ol
            ref={dropdownListContainer}
            className={`
              transition-all
              absolute
              bg-white
              overflow-y-auto
              w-full
              z-20
              max-h-[200px]
              rounded-xl
              top-8
              shadow-card
              ${showDropdown ? "visible opacity-100" : "invisible opacity-0"}
            `}
          >
            {!filteredItems.length ? (
              <li className="py-2 px-4 w-full text-center">{customNoItems ? customNoItems : "Tidak ditemukan."}</li>
            ) : (
              filteredItems.map((item, idx) => (
                <li
                  className={`transition-colors duration-[400ms] ${
                    selected?.value === item.value ? "bg-ileads-bgblue" : "hover:bg-ileads-bgblue"
                  } `}
                  key={idx}
                >
                  <button
                    onClick={() => {
                      setSearch("");

                      inputRef.current.value = selected?.value === item.value ? null : item.text;
                      onChange(selected?.value === item.value ? null : item.value);
                      setSelected((prevValue) => (prevValue?.value === item.value ? null : item));
                    }}
                    className="py-2 px-4 w-full text-left"
                  >
                    {item.text}
                  </button>
                </li>
              ))
            )}
          </ol>
        )}
        {!!items?.length && (
          <DropdownIcon
            className={`absolute right-3 top-1/2 transition-all -translate-y-1/2 ${showDropdown ? "rotate-180" : ""}`}
          />
        )}
        {isEditable && !showDropdown ? (
          <Button
            fontSize={14}
            bgColor={disabled ? Colors.green : Colors.lightBlue}
            className="absolute right-2 top-1/2 transition-all -translate-y-1/2"
            text={disabled ? "Edit" : "Done"}
            onClick={() => {
              setIsDisabled(!disabled);
              if (items?.length) {
                setShowDropdown(!disabled ? false : true);
                setSearch("");
                inputRef.current.value = selected?.text || placeholder;
              }
            }}
            width={52}
          />
        ) : null}
      </div>
    </div>
  );
};

export default QuestionnaireInputField;
