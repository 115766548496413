const AbmMan = require("./abm-man.png");
const AbmWoman = require("./abm-woman.png");
const AttachImage = require("./attach-file.png");
const AwardImage = require("./award-image.png");
const CalendarPen = require("./calendar-lines-pen.png");
const CloseRound = require("./close-round.png");
const ConfuseMan = require("./confuse-man.png");
const DefaultAva = require("./default-ava.png");
const DropdownArrowDown = require("./dropdown-arrow-down.png");
const DropdownArrowUp = require("./dropdown-arrow-up.png");
const EmptyJournal = require("./empty-journal.png");
const EyeClose = require("./eye-close.png");
const EyeOpen = require("./eye-open.png");
const HappyIcon = require("./happy.png");
const HeartRedIcon = require("./heart-red.png");
const HeartIcon = require("./heart.png");
const HintIcon = require("./hint-icon.png");
const ImageUploadIcon = require("./image-upload.png");
const InvisiblePasswordIcons = require("./invisible-password.png");
const JournalSeeFeedback = require("./journal-see-feedback.png");
const LandingIllustration = require("./landing-page-illustration.png");
const LikeIcon = require("./like.png");
const Note = require("./note.png");
const PlaneIcon = require("./plane-icon.png");
const PlayIcon = require("./play-icon.png");
const PlusWhite = require("./plus-white.png");
const SadIcon = require("./sad.png");
const SearchIcon = require("./search.png");
const SickIcon = require("./sick.png");
const SurprisedIcon = require("./surprised.png");
const Trophy = require("./thropy.png");
const TopLeftLogo = require("./top-left-logo.png");
const TopRightLogo = require("./top-right-logo.png");
const TrashIcon = require("./trash.png");
const VideoUploadIcon = require("./video-upload.png");

export {
  CalendarPen,
  EyeClose,
  Trophy,
  EyeOpen,
  JournalSeeFeedback,
  CloseRound,
  SearchIcon,
  SadIcon,
  AttachImage,
  LikeIcon,
  HeartIcon,
  HeartRedIcon,
  TopLeftLogo,
  TopRightLogo,
  LandingIllustration,
  PlaneIcon,
  EmptyJournal,
  HintIcon,
  DropdownArrowDown,
  DropdownArrowUp,
  DefaultAva,
  ConfuseMan,
  AwardImage,
  HappyIcon,
  SickIcon,
  SurprisedIcon,
  PlayIcon,
  InvisiblePasswordIcons,
  Note,
  TrashIcon,
  PlusWhite,
  ImageUploadIcon,
  VideoUploadIcon,
  AbmWoman,
  AbmMan
};
