import React from "react";
import { useNavigate } from "react-router-dom";

import {
  FooterTag,
  FormInnerRightWrapper,
  FormInnerWrapper,
  FormWrapper,
  HintOuterWrapper,
  SecondButtonSpacer,
  Subtitle,
  SubtitleLogin,
  SubtitleRegister,
  TagWrapper,
  Title,
  Wrapper
} from "./index.styles";
import { LandingIllustration, PlaneIcon } from "../../Assets/Images";
import { AuthHeader, AuthHint, Button, Card, Image } from "../../Components";
import { Colors } from "../../Themes";

const LandingPage = () => {
  const navigate = useNavigate();

  const handleNavigatePage = page => () => {
    navigate(`/${page}`);
  };

  const renderHeader = () => (
    <React.Fragment>
      <TagWrapper>
        <Image
          src={ PlaneIcon }
          width={ 117 }
          height={ 117 }
          alt={ "iLead-Plane" }
        />
      </TagWrapper>
      <AuthHeader />
    </React.Fragment>
  );

  const renderCardContent = () => (
    <FormInnerWrapper className="bg-red">
      <Image
        src={ LandingIllustration }
        width={ 350 }
        height={ 350 }
        alt={ "Landing-Illustration" }
      />
      <FormInnerRightWrapper>
        <Title>Selamat datang di iLEAD!</Title>
        <Subtitle>Silahkan
          <SubtitleLogin> login </SubtitleLogin>
          untuk masuk atau
          <SubtitleRegister> register </SubtitleRegister>
          untuk mendaftarkan diri.
        </Subtitle>
        <Button
          text={ "Register" }
          onClick={ handleNavigatePage("register") }
          bgColor={ Colors.lightBlue }
          width={ 310 }
          paddingVertical={ 10 }
          fontSize={ 22 }
        />
        <SecondButtonSpacer />
        <Button
          text={ "Login" }
          onClick={ handleNavigatePage("login") }
          bgColor={ Colors.green }
          width={ 310 }
          paddingVertical={ 10 }
          fontSize={ 22 }
        />
      </FormInnerRightWrapper>
    </FormInnerWrapper>
  );

  return (
    <Wrapper>
      { renderHeader() }
      <FormWrapper>
        <Card content={ renderCardContent() } />
      </FormWrapper>
      <FooterTag>
        iLEAD
      </FooterTag>
      <HintOuterWrapper>
        <AuthHint />
      </HintOuterWrapper>
    </Wrapper>
  );
};

export default LandingPage;
