import React from "react";
import ClickAwayListener from "react-click-away-listener";

import {
  ArrowContainer,
  ErrorMessage,
  Label,
  LoaderWrapper,
  MainValue,
  Option,
  OptionsWrapper,
  SearchIconWrapper,
  SearchInput,
  SearchInputWrapper,
  ShowOptionsWrapper,
  Wrapper
} from "./index.styles";
import { CircularLoader, Image } from "..";
import {
  DropdownArrowDown,
  DropdownArrowUp, 
  SearchIcon
} from "../../Assets/Images";
import { Colors } from "../../Themes";

export default function DropdownInput({
  isSearchable,
  onChangeSearch,
  loadingData,
  type,
  label,
  options,
  onSelect,
  value,
  placeholder = "",
  error,
  wrapperClassName,
  fontSize
}) {
  const [showOptions, setShowOptions] = React.useState(false);

  const handleShowOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleCloseOptions = () => {
    setShowOptions(false);
  };

  const handleSelectOption = option => () => {
    onSelect(option);
    handleShowOptions();
  };

  const renderControlContainer = () => (
    <React.Fragment>
      <MainValue fontSize={ fontSize } placeholder={ placeholder }>
        { (typeof value === "string" ? value : value?.label) || placeholder }
      </MainValue>
      <ArrowContainer>
        <Image
          src={ !showOptions ? DropdownArrowDown : DropdownArrowUp }
          width={ 19 }
          height={ 19 }
          alt={ "dropdown-arrow" }
        />
      </ArrowContainer>
    </React.Fragment>
  );

  const renderSearchInput = () => (
    <SearchInputWrapper>
      <SearchInput
        onChange={ onChangeSearch }
      />
      <SearchIconWrapper>
        <Image
          src={ SearchIcon }
          width={ 22 }
          height={ 22 }
        />
      </SearchIconWrapper>
    </SearchInputWrapper>
  );

  const renderMenuOptions = () => (
    <OptionsWrapper error={ error } type={ type } className={ wrapperClassName }>
      { isSearchable && renderSearchInput() }
      { loadingData ? (
        <LoaderWrapper>
          <CircularLoader
            size={ 18 }
            color={ Colors.lightBlue }
            unfilledColor={ Colors.lightGrey }
          />
        </LoaderWrapper>
      ) : (
        <React.Fragment>
          { options.map((item, key) => (
            <div
              key={ key }
              onClick={ handleSelectOption(item) }
              role={ "button" }
              tabIndex={ 0 }
              onKeyDown={ handleSelectOption(item) }
            >
              <Option fontSize={ fontSize }>{ item.label }</Option>
            </div>
          )) }
        </React.Fragment>
      ) }
    </OptionsWrapper>
  );

  return (
    <ClickAwayListener onClickAway={ handleCloseOptions }>
      <div>
        <Label>{ label }</Label>
        { showOptions ? (
          <ShowOptionsWrapper error={ error } type={ type } onClick={ handleShowOptions } className={ wrapperClassName }>
            { renderControlContainer() }
          </ShowOptionsWrapper>
        ) : (
          <Wrapper error={ error } type={ type } onClick={ handleShowOptions } className={ wrapperClassName }>
            { renderControlContainer() }
          </Wrapper>
        ) }
        { showOptions && renderMenuOptions() }
        { error && <ErrorMessage>{ error?.message }</ErrorMessage> }
      </div>
    </ClickAwayListener>
  );
}
