import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Wrapper } from "./index.styles";

export default function Image({ alt, height, src, width }) {
  return (
    <Wrapper>
      <LazyLoadImage alt={ alt } height={ height } src={ src } width={ width } />
    </Wrapper>
  );
}
