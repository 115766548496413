import {
  appendErrors,
  get,
  set
} from "react-hook-form";

const setCustomValidity = (ref, fieldPath, errors) => {
  if (ref && "reportValidity" in ref) {
    const error = get(errors, fieldPath);
    ref.setCustomValidity((error && error.message) || "");

    ref.reportValidity();
  }
};

const validateFieldsNatively = (
  errors,
  options
) => {
  for (const fieldPath in options.fields) {
    const field = options.fields[fieldPath];
    if (field && field.ref && "reportValidity" in field.ref) {
      setCustomValidity(field.ref, fieldPath, errors);
    } else if (field.refs) {
      field.refs.forEach(ref => setCustomValidity(ref, fieldPath, errors));
    }
  }
};

const toNestError = (
  errors,
  options
) => {
  options.shouldUseNativeValidation && validateFieldsNatively(errors, options);

  const fieldErrors = {};
  for (const path in errors) {
    const field = get(options.fields, path);

    set(
      fieldErrors,
      path,
      Object.assign(errors[path], { ref: field && field.ref })
    );
  }

  return fieldErrors;
};

const parseErrorSchema = (
  error,
  validateAllFieldCriteria
) => {
  return (error.inner || []).reduce(
    (previous, error) => {
      if (!previous[error?.path]) {
        previous[error?.path] = { message: error.message, type: error?.type };
      }

      if (validateAllFieldCriteria) {
        const types = previous[error?.path].types;
        const messages = types && types[error?.type];

        previous[error?.path] = appendErrors(
          error?.path,
          validateAllFieldCriteria,
          previous,
          error?.type,
          messages
            ? [].concat(messages, error.message)
            : error.message
        );
      }

      return previous;
    },
    {}
  );
};

export const useYupValidationResolver =
  (schema, schemaOptions = {}, resolverOptions = {}) =>
    async (values, context, options) => {
      try {
        if (schemaOptions.context && process.env.NODE_ENV === "development") {
        // eslint-disable-next-line no-console
          console.warn(
            "You should not used the yup options context. Please, use the 'useForm' context object instead"
          );
        }

        const result = await schema[
          resolverOptions.mode === "sync" ? "validateSync" : "validate"
        ](
          values,
          Object.assign({ abortEarly: false }, schemaOptions, { context })
        );

        options.shouldUseNativeValidation && validateFieldsNatively({}, options);

        return {
          errors: {},
          values: resolverOptions.rawValues ? values : result
        };
      } catch (e) {
        if (!e.inner) {
          throw e;
        }

        return {
          errors: toNestError(
            parseErrorSchema(
              e,
              !options.shouldUseNativeValidation &&
              options.criteriaMode === "all"
            ),
            options
          ),
          values: {}
        };
      }
    };
