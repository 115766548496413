import styled, { css } from "styled-components";

import { Colors } from "../../Themes";

const TagWrapper = styled.div`
  position: absolute;
  background-color: ${Colors.lightBlue};
  height: 220px;
  padding: 10px 40px 65px;
  right: 0;
  display: flex;
  align-items: flex-end;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 85px;
  width: 185px;
`;

const NavbarContainer = styled.div`
  height: 120px;
  background-color: ${Colors.darkBlue};
`;

export { TagWrapper, NavbarContainer };
