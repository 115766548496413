import styled, { css } from "styled-components";

import { Colors } from "../../Themes";

const Wrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
`;

const TagWrapper = styled.div`
  position: absolute;
  background-color: ${Colors.mainBlue};
  height: 270px;
  padding: 10px;
  left:45vw;
  display: flex;
  align-items: flex-end;
  border-bottom-left-radius: 75px;
  border-bottom-right-radius: 75px;
`;

const FormWrapper = styled.div `
  padding: 30px 0px 0px 0px;
  width: 730px;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;

const FormInnerWrapper = styled.div `
  padding: 157px 25px 36px 25px;
  display: flex;
  flex-direction: row;
`;

const FormInnerRightWrapper = styled.div`
  width: 70%;
`;

const baseTextStyles = css`
  font-family: 'Brandon-Medium';
  text-align: center;
  color: ${Colors.darkBlue};
`;

const Title = styled.p`
  ${baseTextStyles}
  font-size: 30px;
  margin-bottom: 30px;
`;

const Subtitle = styled(Title)`
  ${baseTextStyles}
  font-size: 20px;
  margin-bottom: 38px;
  line-height: 25px;
  color: ${Colors.mainBlue};
`;

const SubtitleLogin = styled.span`
  ${baseTextStyles}
  font-size: 20px;
  color: ${Colors.green}
`;

const SubtitleRegister = styled.span`
  ${baseTextStyles}
  font-size: 20px;
  color: ${Colors.lightBlue}
`;

const FooterTag = styled(Title)`
  font-size: 40px;
  text-align: center;
  font-family: 'Brandon-Bold';
  color: ${Colors.mainBlue};
  margin-top: 20px;
  margin-bottom: 0px;
`;

const SecondButtonSpacer = styled.div`
  margin-top: 20px;
`;

const HintOuterWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
`;

export {
  Wrapper,
  TagWrapper,
  FormWrapper,
  FormInnerWrapper,
  FormInnerRightWrapper,
  Title,
  Subtitle,
  SubtitleLogin,
  SubtitleRegister,
  FooterTag,
  SecondButtonSpacer,
  HintOuterWrapper
};
