import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "../../../Api";
import { DropdownArrowDown, DropdownArrowUp, SearchIcon } from "../../../Assets/Images";
import { Image } from "../../../Components";
import { useUsers } from "../../../Context/UsersStateManagement";
import { CardWrapperPages } from "../../Homepage/index.styles";
import "./ChangePosition.scss";

const listOption = [
  {
    value: "ca",
    name: "Culture Agent (CA)"
  },
  {
    value: "cl",
    name: "Culture Leader (CL)"
  },
  {
    value: "cp",
    name: "Counter Part (CP)"
  },
  {
    value: "nwt",
    name: "Non Winning Team (NWT)"
  }
];

export default function ChangePosition() {
  const { user } = useUsers();
  const [showOption, setShowOption] = useState(false);
  const [filteredOption, setFilteredOption] = useState([]);
  const [state, setState] = useState({
    position: "",
    positionSelected: "",
    positionKeyword: ""
  });


  useEffect(() => {
    initData();
    return () => {
      /* on destroy */
    };
  }, [user]);

  function initData(){
    setState(prev => ({
      ...prev,
      position: user.user_position,
      positionSelected: convertPositionName(user.user_position)
    }));
  }

  const onSelect = (option) => {
    setState(prev => ({
      position: option.value,
      positionSelected: option.name,
      positionKeyword: ""
    }));
    setShowOption(false);
  };

  const onSearchPosition = (positionKeyword) => {
    setState(prev =>({
      ...prev,
      positionKeyword
    }));
    if(positionKeyword) {
      const optionList = [...listOption.map(data => (data.name))];
      const filtered = optionList.filter(item => item.includes(state.positionKeyword.toLowerCase()));
      setFilteredOption(filtered);
    } else {
      setFilteredOption(listOption);
    }
  };

  const convertPositionName = (value) => {
    let positionValue = "";
    switch (value) {
    case "ca":
      positionValue = "Culture Agent (CA)";
      break;
    case "cl":
      positionValue = "Culture Leader (CL)";
      break;
    case "cp":
      positionValue = "Counter Part (CP)";
      break;
    case "nwt":
      positionValue = "Non Winning Team (NWT)";
      break;
    default:
      positionValue = "Pilih Salah Satu";
      break;
    }
    return positionValue;
  };

  const submit = async() => {
    const response = await API.changePosition({ position: state.position });
    if(response.status === 200) window.location.href = `${process.env.PUBLIC_URL}/settings`;
  };

  return (
    <div className="flex flex-col">
      <div className="flex mb-3">
        <div className="flex flex-col space-y-1">
          <p className="text-xl">Ganti Posisi Winning Culture</p>
          <div className="bg-ileads-yellow h-2"></div>
        </div>
      </div>

      <CardWrapperPages>
        <div className="grid place-items-center h-card-content">
          <div className="flex flex-col space-y-9">
            <p className="text-sm text-center">Silahkan ubah posisi winning culture Anda di sini.</p>
            { /* dropdown */ }
            <div className="flex flex-col relative">
              <div>
                <p className="text-lg">Posisi Winning Culture:</p>
                <div className={ `flex justify-between items-center py-2 pl-6 pr-2 border border-ileads-lightblue rounded-input ${showOption && "rounded-b-none border-b-0"}` } style={ { width: 340 } }>
                  <p className="text-lg">{ state.positionSelected }</p>
                  <button onClick={ () => setShowOption(!showOption) }>
                    <Image width={ 19 } height={ 19 } src={ !showOption ? DropdownArrowDown : DropdownArrowUp } alt="dropdown-arrow" />
                  </button>
                </div>
              </div>

              { /* list option */ }
              { showOption && (
                <div className="flex flex-col space-y-2 pl-3 pr-2 rounded-input border border-t-0 py-2 rounded-t-none border-ileads-lightblue">
                  <div>
                    <div className="flex justify-between items-center">
                      <input type="text" className="border-none search" placeholder="Pilih salah satu" onChange={ (e) => onSearchPosition(e.target.value) }/>
                      <Image
                        src={ SearchIcon }
                        width={ 22 }
                        height={ 22 }
                      />
                    </div>
                    <hr className="mr-1 ml-3 mt-1" />
                  </div>
                  <div className="space-y-1.5 pl-3 overflow-y-auto h-16 list-option flex flex-col justify-start">
                    {
                      state.positionKeyword ? (
                        filteredOption.map((option, id) => (
                          <button key={ id } onClick={ () => onSelect(option) } className="text-lg text-left">
                            { option.name }
                          </button>
                        ))
                      ) : (
                        listOption.map((option, id) => (
                          <button key={ id } onClick={ () => onSelect(option) } className="text-lg text-left">
                            { option.name }
                          </button>
                        ))
                      )
                    }
                  </div>
                </div>

              ) }
            </div>
            <div className="flex justify-center">
              <button className="bg-ileads-green text-white py-2.5 text-center rounded-btn w-56 ml-2" onClick={ submit }>Submit</button>
            </div>
          </div>
        </div>
      </CardWrapperPages>
    </div>
  );
}
