import React from "react";

import { HintWrapper } from "./index.styles";
import { Image } from "..";
import { HintIcon } from "../../Assets/Images";

export default function AuthHint() {
  return (
    <HintWrapper>
      <Image
        src={ HintIcon }
        width={ 14 }
        height={ 24 }
        alt={ "Hint" }
      />
    </HintWrapper>
  );
}
