import styled from "styled-components";

import { Colors } from "../../Themes";

export const HintWrapper = styled.div`
  background-color: ${Colors.lightGrey};
  display: flex;
  padding: 20px 28px 20px 28px;
  border-radius: 50px;
  box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.25);
`;
