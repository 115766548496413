import { addFeedReact } from "Api/feed";
import CommentCard from "Components/CommentCard";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import ArticleImage from "../ArticleImage";
import Liker from "../Liker";

const PostCard = ({ post, canDelete, ...attributes }) => {
  const [reaction, setReaction] = useState(null);

  const handleLikeChange = useCallback(
    async (type) => {
      await addFeedReact({
        reaction: type,
        feed_id: post.feed_id
      });

      setReaction(type);
    },
    [post]
  );

  return (
    <div className="mb-8" {...attributes}>
      <div className="flex items-center mb-8">
        <span className="text-xs text-ileads-lightblue">
          {post.feed_type_name}
        </span>
        <div className="w-full h-1 mx-4 bg-ileads-yellow" />
        <span className="text-xs whitespace-pre">
          {moment(post.feed_created_at).format("DD MMMM YYYY")}
        </span>
      </div>

      <ArticleImage
        images={
          post?.feed_images_url?.includes(";")
            ? post.feed_images_url.split(";")
            : post.feed_images_url
        }
      />

      <CommentCard
        detail={post}
        canDelete={canDelete}
        withReaction
        reaction={reaction}
      >
        <div className="flex items-center">
          <Link
            to={`/feeds/${post.feed_id}`}
            className="
              px-4
              py-2
              relative
              bg-ileads-green
              rounded-full
              text-white
              after:h-[5px]
              after:w-9/12
              after:bg-ileads-yellow
              after:block
              after:mx-auto
              after:absolute
              after:-bottom-3
              after:left-1/2
              after:-translate-x-1/2
            "
          >
            {post.feed_comment_count} comments
          </Link>
          <Liker onChange={handleLikeChange} />
        </div>
      </CommentCard>
    </div>
  );
};

export default PostCard;
