import React from "react";

export const Footer = () => {
  return (
    <footer className="flex flex-col items-center justify-center h-48 bg-ileads-lightblue text-center space-y-1 text-base text-white">
      <p className="font-brandon_medium">2021-2022 iLEAD</p>
      <p className="font-brandon_medium">All rights reserved</p>
    </footer>
  );
};
