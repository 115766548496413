import clientAPI from "./clientApi";

export const getAreas = async (params) => {
  const { data } = await clientAPI().get("/v1/team", { params });

  return data;
};

export const getCultureUsers = async (params) => {
  const { data } = await clientAPI().get("/v1/user/list-user-culture-measurement", {
    params
  });

  return data;
};

export const getCultures = async (params) => {
  const { data } = await clientAPI().get("/v1/culture-measurement", { params });

  return data;
};

export const getCultureSections = async (id) => {
  const { data } = await clientAPI().get(`/v1/culture-measurement/objective/${id}`);

  return data;
};

export const createCultureAnswer = async (payload) => {
  const { data } = await clientAPI().post("/v1/culture-measurement", payload);

  return data;
};

export const updateCultureAnswer = async (id, payload) => {
  const { data } = await clientAPI().put(`/v1/culture-measurement/${id}`, payload);

  return data;
};

export const getAnswerById = async (id) => {
  const { data } = await clientAPI().get(`/v1/culture-measurement/${id}`);

  return data;
};
