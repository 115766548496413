export const Colors = {
  bgBlue: "#DFEFFC",
  black: "#000000",
  darkBlue: "#1F295A",
  green: "#94C44E",
  grey: "rgba(32,75,154, 0.7)",
  lightBlue: "#33ACE3",
  lightGrey: "#D9D9D9",
  lightRed: "#FFB09C",
  mainBlue: "#204B9A",
  red: "#E3243B",
  white: "#FFFFFF",
  yellow: "#FED072"
};
