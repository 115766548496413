import React from "react";

import { Wrapper } from "./index.styles";
import { Image } from "../";
import { TopLeftLogo, TopRightLogo } from "../../Assets/Images";

export default function AuthHeader() {
  return (
    <Wrapper className="flex flex-row justify-between">
      <Image
        src={ TopLeftLogo }
        width={ 244 }
        height={ 164 }
        alt={ "ABM-MVC-Logo" }
      />
      <Image
        src={ TopRightLogo }
        width={ 135 }
        height={ 135 }
        alt={ "JUARA-Logo" }
      />
    </Wrapper>
  );
}
