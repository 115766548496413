import { useYupValidationResolver } from "Hooks";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AddCoachingJournalSchema } from "Schemas";
import { useUsers } from "./UsersStateManagement";

export const QuestionnaireContext = createContext();

export const useQuestionnaire = () => useContext(QuestionnaireContext);
const initialPayloadData = {
  status: "draft",
  temp_data: []
};

export const QuestionnaireProvider = ({ children }) => {
  const resolver = useYupValidationResolver(AddCoachingJournalSchema);
  const form = useForm({
    resolver
  });
  const { user } = useUsers();
  const [culture, setCulture] = useState({});
  const [areas, setAreas] = useState({});
  const [cultureUsers, setCultureUsers] = useState({});
  const [currentUserData, setCurrentUserData] = useState({});
  const [payload, setPayload] = useState(initialPayloadData);

  const cultureColors = ["bg-ileads-yellow", "bg-ileads-green", "bg-ileads-lightblue"];

  const setCultureData = (data) => {
    if (!data) setCulture({});
    else
      setCulture({
        ...data,
        culture_measurement_objectives: data.culture_measurement_objectives.map((cData, index) => ({
          ...cData,
          color: cultureColors[index]
        }))
      });
  };

  const clearPayload = () => {
    setPayload(initialPayloadData);
  };

  useEffect(() => {
    setCurrentUserData({
      fullname: user.user_fullname,
      team1Id: user.team1_id,
      position: user.user_position
    });
  }, [user]);

  return (
    <QuestionnaireContext.Provider
      value={{
        ...form,
        areas,
        culture,
        cultureUsers,
        currentUserData,
        clearPayload,
        errors: form.formState.errors,
        payload,
        setAreas,
        setCultureData,
        setCultureUsers,
        setCurrentUserData,
        setPayload
      }}
    >
      {children}
    </QuestionnaireContext.Provider>
  );
};
