import { getCultureUsers } from "Api/questionnaire";
import QuestionnaireTabInput from "Components/QuestionnaireTabInput";
import { choiceLabels } from "Components/QuestionnaireTabInput/choiceLabel";
import { useQuestionnaire } from "Context/questionnaireContext";
import { useUsers } from "Context/UsersStateManagement";
import React, { useMemo, useState } from "react";
import { convertDate } from "Utils/convertDate";
import QuestionnaireFormWrapper from "./FormWrapper";
import QuestionnaireInputField from "./InputField";

const QuestionnaireForm = ({ sectionList, currentStep }) => {
  const { user } = useUsers();
  const { culture, areas, payload, cultureUsers, setCultureUsers, setPayload, currentUserData, setCurrentUserData } =
    useQuestionnaire();
  const currentSection = useMemo(() => sectionList[currentStep], [currentStep, sectionList]);
  const currentCMO = useMemo(
    () => culture.culture_measurement_objectives.find(({ cm_objective_id }) => cm_objective_id === payload.cmo_id),
    [culture, payload]
  );

  const selectedExampleChoices = useMemo(
    () => choiceLabels[currentCMO?.cm_objective_max_answerred > 1 ? "likert_1" : "likert_2"],
    [currentCMO]
  );

  const [userPage, setUserPage] = useState(2);
  const [isLoading, setIsLoading] = useState(false);

  const winningLists = [
    {
      text: "Culture Agent (CA)",
      value: "ca"
    },
    {
      text: "Culture Leader (CL)",
      value: "cl"
    },
    {
      text: "Counter Part (CP)",
      value: "cp"
    },
    {
      text: "Non Winning Team (NWT)",
      value: "nwt"
    }
  ];

  const reFetchCultureUsers = async () => {
    if (userPage >= cultureUsers.meta.total_pages || isLoading) return;

    try {
      setIsLoading(true);
      const cultureUserData = await getCultureUsers({ page: userPage });
      setUserPage(userPage + 1);

      setCultureUsers((prevValue) => ({
        ...cultureUserData,
        data: [...prevValue.data, ...cultureUserData.data.map((cUser) => ({ text: cUser.fullname, value: cUser.id }))]
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const exampleDesc = currentSection.description.split("{{type_answer}}");

  return (
    <>
      {currentSection.type === "appraiser" && (
        <>
          <div dangerouslySetInnerHTML={{ __html: currentSection.description }} />
          <div className="mt-10">
            <div className="grid grid-cols-2 gap-2">
              <QuestionnaireInputField
                onChange={() => {}}
                value={convertDate(culture.startDate, true)}
                label="Tanggal pengisian dimulai:"
                isDisabled
              />
              <QuestionnaireInputField
                onChange={(value) => setPayload((prevValue) => ({ ...prevValue, sn: value }))}
                value={payload.sn}
                label="SN:"
              />
            </div>

            <div className="grid grid-cols-2 gap-2 my-1">
              <QuestionnaireInputField
                onChange={(value) => setCurrentUserData((prevValue) => ({ ...prevValue, fullname: value }))}
                value={currentUserData.fullname}
                label="Nama lengkap:"
                isDisabled
                isEditable
              />
              <QuestionnaireInputField
                onChange={(value) => setPayload((prevValue) => ({ ...prevValue, structural_position: value }))}
                value={payload.structural_position}
                label="Posisi Struktural:"
              />
            </div>

            <div className="grid grid-cols-2 gap-2">
              <QuestionnaireInputField
                onChange={(value) => setCurrentUserData((prevValue) => ({ ...prevValue, position: value }))}
                value={currentUserData.position}
                label="Posisi dalam Winning Team:"
                customNoItems="Winning Team Tidak ditemukan"
                isDisabled
                isEditable
                items={winningLists}
              />
              <QuestionnaireInputField
                onChange={(value) => setCurrentUserData((prevValue) => ({ ...prevValue, team1Id: value }))}
                value={currentUserData.team1Id}
                label="Area/Fungsi:"
                placeholder="Pilih area/fungsi Anda"
                customNoItems="Area/Fungsi Tidak ditemukan"
                isDisabled
                isEditable
                items={areas.data}
              />
            </div>
          </div>
        </>
      )}

      {currentSection.type === "rated_person" && (
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: currentSection.description
                .replace(
                  "{{user_position}}",
                  `<strong>${winningLists.find((wl) => wl.value === user.user_position)?.text || "-"}</strong>`
                )
                .replace("{{user_team}}", `<strong>${user.team1_name}</strong>`)
            }}
          />
          <QuestionnaireInputField
            onChange={(value) => setPayload((prevValue) => ({ ...prevValue, rated_user_id: value ?? "" }))}
            value={payload.rated_user_id}
            onReachBottom={reFetchCultureUsers}
            label="Nama individu yang dinilai:"
            placeholder="Pilih rekan kerja yang akan Anda nilai"
            className="mt-12 !w-[450px] mx-auto"
            items={cultureUsers.data}
          />
        </>
      )}

      {currentSection.type === "example" && (
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: exampleDesc[0]
            }}
          />
          <div className="flex bg-ileads-bgblue w-fit mx-auto rounded-full my-4">
            {selectedExampleChoices.map((choice, idx) => (
              <div
                key={idx}
                className="
                  w-fit
                  flex
                  font-brandon_medium
                  z-20
                  items-center
                  text-sm
                  rounded-full
                  py-2
                  px-3
                "
              >
                <span
                  className={`
                    w-[17px]
                    h-[17px]
                    mr-2
                    rounded-full
                    flex
                    text-xs
                    items-center
                    justify-center
                    ${choice.background}
                    ${choice.color}
                  `}
                >
                  {idx + 1}
                </span>
                <span dangerouslySetInnerHTML={{ __html: choice.text }} />
              </div>
            ))}
          </div>
          {!!exampleDesc[1] && (
            <div
              dangerouslySetInnerHTML={{
                __html: exampleDesc[1]
              }}
            />
          )}
          <strong className="my-4 block">Contoh:</strong>
          {currentCMO?.cm_objective_max_answerred > 1 ? (
            <QuestionnaireTabInput
              disabled
              question={{ type: "likert_1", point: 4 }}
              label="1. Konsep pengembangan Budaya Perusahaan telah disusun secara jelas dan mencakup seluruh aspek organisasi perusahaan."
            />
          ) : (
            <QuestionnaireTabInput
              disabled
              question={{ type: "likert_2", point: 5 }}
              label="1. Konsep pengembangan Budaya Perusahaan telah disusun secara jelas dan mencakup seluruh aspek organisasi perusahaan."
            />
          )}
          <p className="mt-4">
            Jawaban tersebut berarti pernyataan “Konsep pengembangan Budaya Perusahaan telah disusun secara jelas dan
            mencakup seluruh aspek organisasi perusahaan.” dinilai tidak sesuai dengan gambaran kesiapan infrastruktur
            budaya.
          </p>
        </div>
      )}

      {currentSection.type === "questionnaire" && (
        <QuestionnaireFormWrapper
          label={currentSection.title}
          description={currentSection.description}
          questions={currentSection.questionnaire}
        />
      )}
    </>
  );
};

export default QuestionnaireForm;
