import clientAPI from "./clientApi";

export const getDetailJournal = async (id) => {
  const { data } = await clientAPI().get(`/v2/journal/${id}`);

  return data;
};

export const createJournal = async (payload) => {
  const { data } = await clientAPI().post("/v2/journal", payload);

  return data;
};
export const updateJournal = async (id, payload) => {
  const { data } = await clientAPI().patch(`/v2/journal/${id}`, payload);

  return data;
};
export const updateJournalLearner = async (id, payload) => {
  const { data } = await clientAPI().patch(`/v2/journal-learner/${id}`, payload);

  return data;
};

export const getJournalLearnerDetail = async (id) => {
  const { data } = await clientAPI().get(`/v2/journal-learner/${id}`);

  return data;
};
