/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  HappyIcon,
  HeartIcon,
  HeartRedIcon,
  LikeIcon,
  SadIcon
} from "Assets/Images";
import React, { useState } from "react";

const Liker = ({ onChange }) => {
  const [showEmoji, setShowEmoji] = useState(false);

  const iconClasses =
    "w-8 cursor-pointer transition-all hover:scale-125 hover:rotate-[15deg]";

  const handleSelectEmoji = (type) => {
    onChange(type);
    setShowEmoji(false);
  };

  return (
    <div className="relative" onMouseLeave={() => setShowEmoji(false)}>
      <div
        className={`
          absolute
          right-2
          -top-8
          min-w-max
          flex
          gap-[10px]
          py-2
          px-4
          bg-white
          shadow-lg
          rounded-full
          transition-all
          ${showEmoji ? "opacity-100 visible" : "invisible opacity-0"}
        `}
      >
        <img
          onClick={() => handleSelectEmoji("love")}
          className={iconClasses}
          src={HeartRedIcon}
          alt=""
        />
        <img
          onClick={() => handleSelectEmoji("like")}
          className={iconClasses}
          src={LikeIcon}
          alt=""
        />
        <img
          onClick={() => handleSelectEmoji("happy")}
          className={iconClasses}
          src={HappyIcon}
          alt=""
        />
        <img
          onClick={() => handleSelectEmoji("sad")}
          className={iconClasses}
          src={SadIcon}
          alt=""
        />
      </div>
      <img
        className="ml-4 cursor-pointer"
        onMouseOver={() => setShowEmoji(true)}
        src={HeartIcon}
        alt=""
      />
    </div>
  );
};

export default Liker;
