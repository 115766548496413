/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  deleteIdea,
  getGroupDetails,
  getIdeaDetails,
  selectIdea,
  sendIdea,
  voteIdea
} from "Api/ideaPools";
import { getAllCP } from "Api/user";
import Silang from "Assets/Icons/silang.svg";
import { DropdownArrowDown, DropdownArrowUp } from "Assets/Images";
import { Button } from "Components";
import { useUsers } from "Context/UsersStateManagement";
import { useInfiniteScroll, useOnClickOutside } from "Hooks";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Colors } from "Themes";

const DetailIdea = () => {
  const [ideaDetails, setIdeaDetails] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedCp, setSelectedCp] = useState(null);
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [poolDetails, setPoolDetails] = useState({});
  const [buttonConfig, setButtonConfig] = useState({
    color: Colors.yellow,
    text: "Vote"
  });

  const alertComponent = useAlert();

  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  useOnClickOutside(dropdownRef, () => setShowDropdown(false));

  const { loadMoreRef, page, setPage } = useInfiniteScroll(
    1,
    user?.meta?.total_pages,
    isLoading
  );

  const { group_id, idea_id } = useParams();
  const {
    user: { user_id }
  } = useUsers();

  const dropdownOptionClasses = `
    transition-all
    absolute
    border-2
    border-t-0
    border-ileads-mainblue
    px-4
    py-3
    bg-white
    rounded-br-2xl
    w-full
    max-h-80
    overflow-y-auto
    rounded-bl-2xl
    ${showDropdown ? "opacity-100" : "opacity-0 translate-y-20 -scale-y-50"}
  `;

  const handleSearchMember = debounce(async (e) => {
    setPage(1);
    const userResponse = await getAllCP({ search: e.target.value });
    setUser(userResponse);
  }, 500);

  const handleSearchFocus = useCallback(async () => {
    setShowDropdown(true);

    if (user?.data?.length) return;

    await getUsers();
  }, [user]);

  const handleSelectedCP = useCallback(
    (current) => {
      setSelectedCp(selectedCp?.id !== current.id ? current : null);
    },
    [selectedCp]
  );

  const getDataPools = async () => {
    const pools = await getGroupDetails(group_id);

    setPoolDetails(pools);
  };

  const getUsers = async (params) => {
    try {
      setIsLoading(true);

      const userResponse = await getAllCP({ limit: 10, ...params });
      setUser(userResponse);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleButtonClick = async (prompt) => {
    if (prompt === "delete") {
      await deleteIdea(idea_id);
      alertComponent.success("Berhasil menghapus idea!");
      navigate(-1);
      return;
    }
    if (ideaDetails.is_initiator && !ideaDetails.ip_is_selected) {
      await selectIdea({
        ideaPoolsId: idea_id
      });
      alertComponent.success("Berhasil memilih idea!");
      navigate(-1);
    } else if (ideaDetails.ip_is_selected) {
      await sendIdea({
        counterPartId: selectedCp.id,
        ideaPoolsId: idea_id
      });
      alertComponent.success("Berhasil mengirim idea ke CP!");
      navigate(-1);
    } else if (ideaDetails.is_author) {
      await deleteIdea(idea_id);
      navigate(-1);
    } else {
      await voteIdea({
        ideaPoolsId: idea_id
      });
      alertComponent.success("Berhasil vote idea!");
      await getDetail();
    }
  };

  const getDetail = async () => {
    const data = await getIdeaDetails(idea_id);

    if (data.is_initiator && !data.ip_is_selected)
      setButtonConfig({
        color: Colors.green,
        text: "Select"
      });
    else if (data.is_author && !data.ip_is_selected)
      setButtonConfig({
        color: Colors.red,
        text: "Hapus"
      });
    else if (data.ip_is_selected)
      setButtonConfig({
        color: Colors.green,
        text: "Kirim"
      });
    else
      setButtonConfig({
        color: Colors.yellow,
        text: "Vote"
      });

    setIdeaDetails(data);
  };

  useEffect(() => {
    getDetail();
    getDataPools();
  }, []);

  useEffect(() => {
    getUsers({ page });
  }, [page]);

  return (
    <section>
      <div className="flex items-center mb-4">
        <h1
          className="
          border-b-8
          text-xl
          border-ileads-yellow
          inline-block
          pl-1
          pr-2
        "
        >
          Tentang Ide
        </h1>
        <Link
          to={`/idea-pools/${group_id}`}
          className="ml-5 text-sm py-2 px-4 bg-ileads-lightblue text-white rounded-full"
        >
          Kembali
        </Link>
      </div>

      <section
        className="
          p-5
          px-[160px]
          bg-white
          rounded-2xl
          shadow-card
          relative
        "
      >
        <div className="mb-3">
          <label className="block mb-2">Judul idenya apa nih?</label>
          <input
            disabled
            value={ideaDetails.ip_title}
            className="bg-ileads-bgblue rounded-xl py-3 px-4 w-full border-none outline-none"
          />
        </div>
        <div className={`${!ideaDetails.is_author ? "mb-3" : "mb-7"}`}>
          <label className="block mb-2">Tulislah deskripsi idemu.</label>
          <textarea
            disabled
            value={ideaDetails.ip_description}
            className="
              bg-ileads-bgblue
              rounded-xl
              py-3
              px-4
              w-full
              resize-none
              h-28
              border-none
              focus:ring-0
            "
          />
        </div>

        {ideaDetails.is_initiator ? (
          <div className={ideaDetails.is_initiator ? "mb-3" : "mb-7"}>
            <label className="text-lg mb-2 inline-block">
              Ide dicetuskan oleh:
            </label>
            <input
              type="text"
              disabled
              value={ideaDetails.ip_author_fullname}
              className="
                w-full
                text-ileads-mainblue
                rounded-xl
                px-4
                border-2
                focus:ring-0
                focus:ring-transparent
                focus:border-ileads-lightblue
                border-ileads-lightblue
              "
            />
          </div>
        ) : null}

        {ideaDetails.is_initiator &&
        ideaDetails.ip_is_selected &&
        !poolDetails?.selected?.length ? (
          <div className="relative mb-7">
            <label htmlFor="member_ids" className="text-lg mb-2 inline-block">
              Pilih CP:
            </label>
            <div className="relative" ref={dropdownRef}>
              <div
                className={`
                  flex
                  flex-wrap
                  items-center
                  pl-4
                  pr-8
                  focus:border-ileads-mainblue
                  border-ileads-mainblue
                  border-2
                  ${
                    showDropdown
                      ? "rounded-tl-2xl rounded-tr-2xl"
                      : "rounded-2xl"
                  }
                `}
              >
                <input
                  type="text"
                  className="
                    w-full
                    text-ileads-mainblue
                    border-none
                    focus:ring-0
                    focus:ring-transparent
                  "
                  name="member_ids"
                  placeholder={selectedCp ? selectedCp.fullname : "Pilih CP"}
                  onChange={handleSearchMember}
                  onFocus={handleSearchFocus}
                  id="member_ids"
                />
              </div>
              <img
                className="absolute w-3 top-4 right-4"
                src={showDropdown ? DropdownArrowUp : DropdownArrowDown}
                id="chevron"
                alt=""
              />
              <ul id="users-container" className={dropdownOptionClasses}>
                {user?.data?.map((user) => (
                  <li
                    key={user.id}
                    onClick={() => handleSelectedCP(user)}
                    className={`
                      transition-all
                      p-2
                      mb-2
                      last:mb-0
                      w-fit
                      flex
                      items-center
                      ${
                        selectedCp?.id === user.id
                          ? "bg-ileads-green rounded-full text-white"
                          : "text-ileads-mainblue"
                      }
                    `}
                    role="button"
                  >
                    {user.fullname}
                    {selectedCp?.id === user.id && (
                      <img className="ml-2" src={Silang} alt="" />
                    )}
                  </li>
                ))}
                <li ref={loadMoreRef} />
              </ul>
            </div>
          </div>
        ) : null}

        {!poolDetails?.selected?.length ? (
          <div
            className={`flex ${
              ideaDetails.is_author &&
              !(ideaDetails.is_author && ideaDetails.is_initiator)
                ? "justify-end"
                : "justify-between"
            } items-center`}
          >
            {ideaDetails.is_author &&
            ideaDetails.is_initiator &&
            !ideaDetails.ip_is_selected ? (
              <Button
                className="!ml-0 !mr-0"
                text="Hapus"
                bgColor={Colors.red}
                width={106}
                fontSize={20}
                onClick={() => handleButtonClick("delete")}
                paddingVertical={3}
                loaderMainColor={Colors.white}
              />
            ) : null}
            <Button
              disabled={ideaDetails.is_voting}
              className={ideaDetails.ip_is_selected ? "" : "!ml-0 !mr-0"}
              text={ideaDetails.is_voting ? "Voted" : buttonConfig.text}
              bgColor={buttonConfig.color}
              width={106}
              fontSize={20}
              onClick={handleButtonClick}
              paddingVertical={3}
              loaderMainColor={Colors.white}
            />
            {!ideaDetails.is_author && !ideaDetails.ip_is_selected ? (
              <span>Ide sudah di-vote {ideaDetails.ip_votes || 0} kali.</span>
            ) : null}
          </div>
        ) : null}
      </section>
    </section>
  );
};

export default DetailIdea;
