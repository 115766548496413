import axios from "axios";
import { StorageAPI } from "../Utils";

const { REACT_APP_BASE_API_URL } = process.env;

const Headers = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json"
};
const HeadersFormData = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "multipart/form-data"
};

const clientAPI = (isFormData) => {
  const authToken = StorageAPI.getItem("token");

  return axios.create({
    baseURL: REACT_APP_BASE_API_URL,
    headers: {
      ...(isFormData ? HeadersFormData : Headers),
      Authorization: `Bearer ${authToken}`
    },
    timeout: 30000
  });
};

export default clientAPI;
