import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API, convertResponseApi } from "../../Api";
import { CardWrapperPages } from "../Homepage/index.styles";
import "./CoachingJournal.css";

export default function CoachingJournalFeedback() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [valuePoint, setValuePoint] = useState();
  const [feedback, setFeedback] = useState();
  const [active, setActive] = useState(0);

  const getJournalFeedback = async () => {
    const response = await API.coachingJournalFeedback(
      state.journalId
    );
    const data = convertResponseApi(response);
    if (response.status === 200) setFeedback(data);
  };

  const getCountOption = () => {
    const arrPoint = [];
    for (let index = 0; index < 6; index++) {
      const element = index;
      arrPoint.push(element);
    }
    setValuePoint(arrPoint);
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };


  useEffect(() => {
    getJournalFeedback();
    getCountOption();
    /* on destroy */
    return () => {};
  }, []);


  return (
    <div>
      <div className="flex flex-col pr-5 relative">
        <div className="flex">
          <div>
            <p className="font-brandon_bold text-ileads-mainblue text-lg">
              Hasil Feedback
            </p>
            <div className="bg-ileads-yellow h-2 w-32 mt-1" />
          </div>
          <button 
            onClick={ handleNavigateBack }
            className="bg-ileads-lightblue text-white text-center py-1.5 rounded-btn px-4 whitespace-nowrap mb-2 ml-6">
            <span className="font-brandon_medium">Kembali</span>
          </button>
        </div>
        <CardWrapperPages className="mt-4 text-center">
          <div className="flex justify-center space-x-8">
            <div className="flex items-center mb-4">
              <input
                type="radio" 
                checked={ active==0 } 
                name="default-radio-1"
                id="default-radio-1"
                className="
                w-8 h-8 text-ileads-green bg-ileads-neutral-4 focus:ring-white
                dark:border-transparent"
                onChange={ () => setActive(0) }
              />
              <label
                htmlFor="default-radio-1"
                className={
                  active == 0 ? "ml-2 font-brandon_medium text-xl" 
                    : "ml-2 font-brandon_medium text-xl text-ileads-neutral-5"
                }
              >
                Jawaban yang sama
              </label>
            </div>
            <div className="flex items-center mb-4">
              <input
                type="radio" 
                checked={ active==1 } 
                name="default-radio-2"
                id="default-radio-2"
                className={
                  active == 1 ? 
                    "w-8 h-8 text-ileads-yellow bg-ileads-neutral-4 focus:ring-white dark:border-transparent"
                    : "w-8 h-8 text-ileads-green bg-ileads-neutral-4 focus:ring-white dark:border-transparent" 
                }
                onChange={ () => setActive(1) }
              />
              <label
                htmlFor="default-radio-2"
                className={
                  active == 1 ? "ml-2 font-brandon_medium text-xl" 
                    : "ml-2 font-brandon_medium text-xl text-ileads-neutral-5"
                }
              >
                Jawaban Coachee
              </label>
            </div>
          </div>

          <div className="flex w-6/12 mx-auto">
            <div 
              className={
                active == 0 ? "bg-ileads-yellow h-2 w-6/12 justify-center mx-auto"
                  : "bg-ileads-neutral-5 h-1 mt-1 w-6/12 justify-center mx-auto"
              } />
            <div 
              className={
                active == 1 ? "bg-ileads-yellow h-2 w-6/12 justify-center mx-auto"
                  : "bg-ileads-neutral-5 h-1 mt-1 w-6/12 justify-center mx-auto"
              } />
          </div>

          <p className="font-brandon_medium my-5 text-xl">
            Berikut adalah hasil feedback sudah dinilai oleh anggota tim-mu.
          </p>

          <div className="mx-40 space-y-4">
            <div className="flex flex-col">
              <p className="font-brandon">
                “Dalam skala 1 - 5, seberapa baik saya sudah membangun <strong>rapport</strong>
                atau <strong>kedekatan di awal sesi</strong>? ”
              </p>
              <div className="flex justif-between justify-center space-x-6 my-5">
                { valuePoint?.map((item, index) => (
                  <div key={ index }>
                    <div className="flex items-center mb-4">
                      <input
                        checked={ (item === feedback?.same_feedback?.q1 && active == 0) || 
                          (item === feedback?.coachee_feedback?.q1 && active == 1 || (
                            item === feedback?.my_feedback?.q1 && active == 1
                          )) }
                        id={ `checkbox-${item}-${index}` }
                        type="checkbox"
                        name={ `checkbox-${item}-${index}` }
                        className={ `w-8 h-8 bg-ileads-neutral-4 focus:ring-white dark:border-transparent 
                          text-ileads-${item === feedback?.coachee_feedback?.q1 && active == 1 ? "yellow" 
                    : item === feedback?.my_feedback?.q1 && active == 1 ? "lightblue" : "green" }` }
                        readOnly
                      />
                      <label
                        htmlFor="default-checkbox-1"
                        className="ml-2 font-brandon_medium text-gray-900 dark:text-gray-300"
                      >
                        { item }
                      </label>
                    </div>
                  </div>
                )) }
              </div>
            </div>

            <div className="flex flex-col">
              <p className="font-brandon ">
                “Dalam skala 1 - 5, seberapa baik saya sudah membantu coachee
                menentukan <strong>outcome</strong>?”
              </p>
              <div className="flex justif-between justify-center space-x-6 my-5">
                { valuePoint?.map((item, index) => (
                  <div key={ item }>
                    <div className="flex items-center mb-4">
                      <input
                        checked={ (item === feedback?.same_feedback?.q2 && active == 0) || 
                          (item === feedback?.coachee_feedback?.q2 && active == 1 || (
                            item === feedback?.my_feedback?.q2 && active == 1
                          )) }
                        type="checkbox"
                        name={ `checkbox-${item}-${index}` }
                        className={ `w-8 h-8 bg-ileads-neutral-4 focus:ring-white dark:border-transparent 
                          text-ileads-${item === feedback?.coachee_feedback?.q2 && active == 1 ? "yellow" 
                    : item === feedback?.my_feedback?.q2 && active == 1 ? "lightblue" : "green" }` }
                        readOnly
                      />
                      <label
                        htmlFor="default-checkbox-1"
                        className="ml-2 font-brandon_medium text-gray-900 dark:text-gray-300"
                      >
                        { item }
                      </label>
                    </div>
                  </div>
                )) }
              </div>
            </div>

            <div className="flex flex-col">
              <p className="font-brandon ">
                “Dalam skala 1 - 5, seberapa baik saya sudah mempraktekan 
                <strong>active listening</strong> atau <strong>mendengar aktif</strong> saat sesi berlangsung?”
              </p>
              <div className="flex justif-between justify-center space-x-6 my-5">
                { valuePoint?.map((item, index) => (
                  <div key={ item }>
                    <div className="flex items-center mb-4">
                      <input
                        checked={ (item === feedback?.same_feedback?.q3 && active == 0) || 
                          (item === feedback?.coachee_feedback?.q3 && active == 1 || (
                            item === feedback?.my_feedback?.q3 && active == 1
                          )) }
                        type="checkbox"
                        name={ `checkbox-${item}-${index}` }
                        className={ `w-8 h-8 bg-ileads-neutral-4 focus:ring-white dark:border-transparent 
                          text-ileads-${item === feedback?.coachee_feedback?.q3 && active == 1 ? "yellow" 
                    : item === feedback?.my_feedback?.q3 && active == 1 ? "lightblue" : "green" }` }
                        readOnly
                      />
                      <label
                        htmlFor="default-radio-1"
                        className="ml-2 font-brandon_medium text-gray-900 dark:text-gray-300"
                      >
                        { item }
                      </label>
                    </div>
                  </div>
                )) }
              </div>
            </div>

            <div className="flex flex-col">
              <p className="font-brandon">
                “Dalam skala 1 - 5, seberapa baik saya sudah mengajukan 
                <strong>powerful questions</strong> atau <strong>pertanyaan yang menggugah</strong> pada saat sesi
                berlangsung?”
              </p>
              <div className="flex justif-between justify-center space-x-6 my-5">
                { valuePoint?.map((item, index) => (
                  <div key={ item }>
                    <div className="flex items-center mb-4">
                      <input
                        checked={ (item === feedback?.same_feedback?.q4 && active == 0) || 
                          (item === feedback?.coachee_feedback?.q4 && active == 1 || (
                            item === feedback?.my_feedback?.q4 && active == 1
                          )) }
                        type="checkbox"
                        name={ `checkbox-${item}-${index}` }
                        className={ `w-8 h-8 bg-ileads-neutral-4 focus:ring-white dark:border-transparent 
                          text-ileads-${item === feedback?.coachee_feedback?.q4 && active == 1 ? "yellow" 
                    : item === feedback?.my_feedback?.q4 && active == 1 ? "lightblue" : "green" }` }
                        readOnly
                      />
                      <label
                        htmlFor="default-radio-1"
                        className="ml-2 font-brandon_medium text-gray-900 dark:text-gray-300"
                      >
                        { item }
                      </label>
                    </div>
                  </div>
                )) }
              </div>
            </div>

            <div className="flex flex-col">
              <p className="font-brandon">
                “Dalam skala 1 - 5, seberapa baik saya sudah menggali <strong>insights</strong>
                atau <strong>pembelajaran</strong> yang coachee dapatkan selama sesi
                berlangsung?”
              </p>
              <div className="flex justif-between justify-center space-x-6 my-5">
                { valuePoint?.map((item, index) => (
                  <div key={ item }>
                    <div className="flex items-center mb-4">
                      <input
                        checked={ (item === feedback?.same_feedback?.q5 && active == 0) || 
                          (item === feedback?.coachee_feedback?.q5 && active == 1 || (
                            item === feedback?.my_feedback?.q5 && active == 1
                          )) }
                        type="checkbox"
                        name={ `checkbox-${item}-${index}` }
                        className={ `w-8 h-8 bg-ileads-neutral-4 focus:ring-white dark:border-transparent 
                          text-ileads-${item === feedback?.coachee_feedback?.q5 && active == 1 ? "yellow" 
                    : item === feedback?.my_feedback?.q5 && active == 1 ? "lightblue" : "green" }` }
                        readOnly
                      />
                      <label
                        htmlFor="default-radio-1"
                        className="ml-2 font-brandon_medium text-gray-900 dark:text-gray-300"
                      >
                        { item }
                      </label>
                    </div>
                  </div>
                )) }
              </div>
            </div>

            <div className="flex flex-col">
              <p className="font-brandon">
                “Dalam skala 1 - 5, seberapa baik saya sudah membantu coachee
                untuk menyampaikan <strong>komitmen</strong> di akhir sesi?”
              </p>
              <div className="flex justif-between justify-center space-x-6 my-5">
                { valuePoint?.map((item, index) => (
                  <div key={ item }>
                    <div className="flex items-center mb-4">
                      <input
                        checked={ (item === feedback?.same_feedback?.q6 && active == 0) || 
                          (item === feedback?.coachee_feedback?.q6 && active == 1 || (
                            item === feedback?.my_feedback?.q6 && active == 1
                          )) }
                        type="checkbox"
                        name={ `checkbox-${item}-${index}` }
                        className={ `w-8 h-8 bg-ileads-neutral-4 focus:ring-white dark:border-transparent 
                          text-ileads-${item === feedback?.coachee_feedback?.q6 && active == 1 ? "yellow" 
                    : item === feedback?.my_feedback?.q6 && active == 1 ? "lightblue" : "green" }` }
                        readOnly
                      />
                      <label
                        htmlFor="default-radio-1"
                        className="ml-2 font-brandon_medium text-gray-900 dark:text-gray-300"
                      >
                        { item }
                      </label>
                    </div>
                  </div>
                )) }
              </div>
            </div>
          </div>
        </CardWrapperPages>
      </div>
    </div>
  );
}
