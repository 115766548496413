import React from "react";
import { useAlert } from "react-alert";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import {
  AddTeamBtnContainer,
  ButtonSpacer,
  DeleteTeamWrapper,
  DropdownContainer,
  FormInnerWrapper,
  FormWrapper,
  InputSpacer,
  RowContainer,
  Subtitle,
  TagWrapper,
  Title,
  Wrapper
} from "./index.styles";
import { API } from "../../Api";
import { PlaneIcon } from "../../Assets/Images";
import { CloseRound } from "../../Assets/Images";
import { AuthHeader, Button, Card, DropdownInput, Image, Input } from "../../Components";
import { Constants } from "../../Constants";
import { useSearch, useYupValidationResolver } from "../../Hooks";
import { CreateProfileSchema } from "../../Schemas";
import { Colors } from "../../Themes";
import { DropdownOptions, ObjectUtils, StorageAPI } from "../../Utils";

const {
  ErrorMessage: { GENERAL },
  UserPositions
} = Constants;

export default function CreateProfilePage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const alert = useAlert();
  const resolver = useYupValidationResolver(CreateProfileSchema);
  const {
    debouncedResults,
    loadingSearch,
    results
  } = useSearch(API.teamList);
  const {
    control,
    formState : { isSubmitting, errors },
    handleSubmit
  } = useForm({ resolver });
  const [teamsCount, setTeamsCount] = React.useState(1);

  const handleAddTeam = e => {
    e.preventDefault();
    if (teamsCount < 3) {
      setTeamsCount(teamsCount + 1);
    }
  };

  const handleSubstractTeam = e => {
    e.preventDefault();
    setTeamsCount(teamsCount - 1);
  };

  const constructSubmitData = rawData => {
    const formattedData = ObjectUtils.removeEmptyValue(rawData);
    Object.keys(formattedData).forEach(key => {
      if (formattedData[key].value) {
        formattedData[key] = formattedData[key].value;
      }
    });

    return formattedData;
  };

  const onSubmit = async data => {
    const formattedData = constructSubmitData(data);

    try {
      const { data: { data: responseData } } =
        await API.updateProfile(state.user_id, formattedData);
      StorageAPI.setItem("token", responseData.token);

      navigate("/homepage");      
    } catch (err) {
      alert.show(err.response.data.message || GENERAL);
    }
  };

  const renderHeader = () => (
    <React.Fragment>
      <TagWrapper>
        <Image
          src={ PlaneIcon }
          width={ 117 }
          height={ 117 }
          alt={ "iLead-Plane" }
        />
      </TagWrapper>
      <AuthHeader />
    </React.Fragment>
  );

  const renderFullNameInput = () => (
    <Controller
      name="fullname"
      control={ control }
      render={ ({ field }) => (
        <Input
          label={ "Nama lengkap:" }
          inputWidth={ 522 }
          value={ field.value }
          onChange={ field.onChange }
          name={ "fullname" }
          error={ errors["fullname"] }
        />
      ) }
    />
  );

  const renderNickNameInput = () => (
    <Controller
      name="nickname"
      control={ control }
      render={ ({ field }) => (
        <Input
          label={ "Nama panggilan:" }
          inputWidth={ 522 }
          value={ field.value }
          onChange={ field.onChange }
          name={ "nickname" }
          error={ errors["nickname"] }
        />
      ) }
    />
  );

  const renderTeamsInput = () => (
    <React.Fragment>
      { [...Array(teamsCount)].map((_, index) => (
        <InputSpacer key={ index }>
          { index !== 0 && (
            <DeleteTeamWrapper onClick={ handleSubstractTeam }>
              <Image
                src={ CloseRound }
                width={ 23 }
                height={ 23 }
                alt={ "delete-team" }
              />
            </DeleteTeamWrapper>
          ) }
          <RowContainer>
            <DropdownContainer>
              <Controller
                name={ `team${index + 1}Id` }
                control={ control }
                render={ ({ field }) => (
                  <div>
                    <DropdownInput
                      type={ "primary" }
                      label={ "Pilih team:" }
                      options={ DropdownOptions.convertToOptions(results, "name", "id") }
                      value={ field.value }
                      onSelect={ field.onChange }
                      isSearchable
                      onChangeSearch={ debouncedResults }
                      loadingData={ loadingSearch }
                      name={ `team${index + 1}Id` }
                      error={ errors[`team${index + 1}Id`] }
                    />
                  </div>
                ) }
              />
            </DropdownContainer>
            { index === 0 && (
              <AddTeamBtnContainer>
                <Button
                  text={ "Tambah Team" }
                  bgColor={ Colors.white }
                  width={ 193 }
                  paddingVertical={ 6 }
                  fontSize={ 20 }
                  textColor={ Colors.lightBlue }
                  borderColor={ Colors.lightBlue }
                  onClick={ handleAddTeam }
                />
              </AddTeamBtnContainer>
            ) }
          </RowContainer>
        </InputSpacer>
      )) }
    </React.Fragment>
  );

  const renderUserPositionsInput = () => (
    <Controller
      name="position"
      control={ control }
      render={ ({ field }) => (
        <DropdownInput
          type={ "primary" }
          label={ "Pilih posisi di struktur Winning Culture:" }
          options={ DropdownOptions.convertToOptions(UserPositions, "name", "id") }
          value={ field.value }
          onSelect={ field.onChange }
          name={ "position" }
          error={ errors["position"] }
        />
      ) }
    />
  );

  const renderRegisterDataForm = () => (
    <FormInnerWrapper onSubmit={ handleSubmit(onSubmit) }>
      <Title>Lengkapi profilmu.</Title>
      <Subtitle>
        Silakan lengkapi profilmu untuk memulai kegiatan di iLEAD.
      </Subtitle>
      { renderFullNameInput() }
      <InputSpacer>
        { renderNickNameInput() }
      </InputSpacer>
      { renderTeamsInput() }
      <InputSpacer>
        <DropdownContainer>
          { renderUserPositionsInput() }
        </DropdownContainer>
      </InputSpacer>
      <ButtonSpacer>
        <Button
          type={ "submit" }
          text={ "Submit" }
          bgColor={ Colors.green }
          width={ 200 }
          paddingVertical={ 10 }
          fontSize={ 22 }
          loaderMainColor={ Colors.white }
          isLoading={ isSubmitting }
        />
      </ButtonSpacer>
    </FormInnerWrapper>
  );

  return (
    <Wrapper>
      { renderHeader() }
      <FormWrapper>
        <Card
          isUpperCard
          content={ renderRegisterDataForm() }
        />
      </FormWrapper>
    </Wrapper>
  );
}
