import { ReactComponent as NextIcon } from "Assets/Icons/next-icon.svg";
import { ReactComponent as PrevIcon } from "Assets/Icons/prev-icon.svg";
import { usePaginate } from "Hooks";
import React, { useCallback, useEffect, useRef } from "react";
import { Colors } from "Themes";

const Pagination = ({ current, max, onPrev, onNext, onJump, className }) => {
  const indicator = useRef(null);
  const paginationContainer = useRef(null);
  const info = usePaginate(current, max);

  const classes = "ilead-pagination flex items-center justify-evenly w-fit" + (className ? ` ${className}` : "");

  const handleOnClick = useCallback(
    (num) => {
      if (typeof num === "number" && num !== current) onJump(num);
    },
    [current, onJump]
  );

  const handleOnPrev = useCallback(
    (e) => {
      if (current > 1) onPrev(e);
    },
    [current, onPrev]
  );

  const handleOnNext = useCallback(
    (e) => {
      if (current < max) onNext(e);
    },
    [current, max, onNext]
  );

  useEffect(() => {
    const currentSelected = paginationContainer.current?.querySelector(`#current-${info?.current}`);

    if (!currentSelected || !indicator?.current) return;

    indicator.current.style.left = `${currentSelected.offsetLeft}px`;
  }, [info]);

  return max ? (
    <div ref={paginationContainer} className={classes}>
      <button onClick={handleOnPrev} className="mr-3">
        <PrevIcon className="transition-colors" fill={info.prev ? Colors.green : Colors.lightGrey} />
      </button>

      <div className="relative">
        <div
          className="absolute transition-all duration-500 top-0 left-0 w-10 h-10 bg-ileads-yellow rounded-full"
          ref={indicator}
        ></div>

        {info.numbers?.map((num, idx) => (
          <button
            className="
              w-10
              h-10
              z-10
              text-2xl
              transition-colors
              inline-flex
              justify-center
              items-center
              rounded-full
              font-brandon_bold
              relative
            "
            id={typeof num === "number" ? `current-${num}` : null}
            disabled={typeof num !== "number"}
            key={idx}
            onClick={() => handleOnClick(num)}
          >
            {num}
          </button>
        ))}
      </div>

      <button onClick={handleOnNext} className="ml-3">
        <NextIcon className="transition-colors" fill={info.next ? Colors.green : Colors.lightGrey} />
      </button>
    </div>
  ) : null;
};

export default Pagination;
