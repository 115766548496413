import QuestionnaireTabInput from "Components/QuestionnaireTabInput";
import React from "react";

const QuestionnaireFormWrapper = ({ label, description, questions }) => {
  return (
    <>
      <strong className="text-sm">{label}</strong>
      <p className="text-sm mb-5" dangerouslySetInnerHTML={{ __html: description }} />

      <div className="grid gap-y-10 w-full">
        {questions.map((q, idx) => (
          <QuestionnaireTabInput key={idx} question={q} label={`${idx + 1}. ${q.item}`} description={q.description} />
        ))}
      </div>
    </>
  );
};

export default QuestionnaireFormWrapper;
