import { CalendarPen } from "Assets/Images";
import { Button, DropdownInput, Image, Pagination, Underlined, Upload } from "Components";
import { Constants } from "Constants";
import { useYupValidationResolver } from "Hooks";
import moment from "moment";
import { DropdownContainer } from "Pages/CoachingJournalNotes/index.styles";
import React, { useCallback, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { JournalFillSchema, UpdateJournalCoachSchema } from "Schemas";
import { Colors } from "Themes";
import { DropdownOptions } from "Utils";

const renderTextArea = (control, name, title, disabled, show = true) => {
  return (
    show && (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div className="flex flex-col my-1">
            {title}
            <textarea
              disabled={disabled}
              className={`resize-none ${
                disabled ? "bg-ileads-bgblue" : "bg-transparent"
              } mt-1 min-h-[120px] border-2 border-ileads-darkblue rounded-xl`}
              value={field.value}
              onChange={field.onChange}
            />
          </div>
        )}
      />
    )
  );
};

const JournalCoacheeNotes = ({ pageTitle, type, isCoachee }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const resolver = useYupValidationResolver(state.list ? JournalFillSchema : UpdateJournalCoachSchema);
  const {
    control,
    formState: { isSubmitting, errors },
    setValue,
    handleSubmit
  } = useForm({ resolver });

  const {
    CoachingJournalTypes,
    ErrorMessage: { GENERAL }
  } = Constants;

  const [isEditable, setIsEditable] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [name, setName] = useState(0);

  const onSubmitForm = (payload) => {};

  const setter = useCallback(
    (value) => {
      setValue("title", state?.title);
      setValue("date", state?.date);
      if (state.list) {
        setValue("content", value.content);
        setValue("lessonsLearned", value.lessonsLearned);
        setValue("commitment", value.commitment);
      } else {
        setValue("content", value.content);
        setValue("recommendationForCoachee", value.recommendation_for_coachee);
        setValue("strength", value.strength);
        setValue("improvement", value.improvement);
      }
    },
    [setValue, state]
  );

  useEffect(() => {
    if (type === "form") setIsEditable(true);
  }, [type]);

  useEffect(() => {
    if (state?.list) {
      const selected = state.list[page - 1];
      setTotal(state.list.length);
      setter({
        content: selected.journal_content ?? "",
        lessonsLearned: selected.jl_lesson_learned ?? "",
        commitment: selected.jl_commitment ?? "",
        documentsUrl: selected.jl_documents_url[0]
      });
      setName(selected.learner_fullname);
    } else {
      setter(state);
      setName(state.name);
    }
  }, [page, setter, state]);

  return (
    <section>
      <div className="flex items-center mb-4">
        <Underlined className="mr-4">{state.isCoachee ? "Lihat Catatan Coach." : "Lihat Catatan Coachee."}</Underlined>

        {type === "overview" && (
          <>
            <Button
              className="!mx-0"
              fontSize={20}
              text={isEditable ? "Batal" : "Edit"}
              width={70}
              paddingVertical={8}
              height={34}
              bgColor={isEditable ? Colors.red : Colors.lightBlue}
              onClick={() => setIsEditable(!isEditable)}
            />
          </>
        )}
        <Button
          className={`!mr-0 ${type === "overview" ? "ml-auto" : "!ml-0"}`}
          fontSize={20}
          text={type === "form" ? "Batal" : "Kembali"}
          width={82}
          paddingVertical={8}
          height={34}
          onClick={() => navigate(-1)}
          bgColor={type === "form" ? Colors.red : Colors.lightBlue}
        />
      </div>

      <form className="p-5 bg-white rounded-2xl shadow-card" onSubmit={handleSubmit(onSubmitForm)}>
        {/* TITLE SECTION */}
        <div>
          <span className="text-ileads-mainblue font-brandon_bold text-lg">
            Tuliskan <em className="not-italic text-ileads-lightblue">judul</em> sesi coaching.
          </span>
          <div className="mt-1 flex items-center">
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <input
                  className={`p-3 block min-w-[401px] h-[47px] ${
                    type !== "form" ? "bg-ileads-bgblue" : "bg-transparent"
                  } border-2 border-ileads-darkblue rounded-xl`}
                  value={field.value}
                  onChange={field.onChange}
                  disabled={type !== "form"}
                />
              )}
            />
            <span className="mx-3">dengan</span>
            <span>
              {state?.learner.map((learner, idx) => (
                <span key={idx}>{learner.jl_fullname}, </span>
              ))}
            </span>
          </div>
        </div>
        {/* END TITLE SECTION */}
        {/* DATE SECTION */}
        <div className="mt-2">
          <span className="text-ileads-mainblue font-brandon_bold text-lg">
            Pilih <em className="not-italic text-ileads-lightblue">tanggal</em> sesi coaching.
          </span>

          <div className="flex items-center">
            <Image src={CalendarPen} width={23} height={23} />

            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <div
                  className={`p-3 ml-3 ${
                    type === "lihat catatan" || type === "overview" ? "bg-ileads-bgblue" : "bg-transparent"
                  } border-2 h-[47px] border-ileads-darkblue rounded-xl`}
                >
                  <ReactDatePicker
                    disabled={type === "lihat catatan" || type === "overview"}
                    selected={field.value ? new Date(field.value) : new Date()}
                    onChange={field.onChange}
                    customInput={<span>{moment(field.value).format("DD MMMM YYYY")}</span>}
                  />
                </div>
              )}
            />
          </div>
        </div>
        {/* END DATE SECTION */}
        {type === "lihat catatan" && (
          <div className="my-7 px-6 py-3 bg-ileads-lightblue w-fit mx-auto text-white rounded-3xl">
            Yang dicatat oleh: {name}
          </div>
        )}
        {/* CONTENT SECTION */}
        {renderTextArea(
          control,
          "content",
          <span className="text-ileads-mainblue font-brandon_bold text-lg">
            Apa yang <em className="not-italic text-ileads-lightblue">dibicarakan</em> saat coaching?
          </span>,
          true
        )}
        {renderTextArea(
          control,
          "strength",
          <span className="text-ileads-mainblue font-brandon_bold text-lg">
            Dari sesi coaching, apa yang sudah coachee lakukan dengan{" "}
            <em className="not-italic text-ileads-lightblue">efektif</em>?
          </span>,
          true,
          state.isCoachee
        )}
        {renderTextArea(
          control,
          "improvement",
          <span className="text-ileads-mainblue font-brandon_bold text-lg">
            Dari sesi coaching, kualitas apa yang dapat coachee{" "}
            <em className="not-italic text-ileads-lightblue">tingkatkan</em> lagi?
          </span>,
          true,
          state.isCoachee
        )}
        {/* END CONTENT SECTION */}
        {/* LESSON SECTION */}
        {renderTextArea(
          control,
          "lessonsLearned",
          <span className="text-ileads-mainblue font-brandon_bold text-lg">
            Tuliskan <em className="not-italic text-ileads-lightblue">“lessons learned”</em> -mu di coaching session
            ini.{" "}
          </span>,
          true,
          !state.isCoachee
        )}
        {/* END LESSON SECTION */}
        {/* COMMITMENT SECTION */}
        {renderTextArea(
          control,
          "commitment",
          <span className="text-ileads-mainblue font-brandon_bold text-lg">
            <em className="not-italic text-ileads-lightblue">Komitmen</em> apa saja yang sudah disepakati bersama?
          </span>,
          true,
          !state.isCoachee
        )}
        {/* END COMMITMENT SECTION */}
        {/* RECOMMENDATION SECTION */}
        {renderTextArea(
          control,
          "recommendationForCoachee",
          <span className="text-ileads-mainblue font-brandon_bold text-lg">
            Dari sesi coaching,{" "}
            <em className="not-italic text-ileads-lightblue">apa rekomendasi coach untuk coachee?</em>
          </span>,
          true,
          state.isCoachee
        )}
        {/* END RECOMMENDATION SECTION */}
        {type !== "lihat catatan" && (
          <div className="mt-4">
            Pilih kategori coaching:
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <>
                  {isEditable || type === "form" ? (
                    <DropdownContainer>
                      <DropdownInput
                        type={"secondary"}
                        options={DropdownOptions.convertToOptions(CoachingJournalTypes, "name", "key")}
                        value={field.value}
                        onSelect={(objValue) => {
                          field.onChange(objValue.value);
                        }}
                        name={"type"}
                        placeholder={field.value}
                        wrapperClassName="w-60"
                        textClassName="input-password-custom"
                        fontSize={16}
                      />
                    </DropdownContainer>
                  ) : (
                    <div className="input-primary bg-ileads-bgblue flex items-center space-x-1.5 px-2 w-60 mt-2">
                      <span className="input-password-custom text-center text-ileads-mainblue w-full text-ellipsis font-brandon">
                        {field.value}
                      </span>
                    </div>
                  )}
                  {field.value === "Others" && isEditable && (
                    <Controller
                      name="label"
                      control={control}
                      render={({ field: label }) => (
                        <div className="flex flex-row mt-2 mb-6 w-60">
                          <div
                            className={`input-${
                              errors["label"] ? "error" : "primary"
                            } bg-ileads-white flex items-center space-x-1.5 px-4 w-96`}
                          >
                            <input
                              value={label.value}
                              placeholder="Tulis kategori coaching disini."
                              onChange={label.onChange}
                              className="input-password-custom text-left text-ileads-mainblue w-full text-ellipsis font-brandon"
                              name="label"
                            />
                          </div>
                        </div>
                      )}
                    />
                  )}
                </>
              )}
            />
          </div>
        )}
        {type === "lihat catatan" && (
          <Upload
            className="mt-4"
            value={state?.list?.length ? state.list[page - 1].jl_documents_url : state?.documents_url}
            disabled={
              state?.list?.length ? !state.list[page - 1].jl_documents_url.length : !state?.documents_url?.length
            }
            isDownload
          />
        )}
        {total > 1 && (
          <Pagination
            onJump={(value) => setPage(value)}
            onNext={() => setPage(page + 1)}
            onPrev={() => setPage(page - 1)}
            className="mx-auto"
            current={page}
            max={total}
          />
        )}
        {type !== "lihat catatan" && (
          <Button
            text={type === "form" || isEditable ? "Simpan Catatan" : "Lihat Catatan Coachee"}
            bgColor={type === "form" || isEditable ? Colors.green : Colors.lightBlue}
            paddingVertical={12}
            fontSize={20}
            width={245}
            height={53}
            className="mt-8"
          />
        )}
        {type === "form" && (
          <span className="text-ileads-mainblue block w-fit mx-auto mt-2">
            Penting! Catatan coaching-mu belum tersimpan sampai kamu klik “
            <em className="not-italic text-ileads-green">Simpan Catatan</em>”.
          </span>
        )}
      </form>
    </section>
  );
};

export default JournalCoacheeNotes;
