import React from "react";

import { Wrapper } from "./index.styles";

export default function Card({ content, isUpperCard }) {
  return (
    <Wrapper isUpperCard={ isUpperCard }>
      { content }
    </Wrapper>
  );
}
