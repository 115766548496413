import clientAPI from "./clientApi";

export const updateUser = async (id, payload) => {
  const { data } = await clientAPI().patch(`/v1/user/${id}`, payload);

  return data;
};

export const getAllUsers = async (params) => {
  const { data } = await clientAPI().get("/v1/user", {
    params
  });

  return data;
};

export const getAllCP = async (params) => {
  const { data } = await clientAPI().get("/v1/user/counter-part", {
    params
  });

  return data;
};
