import styled from "styled-components";

import { Colors } from "../../Themes";

const StyledButton = styled.button`
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 30px;
  width: ${({ width }) => width}px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height}px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: ${({ paddingVertical }) => paddingVertical}px;
  padding-bottom: ${({ paddingVertical }) => paddingVertical}px;
  color: ${({ textColor }) => textColor || Colors.white};
  font-size: ${({ fontSize }) => fontSize}px;
  font-family: "Brandon-Medium";
  border: 3px ${({ borderColor }) => borderColor && `solid ${borderColor}`};
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export { LoaderWrapper, StyledButton };
