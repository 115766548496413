import styled from "styled-components";

export const Wrapper = styled.div`
  border: 5px solid ${({ unfilledColor }) => unfilledColor || "#f3f3f3" };
  border-radius: 50%;
  border-top: 5px solid ${({ color }) => color };
  width: ${({ size }) => size }px;
  height: ${({ size }) => size }px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
