import { createCommitment, getCommitmentDetail } from "Api/feedback";
import { HappyIcon } from "Assets/Images";
import { Button, Modal, Underlined } from "Components";
import { useYupValidationResolver } from "Hooks";
import React, { useEffect } from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CommitmentSchema } from "Schemas";
import { Colors } from "Themes";

const CommitmentCreate = ({ isDetail }) => {
  const { id: fu_id } = useParams();
  const navigate = useNavigate();
  const resolver = useYupValidationResolver(CommitmentSchema);
  const {
    control,
    setValue,
    formState: { isSubmitting, errors },
    handleSubmit
  } = useForm({
    resolver
  });

  const [showModal, setShowModal] = useState(false);

  const onSubmit = async ({ commitment }) => {
    try {
      await createCommitment({ fu_id, commitment });
      setShowModal(true);
      navigate(`/feedback/improvement/${fu_id}`);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCommitmentDetail(fu_id).then(({ data }) => {
      if (data) isDetail ? setValue("commitment", data.commitment) : navigate(`/feedback/improvement/${fu_id}`);
      else navigate(`/feedback/improvement/create/${fu_id}`);
    });
  }, [isDetail, fu_id, setValue, navigate]);

  return (
    <section>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        title="Sukses!"
        desc="Komitmen telah sukses disimpan!"
        icon={<img className="w-[160px]" src={HappyIcon} alt="" />}
        buttons={
          <Button
            type="button"
            onClick={() => navigate("/feedback")}
            text="Kembali ke Menu Utama Feedback"
            bgColor={Colors.green}
            width={300}
            paddingVertical={12}
            fontSize={20}
          />
        }
      />
      <div className="flex items-center mb-4">
        <Underlined>Commitment for Improvement.</Underlined>
        <Link to="/feedback" className="ml-5 text-sm py-2 px-4 bg-ileads-lightblue text-white rounded-full">
          Kembali
        </Link>
      </div>

      <div className="p-5 bg-white rounded-2xl shadow-card">
        <span className="text-xl text-center block mb-4">
          Isi kolom dibawah ini dengan pernyataan komitmen agar kamu akan terus berkembang kedepannya.
        </span>

        <div className="h-4 bg-ileads-yellow my-4"></div>

        {errors["commitment"] ? (
          <span className="text-ileads-danger-3 mb-4 text-xl block text-center">
            Ups! Sepertinya ada kolom yang belum diisi! Silahkan dicek kembali dan isi semua kolom yang tersedia!
          </span>
        ) : null}

        <p className="text-center">
          Apa saja yang saya lakukan untuk memperbaiki dan meningkatkankan performa saya dari hasil feedback sebelumnya?
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="commitment"
            control={control}
            rules={{ maxLength: 2500 }}
            render={({ field, fieldState }) => (
              <>
                <textarea
                  onChange={(e) => {
                    if (e.target.value.length <= 2500) field.onChange(e);
                  }}
                  disabled={isDetail}
                  value={field.value}
                  className={`
                    w-full
                    h-[200px]
                    resize-none
                    border-2
                    rounded-xl
                    ${isDetail ? "bg-ileads-bgblue" : "bg-white"}
                    ${fieldState.error ? "border-ileads-danger-3" : "border-ileads-darkblue"}
                    mt-1
                  `}
                />
                <span className="block ml-auto w-fit text-sm font-brandon">{field.value?.length || 0}/2500</span>
              </>
            )}
          />

          {!isDetail && (
            <Button
              type="submit"
              className="mt-4"
              text="Simpan Komitmen"
              disabled={isSubmitting}
              isSubmitting={isSubmitting}
              bgColor={Colors.lightBlue}
              width={208}
              paddingVertical={12}
              fontSize={20}
            />
          )}
        </form>
      </div>
    </section>
  );
};

export default CommitmentCreate;
