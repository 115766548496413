import { API, convertResponseApi } from "Api";
import { getMyLeaderBoard } from "Api/leaderBoard";
import { getAreas, getCultures } from "Api/questionnaire";
import BellIcon from "Assets/Icons/bell-icon.svg";
import SettingIcon from "Assets/Icons/settings-icon.svg";
import { AbmMan, AwardImage, DefaultAva, HappyIcon } from "Assets/Images";
import Button from "Components/Button";
import { Constants } from "Constants";
import { useQuestionnaire } from "Context/questionnaireContext";
import { useUsers } from "Context/UsersStateManagement";
import { CardWrapper } from "Pages/Homepage/index.styles";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Colors } from "Themes";

import { StorageAPI } from "Utils";
import { convertDate } from "Utils/convertDate";
import { Footer } from "../Footer/Footer";
import Image from "../Image";
import Navbar from "../Navbar/Navbar";

export default function PrivateLayout() {
  const { user, setDataUser } = useUsers();
  const { culture, setCultureData, setAreas } = useQuestionnaire();
  const [loading, setLoading] = useState(false);
  const [needLogin, setNeedLogin] = useState(false);
  const userToken = StorageAPI.getItem("token");
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const getDataUser = async () => {
    try {
      setLoading(true);
      const response = await API.userProfile();
      const position = await getMyLeaderBoard(response.data.data.user_id);
      const data = convertResponseApi(response);
      if (response.status === 200)
        setDataUser({
          ...data,
          leader_position: position,
          team1_name: data.team1_name ? `${data.team1_name}` : "",
          team2_name: data.team2_name ? `${data.team2_name}` : ""
        });
    } catch (err) {
      if (err.response.status == 401) {
        setNeedLogin(true);
        StorageAPI.removeItem("token");
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchCultureData = async () => {
    const [cultureData, areaData] = await Promise.all([getCultures(), getAreas({ limit: 500 })]);

    setCultureData(cultureData.data);
    setAreas({ ...areaData, data: areaData.data.map((area) => ({ text: area.name, value: area.id })) });
  };

  useEffect(() => {
    if (userToken) {
      getDataUser();
      fetchCultureData();
    }

    return () => {};
  }, []);

  if (!loading) {
    if (!userToken || needLogin) {
      return <Navigate to="/" />;
    }
    return (
      <div className="w-full min-h-screen bg-ileads-bgblue relative">
        <Navbar />
        {/* header */}

        {/* main */}
        {pathname === "/questionnaire" ? (
          <Outlet />
        ) : (
          <main>
            <div className="pb-20 pt-7 font-brandon text-ileads-darkblue">
              {/* notification */}
              <div className="px-5">
                <div className="flex items-center space-x-5 font-brandon_bold mb-1.5">
                  <Image src={BellIcon} alt="ilead-notif" className="cursor-pointer" />
                  <div className="flex flex-col space-y-1">
                    <p className="text-4xl">Hai, {user?.user_nickname}!</p>
                    <div className="bg-ileads-yellow h-2"></div>
                  </div>
                </div>
              </div>
              <div className="flex">
                {/* left */}
                <div className="w-4/12">
                  {/* card info */}
                  <div className="space-y-2.5 mb-5 px-5">
                    {/* first card */}
                    <CardWrapper>
                      <div className="flex w-full">
                        <img
                          alt="ava"
                          src={user?.user_photo || DefaultAva}
                          style={{
                            height: "80px",
                            width: "80px",
                            maxHeight: "80px",
                            maxWidth: "80px"
                          }}
                        />

                        <div className="flex flex-col ml-3 mt-1">
                          <p className="text-2xl font-brandon_bold">{user?.user_fullname}</p>
                          <p className="text-xl">
                            {user.team1_name}
                            {user.team2_name && `${user.team1_name && ","} ${user.team2_name}`}
                            {user.team3_name != null && <span className="text-xl">, {user.team3_name}</span>}
                          </p>
                        </div>
                      </div>
                    </CardWrapper>
                    <CardWrapper>
                      <div className="flex justify-center items-center w-full">
                        <img src={AwardImage} alt="" />
                        <div className="ml-3">
                          <h3 className="text-2xl font-brandon_bold">Posisi Leaderboard: #{user.leader_position}</h3>
                          <Button
                            className="w-[220px]"
                            text="Cek Leaderboard."
                            bgColor={Colors.green}
                            onClick={() => navigate("/leaderboard")}
                            fontSize={20}
                            paddingVertical={4}
                          />
                        </div>
                      </div>
                    </CardWrapper>
                    <CardWrapper>
                      <div className="flex items-center w-full">
                        {user.user_mood && user.user_mood.toLowerCase() !== "marah" ? (
                          Constants.moodTracker.map((mood, idx) => {
                            if (mood.title.toLowerCase() === user.user_mood.toLowerCase())
                              return <img key={idx} className="w-[78px]" src={mood.icon} alt="" />;
                          })
                        ) : (
                          <img src={HappyIcon} className="w-[78px]" alt="" />
                        )}
                        <div className="mx-3">
                          <h3 className="text-2xl font-brandon_bold">Mood</h3>
                          <span className="text-lg">Kamu merasa {user.user_mood}!</span>
                        </div>

                        <Button
                          className="px-4 relative !ml-auto !mr-0 flex-1 max-w-[220px]"
                          text="Cek mood team."
                          onClick={() => navigate("/mood")}
                          bgColor={Colors.green}
                          fontSize={20}
                          paddingVertical={4}
                        />
                      </div>
                    </CardWrapper>
                    <CardWrapper>
                      {Object.keys(culture).length ? (
                        <>
                          <span className="py-1 px-[6px] text-sm bg-ileads-bgblue rounded-full block w-fit mx-auto">
                            Terakhir dinilai <strong>{convertDate(culture.cm_updated_at)}</strong>
                          </span>

                          <div className="mt-5 flex items-center">
                            <ol className="grid gap-y-4">
                              {culture?.culture_measurement_objectives?.map((cData, idx) => (
                                <li className="flex items-center" key={idx}>
                                  <div
                                    className={`w-5 h-5 border-2 border-ileads-mainblue rounded-full mr-3 ${
                                      cData.culture_measurement_takers.length ? cData.color : "bg-transparent"
                                    }`}
                                  ></div>
                                  <span className="font-brandon_bold text-sm">{cData.cm_objective_title}</span>
                                </li>
                              ))}
                            </ol>

                            <Button
                              text={culture.title}
                              bgColor={Colors.green}
                              fontSize={20}
                              width={230}
                              height={45}
                              onClick={() => navigate("/questionnaire")}
                            />
                          </div>
                        </>
                      ) : (
                        <span className="text-lg font-brandon_bold text-center block">Belum ada kuesioner!</span>
                      )}
                    </CardWrapper>
                  </div>
                  {/* icon settings */}
                  <div className="h-16 top-64 flex items-center justify-end bg-ileads-green w-64 rounded-r-settings pr-2">
                    <button onClick={() => navigate("/settings")}>
                      <Image src={SettingIcon} />
                    </button>
                  </div>

                  <Image src={AbmMan} width={400} height={400} className="rounded-2xl h-5" alt={"iLead-ava"} />
                </div>

                {/* right */}
                <div className="w-8/12 font-brandon_bold mr-5" style={{ marginTop: "-16px" }}>
                  <Outlet />
                </div>
              </div>
            </div>
          </main>
        )}
        {/* footer */}
        <Footer />
      </div>
    );
  }
}
