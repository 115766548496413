import { getQuizLists } from "Api/quiz";
import Goal from "Assets/Images/goal.png";
import Medal from "Assets/Images/medali.png";
import PaperPlane from "Assets/Images/quiz-paper-plane.png";
import { Button } from "Components";
import { useInfiniteScroll } from "Hooks";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { Colors } from "Themes";

const JuaraQuiz = () => {
  const [quizLists, setQuizLists] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const alertComponent = useAlert();
  const { loadMoreRef, page } = useInfiniteScroll(
    1,
    quizLists?.meta?.total_pages,
    isLoading
  );

  const navigate = useNavigate();

  const imageList = [PaperPlane, Goal, Medal];

  const getQuizData = async (params) => {
    try {
      setIsLoading(true);
      const quizData = await getQuizLists({
        limit: 3,
        sort: "asc",
        ...params
      });
      const dataFormater = quizData.data.map((quiz) => {
        return {
          ...quiz,
          image: imageList[Math.floor(Math.random() * imageList.length)]
        };
      });

      setQuizLists({
        ...quizData,
        data: quizLists?.data
          ? [...quizLists.data, ...dataFormater]
          : dataFormater
      });
    } catch (error) {
      alertComponent.show(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getQuizData({ page: 1 });
  }, []);

  useEffect(() => {
    getQuizData({ page });
  }, [page]);

  return (
    <section>
      <div className="flex items-center mb-4">
        <h1
          className="
          border-b-8
          text-xl
          border-ileads-yellow
          inline-block
          pl-1
          pr-2
        "
        >
          JUARA Quiz
        </h1>
      </div>

      <section
        className="
          p-5
          px-[160px]
          bg-white
          rounded-2xl
          shadow-card
          relative
        "
      >
        <h2 className="text-center text-sm mb-14">
          Yuk ikuti quiz-quiz JUARA! Buktikan seberapa JUARA-nya kamu! Skor dari
          quiz akan diakumulasikan ke poin Leaderboard kamu.
        </h2>

        {!quizLists?.data?.length ? (
          <div className="flex items-center justify-center border-t py-10">
            <span className="flex h-5 w-5 relative">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-ileads-mainblue opacity-75"></span>
              <span className="relative inline-flex rounded-full h-5 w-5 bg-ileads-mainblue"></span>
            </span>
            <h2 className="text-3xl ml-4">Belum ada quiz yang tersedia</h2>
          </div>
        ) : (
          quizLists.data.map((quiz) => (
            <div
              key={quiz.quiz_id}
              className={`
              p-8
              mb-3
              transition-all
              items-center
              hover:shadow-2xl
              hover:scale-105
              last:mb-0
              border-[3px]
              flex
              justify-between
              rounded-btn
              border-ileads-lightblue
              ${quiz.quiz_status === "done" ? "bg-ileads-bgblue" : ""}`}
            >
              <div className="flex items-center">
                <img className="h-[72px]" src={quiz.image} alt="" />
                <div className="ml-10">
                  <span className="text-ileads-lightblue text-lg">
                    {quiz.quiz_title}
                  </span>
                  <p className="text-sm mt-1">
                    {moment(quiz.quiz_created_at).format("DD MMMM YYYY")}
                  </p>
                </div>
              </div>

              {quiz.qtaker_point || quiz.isCompleted ? (
                <Button
                  className="font-bold !mx-0"
                  width={126}
                  text={`Skor: ${quiz.qtaker_point}/${quiz.total_question}`}
                  bgColor={Colors.yellow}
                  fontSize={20}
                  paddingVertical={8}
                  textColor={Colors.darkBlue}
                />
              ) : (
                <Button
                  className="font-bold !mx-0"
                  width={126}
                  text="Isi quiz."
                  bgColor={Colors.green}
                  fontSize={20}
                  paddingVertical={8}
                  onClick={() => navigate(`/juara-quiz/${quiz.quiz_id}`)}
                  textColor={Colors.white}
                />
              )}
            </div>
          ))
        )}
        {isLoading ? (
          <p className="flex items-center mt-10 mb-5 justify-center text-ileads-mainblue">
            <span className="flex h-6 w-6 items-center justify-center relative mr-2">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-ileads-lightblue opacity-75" />
              <span className="relative inline-flex rounded-full h-4 w-4 bg-ileads-mainblue" />
            </span>
            Loading more quiz...
          </p>
        ) : null}
        <div ref={loadMoreRef} />
      </section>
    </section>
  );
};

export default JuaraQuiz;
