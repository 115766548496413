import { createGlobalStyle } from "styled-components";

import BrandonBold from "../Assets/Fonts/Brandon_bold.otf";
import BrandonMedium from "../Assets/Fonts/Brandon_medium.otf";
import BrandonRegular from "../Assets/Fonts/Brandon_regular.otf";

export const FontStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }
  @font-face {
    src: url(${BrandonRegular});
    font-family: 'Brandon-Regular';
  }
  @font-face {
    src: url(${BrandonMedium});
    font-family: 'Brandon-Medium';
  }
  @font-face {
    src: url(${BrandonBold});
    font-family: 'Brandon-Bold';
  }
`;
