/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */
import { useOnClickOutside } from "Hooks";
import React, { useRef, useState } from "react";

const ArticleImage = ({ images }) => {
  const modal = useRef(null);
  const [openModal, setOpenModal] = useState(null);

  useOnClickOutside(modal, () => setOpenModal(null));

  const isVideo = typeof images === "string" && images.endsWith(".mp4");
  const containerClasses = "rounded-2xl object-cover";

  const computeTag = (link, small) => {
    return isVideo ? (
      <video
        controls
        className={`${containerClasses} max-w-[568px]`}
        src={link}
      />
    ) : (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <img
        onClick={() => setOpenModal(link)}
        className={`${containerClasses} w-[568px] ${
          small ? "h-[calc((100%/2)-6px)]" : "h-[302px]"
        }`}
        src={link}
        alt=""
      />
    );
  };

  const renderArrayImages = () => {
    return Array.isArray(images) && images?.length < 3 ? (
      images.map((image, idx) => <div key={idx}>{computeTag(image)}</div>)
    ) : (
      <>
        <div>{computeTag(images[0])}</div>
        <div className="flex flex-1 flex-col gap-3">
          {computeTag(images[1], true)}
          {computeTag(images[2], true)}
        </div>
      </>
    );
  };

  const renderImage = () => {
    return Array.isArray(images) ? renderArrayImages() : computeTag(images);
  };

  return (
    <>
      {openModal ? (
        <div className="modal fixed top-0 left-0 z-50">
          <div className="modal__backdrop w-screen h-screen backdrop-blur-md bg-black/70 flex justify-center items-center p-20">
            <img ref={modal} src={openModal} alt="" className="h-full" />
          </div>
        </div>
      ) : null}
      <div
        className={`flex justify-center gap-3 ${
          isVideo ? "" : "max-h-[302px]"
        }`}
      >
        {images?.length && images !== "undefined" ? renderImage() : null}
      </div>
    </>
  );
};

export default ArticleImage;
