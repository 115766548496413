import React from "react";
import { Provider as AlertProvider } from "react-alert";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AlertTemplate } from "./Components";
import { Constants } from "./Constants";
import reportWebVitals from "./reportWebVitals";
import { FontStyles } from "./Themes";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <AlertProvider
      template={ AlertTemplate }
      { ...Constants.AlertOptions }>
      <App />
      <FontStyles />
    </AlertProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
