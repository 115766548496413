import styled from "styled-components";

import { Colors } from "../../Themes";

const Wrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

const TagWrapper = styled.div`
  position: absolute;
  background-color: ${Colors.mainBlue};
  height: 120px;
  padding: 10px;
  left:45vw;
  display: flex;
  align-items: flex-end;
  border-bottom-left-radius: 75px;
  border-bottom-right-radius: 75px;
`;

const FormWrapper = styled.div `
  padding: 30px 0px 0px 0px;
  width: 730px;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;

const FormInnerWrapper = styled.form `
  padding: 85px 97px 85px 97px;
`;

const Title = styled.p`
  font-size: 30px;
  font-family: 'Brandon-Medium';
  color: ${Colors.darkBlue};
  text-align: center;
  border-bottom: 7px solid ${Colors.yellow};
`;

const Subtitle = styled(Title)`
  font-size: 20px;
  color: ${Colors.mainBlue};
  border-bottom: 0px;
  margin-top: 25px;
  margin-bottom: 90px;
`;

const FormLabel = styled(Title)`
  font-size: 22px;
  border-bottom: 0px;
  margin-bottom: 25px;
`;

const OtpInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 75px;
`;

const OtpInputStyle = {
  backgroundColor: Colors.bgBlue,
  borderColor: Colors.lightBlue,
  borderRadius: "20px",
  borderWidth: 2,
  color: Colors.mainBlue,
  fontFamily: "Brandon-Medium",
  fontSize: "28px",
  height: "88px",
  marginLeft: "12.5px",
  marginRight: "12.5px",
  width: "75px"
};

const ResendEmailText = styled(FormLabel)`
  margin-top: 25px;
  color: ${Colors.lightBlue};
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
`;

const HintOuterWrapper = styled.div`
  margin-top: -66px;
  display: flex;
  justify-content: flex-end;
`;

export {
  Wrapper,
  TagWrapper,
  FormWrapper,
  FormInnerWrapper,
  Title,
  Subtitle,
  FormLabel,
  OtpInputContainer,
  OtpInputStyle,
  ResendEmailText,
  HintOuterWrapper,
  LoaderWrapper
};
