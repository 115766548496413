import { API } from "Api";
import { ReactComponent as NextIcon } from "Assets/Icons/next-icon.svg";
import { ReactComponent as PrevIcon } from "Assets/Icons/prev-icon.svg";
import { EmptyJournal, JournalSeeFeedback, Note, PlusWhite } from "Assets/Images";
import { CircularLoader, Image, Pagination } from "Components";
import { useUsers } from "Context/UsersStateManagement";
import { groupBy } from "lodash";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Colors } from "Themes";
import { ObjectUtils } from "Utils";
import { ContentWrapper, DateWrapper } from "./index.styles";
import "./index.css";

const JournalTypeColors = {
  kpi: "yellow",
  "kpi coaching": "yellow",
  "project culture coaching": "purple",
  "project culture": "purple",
  others: "lightblue",
  coached: "green",
  other: "lightblue"
};

export default function CoachingJournalPage() {
  const navigate = useNavigate();
  const { user } = useUsers();
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [journals, setJournals] = React.useState({});

  const handleNavigateNotes = (journalId, isCoachee, learner, title, created_at, type) => () => {
    if (!isCoachee) navigate("notes", { state: { journalId, isCoachee, learner, title, created_at, type } });
    else
      navigate("notes/fill", {
        state: {
          journalId,
          isCoachee,
          learner,
          title,
          date: created_at,
          type
        }
      });
  };

  const handleNavigateAdd = () => {
    navigate("add");
  };

  const handleNavigateFeedback = (journalId) => () => {
    navigate("feedback", { state: { journalId } });
  };

  const getCoachingJournals = async () => {
    setLoading(true);
    try {
      const { data } = await API.coachingJournalList(null, page);
      setTotalPages(data.meta.total_pages);
      setJournals(groupBy(data.data.journal, (journal) => moment(journal.journal_date).format("MM-DD-YYYY")));
    } catch (err) {
      setJournals({});
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getCoachingJournals();
  }, [page]);

  const renderJournalDate = (journal) => (
    <div className="w-2/12">
      <DateWrapper>
        <div className="absolute right-5 bottom-5">
          <span className="font-brandon_bold text-2xl">
            {moment(new Date(journal)).locale("id").format("DD")}
            <br />
            {moment(new Date(journal)).locale("id").format("MMM")}
          </span>
        </div>
      </DateWrapper>
    </div>
  );

  const renderLoader = () => (
    <div className="flex items-center justify-center my-12">
      <CircularLoader size={30} color={Colors.yellow} unfilledColor={Colors.darkBlue} />
    </div>
  );

  const renderNoData = () => <img src={EmptyJournal} alt="" draggable="false" />;

  const renderPagination = () => (
    <Pagination
      className="mx-auto"
      current={page}
      max={totalPages}
      onJump={(value) => setPage(value)}
      onPrev={() => setPage(page - 1)}
      onNext={() => setPage(page + 1)}
    />
  );

  return (
    <div>
      <div className="flex flex-row">
        <div>
          <p className="font-brandon_bold text-ileads-mainblue text-lg">Coaching Journals</p>
          <div className="bg-ileads-yellow h-2 w-32" />
        </div>
        <button
          type="submit"
          onClick={handleNavigateAdd}
          className="bg-ileads-lightblue py-1.5 rounded-btn px-4 whitespace-nowrap mb-2 ml-6 flex flex-row"
        >
          <div className="my-auto mr-2">
            <Image src={PlusWhite} width={14} height={14} />
          </div>
          <span className="font-brandon_medium text-white text-center">Tambah coaching journal</span>
        </button>
      </div>
      <div className="flex flex-col pr-5 relative">
        <ContentWrapper className={`mt-4 ${ObjectUtils.isEmpty(journals) ? "!p-0" : ""}`}>
          {loading ? (
            renderLoader()
          ) : (
            <React.Fragment>
              {ObjectUtils.isEmpty(journals) ? (
                renderNoData()
              ) : (
                <React.Fragment>
                  {Object.keys(journals).map((journal, index) => (
                    <div className="flex mb-12" key={index}>
                      {renderJournalDate(journal)}
                      <div className="flex flex-col w-10/12">
                        {journals[journal].map((item, index) => (
                          <div key={index} className="flex flex-col">
                            <div className="flex w-full">
                              <div className=" pl-10 justify-between text-lg w-full">
                                <div
                                  className={"grid grid-cols-2 py-10 h-fit"}
                                  style={{
                                    borderBottom: "3px solid lightblue"
                                  }}
                                >
                                  <div className="flex flex-row">
                                    <div className="my-auto">
                                      <div
                                        className={`w-5 h-5 rounded-xl bg-ileads-${
                                          item.is_coachee ? "green" : JournalTypeColors[item.journal_type.toLowerCase()]
                                        }`}
                                      />
                                    </div>
                                    <div className="flex flex-col ml-8">
                                      <div className="overflow-hidden truncate max-w-xs">
                                        <span
                                          className={` text-ellipsis font-brandon_bold ${
                                            item.is_coachee
                                              ? "bg-ileads-green text-center rounded-2xl px-5 text-white"
                                              : "text-ileads-mainblue"
                                          }`}
                                        >
                                          {item.journal_title}
                                        </span>
                                      </div>
                                      <span>
                                        <span
                                          className={`text-ileads-${
                                            item.is_coachee
                                              ? "green"
                                              : JournalTypeColors[item.journal_type.toLowerCase()]
                                          } font-brandon`}
                                        >
                                          {item.is_coachee ? "Coached by" : "You coached"}
                                        </span>{" "}
                                        <div
                                          className={
                                            item.journal_learner.length > 2
                                              ? "flex text-left flex-wrap w-8/12"
                                              : "flex text-left flex-wrap w-full"
                                          }
                                        >
                                          {item.is_coachee ? (
                                            <span className="font-brandon text-ileads-mainblue">
                                              {item.coach_fullname}
                                            </span>
                                          ) : (
                                            <>
                                              {item.journal_learner.map((learner, indexLearner) => {
                                                return (
                                                  <div key={indexLearner}>
                                                    <span className="font-brandon text-ileads-mainblue">
                                                      {learner.jl_fullname}{" "}
                                                      {indexLearner !== item.journal_learner.length - 1 && ","}
                                                      &nbsp;
                                                    </span>
                                                  </div>
                                                );
                                              })}
                                            </>
                                          )}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                  {item.is_coachee && !item.journal_learner[0].is_filled ? (
                                    <div className="flex justify-end items-center pr-10">
                                      <button
                                        tabIndex={0}
                                        onClick={() => {
                                          navigate("notes/fill", {
                                            state: {
                                              journalId: item.journal_id,
                                              isCoachee: item.is_coachee,
                                              learner: item.journal_learner,
                                              title: item.journal_title,
                                              date: item.journal_created_at,
                                              type: item.journal_type,
                                              fill: true
                                            }
                                          });
                                        }}
                                        className="flex justify-end font-brandon_bold text-center cursor-pointer w-full"
                                      >
                                        <div className="flex flex-row">
                                          <Image src={Note} alt="note" className="cursor-pointer mx-2" />
                                        </div>
                                        <span className="ml-4 font-brandon_medium transition-colors text-ileads-lightblue feedback hover:text-ileads-mainblue">
                                          Isi catatan
                                        </span>
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="flex justify-end items-center pr-10">
                                      <button
                                        tabIndex={0}
                                        onClick={handleNavigateNotes(
                                          item.journal_id,
                                          item.is_coachee,
                                          item.journal_learner,
                                          item.journal_title,
                                          item.journal_created_at,
                                          item.journal_type
                                        )}
                                        className="flex font-brandon_bold text-center cursor-pointer"
                                      >
                                        <Image src={Note} alt="note" className="cursor-pointer mr-2" />
                                        <span className="ml-4 feedback font-brandon_medium transition-colors text-ileads-lightblue hover:text-ileads-mainblue">
                                          Lihat catatan
                                        </span>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </ContentWrapper>
      </div>
      {renderPagination()}
    </div>
  );
}
