import { API } from "Api";
import { CalendarPen, CloseRound, DropdownArrowDown, DropdownArrowUp, HappyIcon, SearchIcon } from "Assets/Images";
import { Button, CircularLoader, DropdownInput, Image, Modal, Upload } from "Components";
import { Constants } from "Constants";
import { useUsers } from "Context/UsersStateManagement";
import { useYupValidationResolver } from "Hooks";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { AddCoachingJournalSchema } from "Schemas";
import { Colors } from "Themes";
import { DropdownOptions } from "Utils";
import { ContentWrapper, DropdownContainer } from "./index.styles";
import "react-datepicker/dist/react-datepicker.css";
import { uploadAttachment } from "Api/upload";
import { createJournal } from "Api/coachingJournal";

const { CoachingJournalTypes } = Constants;

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    backgroundColor: Colors.white,
    borderRadius: 45
  }
};

export default function CoachingJournalAddPage() {
  const { user } = useUsers();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [userList, setUserList] = useState([]);
  const [file, setFile] = useState(null);
  const [searchUser, setSearchUser] = useState("");
  const [showUserOptions, setShowUserOptions] = useState(false);
  const [userPage, setUserPage] = useState(1);
  const [endUserPage, setEndUserPage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // const [showConfirmFeedback, setShowConfirmFeedback] = useState(false);
  const resolver = useYupValidationResolver(AddCoachingJournalSchema);
  const {
    control,
    formState: { isSubmitting, errors },
    getValues,
    handleSubmit
  } = useForm({
    resolver,
    defaultValues: {
      date: new Date(),
      title: "",
      content: "",
      strength: "",
      improvement: "",
      type: "",
      label: "",
      recommendationForCoachee: "",
      learnerIds: [],
      documentsUrl: ""
    }
  });

  const handleNavigateBack = () => {
    navigate(-1);
  };
  const limitChar = (e, field) => {
    if (e.target.value.length <= 2500) field.onChange(e);
    else field.onChange(e.target.value.slice(0, 2500));
  };

  const getUsersFullName = (currentUsers) => {
    const filteredUsers = userList.filter((item) =>
      currentUsers.some((f) => {
        return f === item.id;
      })
    );

    return filteredUsers
      .reverse()
      .map((item) => item.fullname)
      .join(", ");
  };

  const handleSelectUser = (currentUsers, user) => {
    let users = currentUsers || getValues().learnerIds;
    if (users.includes(user.id)) {
      users.splice(users.indexOf(user.id), 1);
    } else {
      if (users.length < 5) {
        users = [...users, user.id];
      }
    }

    return users;
  };

  const onSubmit = async () => {
    if (!file) {
      await createJournal({ ...getValues() });
      setShowModal(true);
      return;
    }

    const formData = new FormData();
    formData.append("files", file);

    const { urls: documentsUrl } = await uploadAttachment(formData);
    await createJournal({ ...getValues(), documentsUrl });
    setShowModal(true);
  };

  const toggleUserOptions = () => {
    setShowUserOptions(!showUserOptions);
  };

  const getUsers = async (type) => {
    try {
      if (type === "page") {
        const {
          data: { data: responseData }
        } = await API.userList(userPage, searchUser);
        setUserList([...userList, ...responseData]);
      } else {
        const {
          data: { data: responseData }
        } = await API.userList(1, searchUser);
        setUserList(responseData);
        setUserPage(1);
        setEndUserPage(false);
      }
    } catch (err) {
      setEndUserPage(true);
    }
  };

  const handleUserListScroll = (e) => {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight && !endUserPage) {
      setUserPage(userPage + 1);
    }
  };

  useEffect(() => {
    getUsers("page");
  }, [userPage]);

  useEffect(() => {
    getUsers("search");
  }, [searchUser]);

  const renderHeader = () => (
    <div className="flex flex-row mb-4">
      <div className="mr-6">
        <p className="font-brandon_bold text-ileads-mainblue text-lg">Tambah coaching journal</p>
        <div className="bg-ileads-yellow h-2 w-42" />
      </div>
      <button
        onClick={handleNavigateBack}
        className="bg-ileads-red text-white text-center py-1.5 rounded-btn px-4 whitespace-nowrap"
      >
        <span className="font-brandon_medium">Cancel</span>
      </button>
    </div>
  );

  const renderCoachForm = () => (
    <>
      <span className="text-ileads-mainblue font-brandon_bold text-lg">
        Dari sesi coaching, apa yang sudah coachee lakukan dengan <span className="text-ileads-lightblue">efektif</span>
        ?
      </span>
      <Controller
        name="strength"
        control={control}
        rules={{ maxLength: 500 }}
        render={({ field }) => (
          <>
            <div
              className={`textarea-${
                errors["strength"] ? "error" : "primary"
              } bg-ileads-white flex items-center space-x-1.5 px-2 mt-2 mb-1`}
            >
              <textarea
                cols="40"
                rows="3"
                value={field.value}
                onChange={(e) => limitChar(e, field)}
                className="input-password-custom text-left text-ileads-mainblue w-full text-ellipsis font-brandon"
                name="strength"
              />
            </div>
            <div className="flex items-end justify-end mb-3">
              <p className="font-brandon text-[11px]">{field.value?.length || 0}/2500</p>
            </div>
          </>
        )}
      />
      <span className="text-ileads-mainblue font-brandon_bold text-lg">
        Dari sesi coaching, kualitas apa yang dapat coachee <span className="text-ileads-lightblue">tingkatkan</span>{" "}
        lagi?
      </span>
      <Controller
        name="improvement"
        control={control}
        rules={{ maxLength: 500 }}
        render={({ field }) => (
          <>
            <div
              className={`textarea-${
                errors["improvement"] ? "error" : "primary"
              } bg-ileads-white flex items-center space-x-1.5 px-2 mt-2 mb-1`}
            >
              <textarea
                cols="40"
                rows="3"
                value={field.value}
                onChange={(e) => limitChar(e, field)}
                className="input-password-custom text-left text-ileads-mainblue w-full text-ellipsis font-brandon"
                name="improvement"
              />
            </div>
            <div className="flex items-end justify-end mb-3">
              <p className="font-brandon text-[11px]">{field.value?.length || 0}/2500</p>
            </div>
          </>
        )}
      />
    </>
  );

  const renderCoacheeForm = () => (
    <>
      <span className="font-brandon_bold text-lg">
        Tulislah “<span className="text-ileads-lightblue">lessons learned</span>”-mu di coaching session ini.
      </span>
      <Controller
        name="lessonsLearned"
        control={control}
        rules={{ maxLength: 500 }}
        render={({ field }) => (
          <>
            <div
              className={`textarea-${
                errors["lessonsLearned"] ? "error" : "primary"
              } bg-ileads-white flex items-center space-x-1.5 px-2 mt-2 mb-1`}
            >
              <textarea
                cols="40"
                rows="3"
                value={field.value}
                onChange={(e) => limitChar(e, field)}
                className="input-password-custom text-left text-ileads-mainblue w-full text-ellipsis font-brandon"
                name="lessonsLearned"
              />
            </div>
            <div className="flex items-end justify-end mb-3">
              <p className="font-brandon text-[11px]">{field.value?.length || 0}/2500</p>
            </div>
          </>
        )}
      />
    </>
  );

  const renderJournalContent = () => (
    <>
      <span className="text-ileads-mainblue font-brandon_bold text-lg">
        Apa yang <span className="text-ileads-lightblue">dibicarakan</span> saat coaching?
      </span>
      <Controller
        name="content"
        control={control}
        rules={{ maxLength: 500 }}
        render={({ field }) => (
          <>
            <div
              className={`textarea-${
                errors["content"] ? "error" : "primary"
              } bg-ileads-white flex items-center space-x-1.5 px-2 mt-2 mb-1`}
            >
              <textarea
                cols="40"
                rows="3"
                value={field.value}
                onChange={(e) => limitChar(e, field)}
                className="input-password-custom text-left text-ileads-mainblue w-full text-ellipsis font-brandon"
                name="content"
              />
            </div>
            <div className="flex items-end justify-end mb-3">
              <p className="font-brandon text-[11px]">{field.value?.length || 0}/2500</p>
            </div>
            <Upload
              className="mb-3"
              onChange={(value) => setFile(value)}
              onError={() => {}}
              max={2}
              accept=".docx, .pdf, .xls, .xlsx, .ppt, .pptx"
              fileDesc=".docx, .pdf, .xls, dan .ppt"
            />
          </>
        )}
      />
    </>
  );

  const renderJournalCommitment = () => (
    <>
      <span className={`font-brandon_bold text-ileads-${errors["commitment"] ? "red" : "mainblue"} text-lg`}>
        Dari sesi coaching,
        <span className={`text-ileads-${errors["commitment"] ? "red" : "lightblue"} `}>
          {" "}
          apa rekomendasi saya untuk coachee
        </span>
        ?
      </span>
      <Controller
        name="recommendationForCoachee"
        control={control}
        rules={{ maxLength: 500 }}
        render={({ field }) => (
          <>
            <div
              className={`textarea-${
                errors["recommendationForCoachee"] ? "error" : "primary"
              } bg-ileads-white flex items-center space-x-1.5 px-2 mt-2 mb-1`}
            >
              <textarea
                cols="40"
                rows="3"
                value={field.value}
                onChange={(e) => limitChar(e, field)}
                className="input-password-custom text-left text-ileads-mainblue w-full text-ellipsis font-brandon"
                name="recommendationForCoachee"
              />
            </div>
            <div className="flex items-end justify-end mb-3">
              <p className="font-brandon text-[11px]">{field.value?.length || 0}/2500</p>
            </div>
          </>
        )}
      />
    </>
  );

  const renderJournalType = () => (
    <>
      <span className="text-ileads-mainblue font-brandon_bold text-lg">Pilih kategori coaching:</span>
      <Controller
        name="type"
        control={control}
        render={({ field }) => (
          <>
            <DropdownContainer>
              <DropdownInput
                type={"secondary"}
                options={DropdownOptions.convertToOptions(CoachingJournalTypes, "name", "key")}
                value={field.value}
                onSelect={(objValue) => {
                  field.onChange(objValue.value);
                }}
                name={"type"}
                placeholder={!field.value && "Pilih kategori"}
                error={errors["type"]}
                wrapperClassName="w-60"
                textClassName="input-password-custom"
                fontSize={16}
              />
            </DropdownContainer>
            {field.value === "Others" && (
              <Controller
                name="label"
                control={control}
                render={({ field: label }) => (
                  <div className="flex flex-row mt-2 mb-6 w-60">
                    <div
                      className={`input-${
                        errors["label"] ? "error" : "primary"
                      } bg-ileads-white flex items-center space-x-1.5 px-4 w-96`}
                    >
                      <input
                        value={label.value}
                        placeholder="Tulis kategori coaching disini."
                        onChange={label.onChange}
                        className="input-password-custom text-left text-ileads-mainblue w-full text-ellipsis font-brandon"
                        name="label"
                      />
                    </div>
                  </div>
                )}
              />
            )}
          </>
        )}
      />
    </>
  );

  return (
    <div>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        title="Sukses!"
        desc="Catatan telah sukses disimpan!"
        icon={<img className="w-[160px]" src={HappyIcon} alt="" />}
        buttons={
          <Button
            type="button"
            onClick={() => navigate("/coaching-journals")}
            text="Kembali ke Catatan"
            bgColor={Colors.green}
            width={300}
            paddingVertical={12}
            fontSize={20}
          />
        }
      />
      {renderHeader()}
      <ContentWrapper
        className={`border-2 ${Object.keys(errors).length ? "border-ileads-danger-3" : "border-transparent"}`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <>
          {!!Object.keys(errors).length && (
            <h3 className="text-center text-ileads-danger-3 mb-2">
              Ups! Sepertinya ada kolom yang belum diisi! Silahkan dicek kembali dan isi semua kolom yang tersedia!
            </h3>
          )}
          <div className="flex flex-row">
            <div>
              <span className="text-ileads-mainblue font-brandon_bold text-lg">
                Tuliskan <span className="text-ileads-lightblue">judul</span> sesi coaching
              </span>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <div className="flex flex-row mt-2 mb-6">
                    <div
                      className={`input-${
                        errors["title"] ? "error" : "primary"
                      } bg-ileads-white flex items-center space-x-1.5 px-4 w-96`}
                    >
                      <input
                        value={field.value}
                        placeholder="Tulis nama judul sesi coaching di sini."
                        onChange={field.onChange}
                        className="input-password-custom text-left text-ileads-mainblue w-full text-ellipsis font-brandon"
                        name="title"
                      />
                    </div>
                  </div>
                )}
              />
            </div>
            <div className="mt-10 mx-4">
              <span className="text-ileads-mainblue font-brandon_bold text-lg">dengan</span>
            </div>
            <div className="mt-9">
              <Controller
                name="learnerIds"
                control={control}
                render={({ field }) => (
                  <div className="flex flex-col relative">
                    <button
                      type="button"
                      onClick={toggleUserOptions}
                      className={`flex justify-between items-center py-1.5 px-6 border-2 border-ileads-${
                        errors["learnerIds"] ? "red" : "lightblue"
                      } rounded-3xl ${showUserOptions && "rounded-b-none border-b-0"}`}
                      style={{ width: 340 }}
                    >
                      {field.value?.length > 0 ? (
                        <span className="text-md font-brandon text-ileads-mainblue overflow-hidden truncate w-56 text-left">
                          {getUsersFullName(field.value)}
                        </span>
                      ) : (
                        <span className="text-md font-brandon text-ileads-grey">Pilih coachee (max. 5 orang)</span>
                      )}
                      <div>
                        <Image
                          width={19}
                          height={19}
                          src={!showUserOptions ? DropdownArrowDown : DropdownArrowUp}
                          alt="dropdown-arrow"
                        />
                      </div>
                    </button>

                    {showUserOptions && (
                      <div
                        className={`flex flex-col space-y-2 pl-3 pr-2 rounded-input border-2 border-t-0 py-2 rounded-t-none border-ileads-${
                          errors["learnerIds"] ? "red" : "lightblue"
                        }`}
                      >
                        <div>
                          <div className="flex justify-between items-center text-ileads-mainblue font-brandon">
                            <input
                              type="text"
                              className="border-none search"
                              placeholder="Cari coachee"
                              value={searchUser}
                              onChange={(e) => {
                                setSearchUser(e.target.value);
                              }}
                            />
                            <Image src={SearchIcon} width={22} height={22} />
                          </div>
                          <hr className="mr-1 ml-3 mt-1" />
                        </div>
                        {/* list option */}
                        <div
                          onScroll={handleUserListScroll}
                          className={`space-y-1.5 pl-3 overflow-y-auto h-48 list-option flex flex-col justify-start ${
                            !userList.length && "h-6"
                          }`}
                        >
                          {userList.length ? (
                            userList.map((option, id) => (
                              <button
                                type="button"
                                key={id}
                                onClick={() => {
                                  field.onChange(handleSelectUser(field.value, option));
                                }}
                                className="text-left flex flex-row"
                              >
                                <div className="my-auto">
                                  {field.value?.includes(option.id) ? (
                                    <div className="border-2 border-ileads-yellow w-6 h-6 rounded-md mr-3 bg-ileads-yellow" />
                                  ) : (
                                    <div className="border-2 border-ileads-lightblue w-6 h-6 rounded-md mr-3" />
                                  )}
                                </div>
                                <span className="text-md font-brandon text-ileads-mainblue">{option.fullname}</span>
                              </button>
                            ))
                          ) : (
                            <p className="text-center font-brandon">No results found</p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
          </div>
          <span className="font-brandon_bold text-ileads-mainblue text-lg">
            Pilih <span className="text-ileads-lightblue">tanggal</span> sesi coaching
          </span>
          <div className="flex flex-row mt-2 mb-6">
            <div className="my-auto mr-2">
              <Image src={CalendarPen} width={23} height={23} />
            </div>
            <div className="input-primary bg-ileads-white flex items-center space-x-1.5 px-2 w-60">
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <div className="input-password-custom text-center text-ileads-mainblue w-full cursor-pointer">
                    <DatePicker
                      selected={field.value ? new Date(field.value) : new Date()}
                      onChange={field.onChange}
                      customInput={<span className="font-brandon">{moment(field.value).format("DD MMMM YYYY")}</span>}
                    />
                  </div>
                )}
              />
            </div>
          </div>
          {renderJournalContent()}
          {!state?.isCoachee ? renderCoachForm() : renderCoacheeForm()}
          {renderJournalCommitment()}
          {renderJournalType()}
          <div className="mb-3 flex items-center justify-center">
            <Button
              type="submit"
              className="mt-4"
              text="Simpan Catatan"
              isLoading={isSubmitting}
              disabled={isSubmitting}
              bgColor={Colors.green}
              width={187}
              paddingVertical={12}
              fontSize={20}
            />
          </div>
          <div className="mb-12 flex items-center text-ileads-mainblue justify-center">
            Penting! Catatan coaching-mu belum tersimpan sampai kamu klik “
            <span className="text-ileads-green">Simpan Catatan</span>”.
          </div>
        </>
      </ContentWrapper>
    </div>
  );
}
