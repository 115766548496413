import { Button } from "Components";
import { Constants } from "Constants";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Colors } from "Themes";

const FeedGuideLine = () => {
  const navigate = useNavigate();

  return (
    <section>
      <div className="flex items-center mb-4">
        <h1
          className="
          border-b-8
          text-xl
          border-ileads-yellow
          inline-block
          pl-1
          pr-2
        "
        >
          Guideline Feed
        </h1>
        <Button
          className="!mr-0 !ml-5 font-normal"
          width={75}
          text="Kembali"
          onClick={() => navigate(-1)}
          bgColor={Colors.lightBlue}
          fontSize={14}
          paddingVertical={8}
          textColor={Colors.white}
        />
      </div>

      <section
        className="
          p-5
          px-[140px]
          bg-white
          rounded-2xl
          shadow-card
          relative
          flex
          items-center
          flex-col
          gap-y-4
          text-xs
          font-brandon
        "
      >
        {Constants.feedGuidlines.map((guide) => (
          <>
            <strong className="font-brandon_bold">{guide.title}</strong>
            <ol>
              {guide.rules.map((rule, idx) => (
                <li className="text-center" key={idx}>
                  {idx + 1}. {rule}
                </li>
              ))}
            </ol>
            {guide.extra && <span className="text-center">{guide.extra}</span>}
          </>
        ))}
      </section>
    </section>
  );
};

export default FeedGuideLine;
