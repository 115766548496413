import { getJournalLearnerDetail, updateJournalLearner } from "Api/coachingJournal";
import { uploadAttachment } from "Api/upload";
import { CalendarPen, HappyIcon } from "Assets/Images";
import { Button, Image, Modal, Underlined, Upload } from "Components";
import { useYupValidationResolver } from "Hooks";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { JournalFillSchema } from "Schemas";
import { Colors } from "Themes";

const renderTextArea = (control, errors, name, title, disabled) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className="flex flex-col my-1">
          {title}
          <textarea
            disabled={disabled}
            className={`${disabled ? "bg-ileads-bgblue" : "bg-transparent"} mt-1 min-h-[120px] border-2 rounded-xl ${
              errors[name] ? "border-ileads-danger-3" : "border-ileads-darkblue"
            }`}
            value={field.value}
            onChange={(e) => {
              if (e.target.value.length <= 2500) field.onChange(e);
              else field.onChange(e.target.value.slice(0, 2500));
            }}
          />
          <div className="flex items-end justify-end mb-3">
            <p className="font-brandon text-[11px]">{field.value?.length || 0}/2500</p>
          </div>
        </div>
      )}
    />
  );
};

const JournalCoacheeNotes = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const resolver = useYupValidationResolver(JournalFillSchema);
  const {
    control,
    setValue,
    formState: { isSubmitting, errors },
    handleSubmit
  } = useForm({ resolver });

  const [file, setFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [detail, setDetail] = useState({});

  const onSubmitForm = async (payload) => {
    if (file) {
      const formData = new FormData();
      formData.append("files", file);
      const { urls } = await uploadAttachment(formData);
      setValue("documentsUrl", urls);
    }

    await updateJournalLearner(state?.learner[0].jl_id, payload);
    setShowModal(true);
  };

  useEffect(() => {
    getJournalLearnerDetail(state?.learner[0].jl_id).then(({ data }) => {
      setDetail(data);
      setValue("content", data.jl_content);
      setValue("lessonsLearned", data.jl_lesson_learned);
      setValue("commitment", data.jl_commitment);
      setIsEditable(false);
    });
  }, [setValue, state]);

  return (
    <section>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        title="Sukses!"
        desc="Catatan telah sukses disimpan!"
        icon={<img className="w-[160px]" src={HappyIcon} alt="" />}
        buttons={
          <Button
            type="button"
            onClick={() => navigate("/coaching-journals")}
            text="Kembali ke Menu Utama Coaching Journal"
            bgColor={Colors.green}
            width={400}
            paddingVertical={12}
            fontSize={20}
          />
        }
      />
      <div className="flex items-center mb-4">
        <Underlined className="mr-4">
          {!state?.fill ? "Coaching Journal Overview." : "Isi Coaching Journal."}
        </Underlined>
        <Button
          className="!mx-0"
          fontSize={20}
          text={!state?.fill ? "Edit" : "Batal"}
          width={82}
          paddingVertical={8}
          height={34}
          onClick={() => (!state?.fill ? setIsEditable(!isEditable) : navigate(-1))}
          bgColor={!state?.fill ? Colors.lightBlue : Colors.red}
        />
        {!state?.fill && (
          <Button
            className="!mr-0"
            fontSize={20}
            text="Kembali"
            width={82}
            paddingVertical={8}
            height={34}
            onClick={() => navigate(-1)}
            bgColor={Colors.lightBlue}
          />
        )}
      </div>

      <form className="p-5 bg-white rounded-2xl shadow-card" onSubmit={handleSubmit(onSubmitForm)}>
        {!!Object.keys(errors).length && (
          <h3 className="text-center text-ileads-danger-3 mb-2">
            Ups! Sepertinya ada kolom yang belum diisi! Silahkan dicek kembali dan isi semua kolom yang tersedia!
          </h3>
        )}
        {/* TITLE SECTION */}
        <div>
          <span className="text-ileads-mainblue font-brandon_bold text-lg">
            Tuliskan <em className="not-italic text-ileads-lightblue">judul</em> sesi coaching.
          </span>
          <div className="mt-1 flex items-center">
            <input
              className="bg-ileads-bgblue p-3 min-w-[401px] h-[47px] border-2 border-ileads-darkblue rounded-xl"
              value={state?.title}
              disabled
            />
            <span className="mx-3">dengan</span>
            {state?.learner.map((learner, idx) => (
              <span key={idx}>{learner.jl_fullname}</span>
            ))}
          </div>
        </div>
        {/* END TITLE SECTION */}
        {/* DATE SECTION */}
        <div className="mt-2">
          <span className="text-ileads-mainblue font-brandon_bold text-lg">
            Pilih <em className="not-italic text-ileads-lightblue">tanggal</em> sesi coaching.
          </span>

          <div className="flex items-center">
            <Image src={CalendarPen} width={23} height={23} />
            <div className="p-3 ml-3 bg-ileads-bgblue border-2 h-[47px] border-ileads-darkblue rounded-xl">
              <ReactDatePicker
                disabled
                selected={new Date(state?.date)}
                customInput={<span>{moment(state?.date).format("DD MMMM YYYY")}</span>}
              />
            </div>
          </div>
        </div>
        {/* END DATE SECTION */}

        {
          state?.fill && (
            <Button
              className="my-4"
              fontSize={20}
              text="Lihat Catatan Coach"
              width={226}
              paddingVertical={8}
              height={53}
              onClick={() => navigate("/coaching-journals/notes/coachee", {
                state: { ...detail.journal, name: detail.coach_fullname, isCoachee: true, learner: state?.learner }
              })}
              bgColor={Colors.yellow}
              textColor={Colors.darkBlue}
            />
          )
        }

        {/* CONTENT SECTION */}
        {renderTextArea(
          control,
          errors,
          "content",
          <span className="text-ileads-mainblue font-brandon_bold text-lg">
            Apa yang <em className="not-italic text-ileads-lightblue">dibicarakan</em> saat coaching?
          </span>,
          !state?.fill && !isEditable
        )}
        {/* END CONTENT SECTION */}

        <Upload
          className="mt-4"
          onChange={(value) => {
            if (!(value instanceof File)) setValue("documentsUrl", "");
            setFile(value);
          }}
          onError={() => {}}
          isDownload={!state?.fill && !isEditable}
          max={2}
          value={detail?.jl_documents_url}
          accept=".docx, .pdf, .xls, .xlsx, .ppt, .pptx"
          fileDesc=".docx, .pdf, .xls, dan .ppt"
        />

        {/* LESSON LEARNED SECTION */}
        {renderTextArea(
          control,
          errors,
          "lessonsLearned",
          <span className="text-ileads-mainblue font-brandon_bold text-lg">
            Tuliskan <em className="not-italic text-ileads-lightblue">“lessons learned”</em> -mu di coaching session
            ini.{" "}
          </span>,
          !state?.fill && !isEditable
        )}
        {/* END LESSON LEARNED SECTION */}

        {/* COMMITMENT SECTION */}
        {renderTextArea(
          control,
          errors,
          "commitment",
          <span className="text-ileads-mainblue font-brandon_bold text-lg">
            <em className="not-italic text-ileads-lightblue">Komitmen</em> apa saja yang sudah disepakati bersama?
          </span>,
          !state?.fill && !isEditable
        )}
        {/* END COMMITMENT SECTION */}

        <div className="mt-4">
          <span className="text-ileads-mainblue font-brandon_bold text-lg">Pilih kategori coaching:</span>

          <div className="input-primary bg-ileads-bgblue flex items-center space-x-1.5 px-2 w-60 mt-2">
            <span className="input-password-custom text-center text-ileads-mainblue w-full text-ellipsis font-brandon">
              {state?.type}
            </span>
          </div>
          {state?.type === "Others" && (
            <div className="input-primary bg-ileads-bgblue flex items-center space-x-1.5 px-2 w-60 mt-2">
              <span className="input-password-custom text-center text-ileads-mainblue w-full text-ellipsis font-brandon">
                {state?.label}
              </span>
            </div>
          )}
        </div>

        <Button
          text={!state?.fill && !isEditable ? "Lihat Catatan Coach" : "Simpan Catatan"}
          bgColor={!state?.fill && !isEditable ? Colors.lightBlue : Colors.green}
          paddingVertical={12}
          fontSize={20}
          disabled={!!Object.keys(errors).length}
          type="submit"
          width={245}
          height={53}
          onClick={(e) => {
            if (!state?.fill && !isEditable) {
              e.preventDefault();
              navigate("/coaching-journals/notes/coachee", {
                state: { ...detail.journal, name: detail.coach_fullname, isCoachee: true, learner: state?.learner }
              });
            }
          }}
          className="mt-8"
        />

        {isEditable && (
          <span className="text-ileads-mainblue block w-fit mx-auto mt-2">
            Penting! Catatan coaching-mu belum tersimpan sampai kamu klik “
            <em className="not-italic text-ileads-green">Simpan Catatan</em>”.
          </span>
        )}
      </form>
    </section>
  );
};

export default JournalCoacheeNotes;
