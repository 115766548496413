import { API, convertResponseApi } from "Api";
import React, { createContext, useContext, useState } from "react";

export const UserContext = createContext();

export const useUsers = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [feedDeleted, setFeedDeleted] = useState(false);

  const setDataUser = (userData) => {
    setUser(userData);
  };

  const refetchUserData = async () => {
    const response = await API.userProfile();
    setDataUser(convertResponseApi(response));
  };

  return (
    <UserContext.Provider value={{ user, setDataUser, feedDeleted, setFeedDeleted, refetchUserData }}>
      {children}
    </UserContext.Provider>
  );
};
