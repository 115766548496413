/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { answerQuiz, getQuizDetails } from "Api/quiz";
import { AbmWoman } from "Assets/Images";
import { Button } from "Components";
import React, { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useNavigate, useParams } from "react-router-dom";
import { Colors } from "Themes";

const JuaraQuizDetail = () => {
  const [quizDetail, setQuizDetail] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [showResult, setShowResult] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const alertComponent = useAlert();

  const navigate = useNavigate();
  const { id: quizId } = useParams();

  const getQuizData = async () => {
    const { data } = await getQuizDetails(quizId);
    setQuizDetail(data);
  };

  const handleSelectAnswer = useCallback(
    (optionId) => {
      const tempArray = [...selectedAnswers];
      const indexAnswer = tempArray.findIndex(
        (answer) => answer.id === currentQuestion.id
      );

      if (indexAnswer > -1) tempArray[indexAnswer].optionId = optionId;
      else tempArray.push({ id: currentQuestion.id, optionId });

      setSelectedAnswers(tempArray);
    },
    [selectedAnswers, currentQuestion]
  );

  const checkActive = useCallback(
    (optionId) => {
      return selectedAnswers.some((answer) => answer.optionId === optionId);
    },
    [selectedAnswers]
  );

  const handleButtonAction = useCallback(
    async (type) => {
      if (type === "inc" && currentIndex + 1 < quizDetail.questions.length)
        setCurrentIndex(currentIndex + 1);
      else if (type === "dec" && currentIndex > 0)
        setCurrentIndex(currentIndex - 1);
      else if (type === "dec" && currentIndex === 0) navigate(-1);
      else if (currentIndex + 1 === quizDetail.questions.length) {
        try {
          const {
            data: { point }
          } = await answerQuiz({
            optionIds: selectedAnswers.map((answer) => answer.optionId),
            quizId: quizId
          });
          alertComponent.success("Quiz berhasil di kirim!");
          setShowResult(point);
        } catch (error) {
          alertComponent.show(error.response.data.message);
        }
      }
    },
    [currentIndex, quizDetail, selectedAnswers, quizId]
  );

  const renderResult = () => {
    return (
      <>
        <h3
          className="
          mb-6
          text-xl
          bg-ileads-yellow
          py-1
          w-[218px]
          text-center
          rounded-full
        "
        >
          Selamat
        </h3>
        <span className="text-sm">Inilah perolehan nilai quiz JUARA ini!</span>
        <h1 className="text-[90px] text-ileads-green mb-2">{showResult}</h1>
        <span className="text-sm mb-10">
          dari {quizDetail?.questions.length} pertanyaan.
        </span>

        <Button
          className="px-4 mr-5 relative"
          text="Oke!"
          bgColor={Colors.lightBlue}
          fontSize={14}
          paddingVertical={8}
          width={82}
          onClick={() => navigate("/juara-quiz")}
          loaderMainColor={Colors.white}
        />
      </>
    );
  };

  useEffect(() => {
    getQuizData();
  }, []);

  useEffect(() => {
    if (quizDetail) setCurrentQuestion(quizDetail.questions[currentIndex]);
  }, [currentIndex, quizDetail]);

  return (
    <section>
      <div className={`flex items-center ${showResult === null ? "mb-4" : ""}`}>
        <h1
          className="
            border-b-8
            text-xl
            border-ileads-yellow
            inline-block
            pl-1
            pr-2
          "
        >
          {quizDetail?.title}
        </h1>
      </div>

      {showResult ? <img src={AbmWoman} alt="" className="mx-auto" /> : null}

      <section
        className={`
          rounded-2xl
          relative
          flex
          flex-col
          justify-center
          items-center
          bg-white
          shadow-card
          px-[160px]
          ${showResult ? "py-[60px]" : "py-20"}
        `}
      >
        {showResult ? (
          renderResult()
        ) : (
          <>
            <label className="mb-10">{currentQuestion?.name}</label>
            <div className="flex flex-col">
              {currentQuestion?.options?.map((option) => (
                <div
                  key={option.id}
                  role="button"
                  onClick={() => handleSelectAnswer(option.id)}
                  className={`
                    p-2
                    transition-all
                    border-[3px]
                    mb-2
                    last:mb-0
                    group
                    relative
                    pl-10
                    hover:text-white
                    hover:bg-ileads-lightblue
                    flex
                    items-center
                    rounded-3xl
                    text-sm
                    border-ileads-lightblue
                    ${
                      checkActive(option.id)
                        ? "bg-ileads-lightblue text-white"
                        : ""
                    }
                  `}
                >
                  <span className="flex h-5 w-5 absolute left-2 top-2 mr-2">
                    <span
                      className={`
                        absolute
                        inline-flex
                        h-full
                        w-full
                        rounded-full
                        ${
                          checkActive(option.id)
                            ? "bg-ileads-yellow opacity-75 animate-ping"
                            : ""
                        }
                      `}
                    />
                    <span
                      className={`
                        relative
                        inline-flex
                        rounded-full
                        h-5
                        w-5
                        border-2
                        border-ileads-lightblue
                        group-hover:bg-ileads-yellow
                        group-hover:border-ileads-yellow
                        ${checkActive(option.id) ? "bg-ileads-yellow" : ""}
                      `}
                    />
                  </span>

                  {option.name}
                </div>
              ))}
            </div>

            <div className="mt-24 flex items-center w-full">
              <Button
                className="px-4 mr-5 relative"
                text="Kembali"
                bgColor={Colors.yellow}
                fontSize={14}
                paddingVertical={4}
                onClick={() => handleButtonAction("dec")}
                loaderMainColor={Colors.white}
              />

              <div className="h-2 w-full mx-5 bg-[#E0E0E0] relative">
                <span
                  className="h-full transition-all bg-ileads-yellow absolute"
                  style={{
                    width: `calc(100% / ${
                      quizDetail?.questions?.length - currentIndex || 1
                    })`
                  }}
                />
              </div>

              <Button
                className="px-4"
                text="Berikutnya"
                bgColor={Colors.green}
                fontSize={14}
                paddingVertical={4}
                onClick={() => handleButtonAction("inc")}
                loaderMainColor={Colors.white}
              />
            </div>
          </>
        )}
      </section>
    </section>
  );
};

export default JuaraQuizDetail;
