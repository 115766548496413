import * as yup from "yup";

export const CreateProfileSchema = yup.object({
  fullname: yup.string().required("Nama lengkap tidak boleh kosong"),
  nickname: yup.string().required("Nama panggilan tidak boleh kosong"),
  position: yup.object().required("Posisi di winning culture harus dipilih"),
  team1Id: yup.object().required("Team harus dipilih"),
  team2Id: yup.object(),
  team3Id: yup.object()
}).required();
