import clientAPI from "./clientApi";

export const getRequests = async (params) => {
  const { data } = await clientAPI().get("/v1/request-feedback", { params });

  return data;
};

export const getFeedbacks = async (params) => {
  const { data } = await clientAPI().get("/v1/feedback-user", { params });

  return data;
};

export const getFeedbackDetail = async (id) => {
  const { data } = await clientAPI().get(`/v1/feedback-user-detail/${id}`);

  return data;
};

export const getExistingLists = async (params) => {
  const { data } = await clientAPI().get("/v1/existing-coachees", { params });

  return data;
};

export const getCommitmentDetail = async (id) => {
  const { data } = await clientAPI().get(`/v1/feedback-commitment/${id}`);

  return data;
};

export const requestFeedback = async (payload) => {
  const { data } = await clientAPI().post("/v1/request-feedback", payload);

  return data;
};

export const createFeedback = async (payload) => {
  const { data } = await clientAPI().post("/v1/feedback-user", payload);

  return data;
};

export const createCommitment = async (payload) => {
  const { data } = await clientAPI().post("/v1/feedback-commitment", payload);

  return data;
};
