import { createFeedback, getFeedbackDetail } from "Api/feedback";
import { HappyIcon } from "Assets/Images";
import { Button, Modal, Underlined } from "Components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Colors } from "Themes";

const FeedbackCreate = ({ isDetail }) => {
  const { id: rfu_id } = useParams();
  const [answer, setAnswer] = useState({
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    q5: 0,
    q6: 0
  });
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [hasCommitment, setHasCommitment] = useState(false);
  const navigate = useNavigate();
  const { id, coach_id } = useParams();

  const checkErrors = useMemo(() => {
    const tempErrors = [];
    for (const [key, value] of Object.entries(answer)) {
      if (!value) tempErrors.push(key);
    }
    return tempErrors;
  }, [answer]);

  const points = [1, 2, 3, 4, 5];
  const questions = [
    "Dalam skala 1 - 5, seberapa baik coach-mu sudah membangun rapport atau kedekatan di awal sesi?",
    "Dalam skala 1 - 5, seberapa baik coach-mu sudah membantu saya sebagai coachee menentukan outcome?",
    "Dalam skala 1 - 5, seberapa baik coach-mu sudah mempraktekan active listening atau mendengar aktif saat sesi berlangsung?",
    "Dalam skala 1 - 5, seberapa baik coach-mu sudah mengajukan powerful questions atau pertanyaan yang menggugah pada saat sesi berlangsung?",
    "Dalam skala 1 - 5, seberapa baik coach-mu sudah menggali insights atau pembelajaran yang saya dapatkan selama sesi berlangsung?",
    "Dalam skala 1 - 5, seberapa baik coach-mu sudah membantu saya sebagai coachee untuk menyampaikan komitmen di akhir sesi?"
  ];

  const handleSubmit = useCallback(() => {
    setErrors(checkErrors);
    if (errors.length) return;
    setIsLoading(true);
    createFeedback({
      ...answer,
      coach_id,
      rfu_id
    })
      .then(() => {
        setShowModal(true);
      })
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, [answer, checkErrors, errors]);

  const handleSelectPoint = useCallback(
    (point, idx) => {
      if (answer[`q${idx + 1}`] === point) {
        setAnswer((prevValue) => ({
          ...prevValue,
          [`q${idx + 1}`]: 0
        }));
        setErrors((prevValue) => [...prevValue, `q${idx + 1}`]);
      } else {
        setErrors((prevValue) => prevValue.filter((err) => err !== `q${idx + 1}`));
        setAnswer((prevValue) => ({
          ...prevValue,
          [`q${idx + 1}`]: point
        }));
      }
    },
    [answer]
  );

  useEffect(() => {
    if (!isDetail) return;

    getFeedbackDetail(id).then(({ data }) => {
      if (!data) return;
      setHasCommitment(Boolean(data.has_commitment));

      const { q1, q2, q3, q4, q5, q6 } = data;
      setAnswer({ q1, q2, q3, q4, q5, q6 });
    });
  }, [id, isDetail]);

  return (
    <section>
      <Modal
        show={showModal}
        onClose={() => navigate("/feedback")}
        title="Sukses!"
        desc="Feedback telah sukses direquest!"
        icon={<img className="w-[160px]" src={HappyIcon} alt="" />}
        buttons={
          <Button
            type="button"
            onClick={() => navigate("/feedback")}
            text="Kembali ke Menu Utama Feedback"
            bgColor={Colors.green}
            width={320}
            paddingVertical={12}
            fontSize={20}
          />
        }
      />
      <div className="flex items-center mb-4">
        <Underlined>Feedback Results.</Underlined>
        <Link to="/feedback" className="ml-5 text-sm py-2 px-4 bg-ileads-lightblue text-white rounded-full">
          Kembali
        </Link>
      </div>

      <div className="p-5 bg-white rounded-2xl shadow-card">
        <span className="text-xl text-center block mb-4">
          {isDetail
            ? "Berikut adalah hasil feedback sudah dinilai oleh anggota tim-mu."
            : "Berilah penilaian untuk coach-mu dengan memberi rating pada pernyataan berikut ini sesuai dengan sesi coaching yang sudah kamu lakukan."}
        </span>

        {!isDetail ? (
          <div className="text-xl text-center mb-4">
            Penting! Catatan coaching-mu belum tersimpan sampai kamu klik{" "}
            <span className="text-ileads-green">Submit</span> di bawah halaman ini.
          </div>
        ) : null}

        <div className="h-4 bg-ileads-yellow my-4"></div>

        {errors.length ? (
          <span className="text-ileads-danger-3 my-4 text-xl block text-center">
            Ups! Sepertinya ada feedback yang belum diisi! Silahkan dicek kembali dan isi semua feedback yang tersedia!
          </span>
        ) : null}

        {questions.map((question, idx) => (
          <div className="mb-4 last:mb-0" key={idx}>
            <p
              className={`text-center mb-4 transition-colors ${
                errors.includes(`q${idx + 1}`) ? "text-ileads-danger-3" : "text-ileads-darkblue"
              }`}
            >
              {question}
            </p>

            <div className="flex gap-5 justify-center">
              {points.map((point) => (
                <button
                  key={point}
                  disabled={isDetail}
                  className="text-center"
                  onClick={() => handleSelectPoint(point, idx)}
                >
                  <div
                    className={`w-10 transition-colors h-10 border-2 border-ileads-lightblue ${
                      point === answer[`q${idx + 1}`] ? "bg-ileads-lightblue" : "bg-ileads-lightblue/50"
                    } rounded-full`}
                  ></div>
                  <span>{point}</span>
                </button>
              ))}
            </div>
          </div>
        ))}

        {isDetail ? (
          <Link
            to={`/feedback/improvement/${hasCommitment ? "" : "create/"}${id}`}
            className="mx-auto block rounded-full text-white w-48 text-center p-3 text-xl bg-ileads-green"
          >
            Komitmen Saya
          </Link>
        ) : (
          <Button
            onClick={handleSubmit}
            disabled={isLoading}
            isLoading={isLoading}
            className="mt-4"
            text="Submit Feedback"
            bgColor={Colors.green}
            width={197}
            paddingVertical={12}
            fontSize={20}
          />
        )}
      </div>
    </section>
  );
};

export default FeedbackCreate;
