import { debounce } from "lodash";
import React from "react";

export const useSearch = apiRequest => {
  const [loadingSearch, setLoadingSearch] = React.useState(false);
  const [results, setResults] = React.useState([]);

  const onChange = async e => {
    if (apiRequest) {
      setLoadingSearch(true);
      try {
        const { data: { data: responseData } } =
          await apiRequest(e.target.value);
        setResults(responseData);
      } finally {
        setLoadingSearch(false);
      }
    }
  };

  React.useEffect(() => {
    onChange({ target : { value: "" } });
    // eslint-disable-next-line
  }, []);

  const debouncedResults = React.useMemo(() => {
    return debounce(onChange, 800);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  return ({
    debouncedResults,
    loadingSearch,
    results
  });
};
