import { CloseRound } from "Assets/Images";
import { useOnClickOutside } from "Hooks";
import React, { useRef } from "react";

const Modal = ({ show, children, onClose, title, desc, icon, buttons }) => {
  const modalBody = useRef(null);

  useOnClickOutside(modalBody, () => {
    if (show) onClose();
  });

  return (
    <div
      className={`ilead-modal fixed top-0 left-0 z-50 transition-all ${
        show ? "visible opacity-100" : "invisible opacity-0"
      }`}
    >
      <div className=" bg-ileads-darkblue/50 w-screen h-screen flex justify-center items-center">
        <div
          ref={modalBody}
          className={`relative${!children ? " bg-white py-[71px] px-12 rounded-[36px] w-[600px]" : ""} `}
        >
          <button type="button" onClick={onClose} className="absolute top-16 right-12">
            <img src={CloseRound} className="w-10 h-10" alt="" />
          </button>
          {children ? (
            children
          ) : (
            <div className="flex flex-col items-center">
              {icon}
              <h5 className="text-ileads-green text-2xl text-center mt-8 m-2">{title}</h5>
              <span className="text-center inline-block mb-8" dangerouslySetInnerHTML={{ __html: desc }} />
              <div className="grid grid-flow-col gap-10">{buttons}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
