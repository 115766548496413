import { useEffect, useState } from "react";

export const usePaginate = (current, max) => {
  const [prev, setPrev] = useState(null);
  const [next, setNext] = useState(null);
  const [numbers, setNumbers] = useState([]);
  const [result, setResult] = useState({});

  useEffect(() => {
    if (!current || !max) return;

    let tempNumber = [1];

    setPrev(current === 1 ? null : current - 1);
    setNext(current === max ? null : current + 1);

    if (current > 4) tempNumber.push("...");

    let r = 2,
      r1 = current - r,
      r2 = current + r;

    for (let i = r1 > 2 ? r1 : 2; i <= Math.min(max, r2); i++) {
      tempNumber.push(i);
    }

    if (r2 + 1 < max) tempNumber.push("...");
    if (r2 < max) tempNumber.push(max);

    setNumbers(tempNumber);
  }, [current, max]);

  useEffect(() => {
    if (current === 1 && max === 1) {
      setResult({ current, next, numbers, prev });
      return;
    }
    setResult({ current, next, numbers, prev });
  }, [current, max, next, numbers, prev]);

  return result;
};
