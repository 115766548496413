import "moment/locale/id";
import React from "react";

import styled from "styled-components";
import RootApp from "./Routes";
import { Colors } from "./Themes";
import "./App.scss";
import { UserProvider } from "./Context/UsersStateManagement";
import { QuestionnaireProvider } from "Context/questionnaireContext";

const Wrapper = styled.div`
  background-color: ${({ bgColor }) => bgColor};
`;

export default function App() {
  return (
    <UserProvider>
      <QuestionnaireProvider>
        <Wrapper bgColor={Colors.bgBlue}>
          <RootApp />
        </Wrapper>
      </QuestionnaireProvider>
    </UserProvider>
  );
}
