import { Button } from "Components";
import { useQuestionnaire } from "Context/questionnaireContext";
import React from "react";
import { Colors } from "Themes";
import { convertDate } from "Utils/convertDate";

const QuestionnaireProgress = ({ items, onClick, isLoading }) => {
  const { culture, setPayload, payload } = useQuestionnaire();

  const getCurrentProgress = (data) => {
    const takers = data.culture_measurement_takers;
    const currentData = takers.find((cTaker) => cTaker.cm_taker_status === "draft") || null;
    const currentDataSubmitted = takers.find((cTaker) => cTaker.cm_taker_status === "submitted") || null;

    if (currentDataSubmitted && !currentData)
      return {
        ...currentDataSubmitted,
        width: (currentDataSubmitted.cm_taker_last_filled * 100) / currentDataSubmitted.cm_taker_total_section
      };
    else if (!takers.length || !currentData) return null;
    return { ...currentData, width: (currentData.cm_taker_last_filled * 100) / currentData.cm_taker_total_section };
  };

  return (
    <div className="font-brandon mt-3 mb-1 text-sm">
      <strong>Periode pengisian:</strong>
      <span className="block w-fit rounded-full py-2 px-3 mt-1 bg-ileads-bgblue">
        {convertDate(culture.startDate, true)} - {convertDate(culture.endDate, true)}
      </span>

      <ol className="grid gap-y-4 pl-14 mt-5">
        {items?.map((item) => (
          <li className="grid grid-cols-2 items-center" key={item.cm_objective_id}>
            <div className="flex items-center w-fit">
              <div
                className={`w-5 h-5 border-2 border-ileads-mainblue rounded-full mr-3 ${
                  item.culture_measurement_takers.length ? item.color : "bg-transparent"
                }`}
              ></div>
              <span className="font-brandon_bold text-sm">
                {item.cm_objective_title}
                {item.cm_objective_max_answerred > 1 && (
                  <strong>
                    <br />
                    {item.culture_measurement_takers.length}/{item.cm_objective_max_answerred} Orang
                  </strong>
                )}
              </span>
              <Button
                text="Isi Kuesioner"
                bgColor={!item.is_enable ? Colors.lightGrey : Colors.green}
                fontSize={12}
                paddingVertical={4}
                isLoading={isLoading && item.is_enable}
                width={83}
                onClick={() => {
                  onClick({ detail: item, hasTakers: !!item.culture_measurement_takers?.length });
                }}
                disabled={!item.is_enable}
                className={`!ml-2 ${!item.is_enable ? "!text-gray-500" : "text-white"}`}
              />
            </div>

            <div>
              <div className="flex justify-between items-center">
                <span className="text-[10px] min-h-[14px] inline-block">
                  {!getCurrentProgress(item)
                    ? ""
                    : `Terakhir diisi pada tanggal ${convertDate(getCurrentProgress(item).cm_taker_updated_at)}`}
                </span>
                <strong className="text-sm">
                  {!getCurrentProgress(item)
                    ? "-"
                    : `${getCurrentProgress(item).cm_taker_last_filled}/
                  ${getCurrentProgress(item).cm_taker_total_section}`}
                </strong>
              </div>
              <div className="relative bg-gray-200 h-2 w-full">
                {getCurrentProgress(item) && (
                  <div
                    className="absolute bg-ileads-yellow h-full"
                    style={{ width: `${getCurrentProgress(item).width}%` }}
                  />
                )}
              </div>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default QuestionnaireProgress;
