import clientAPI from "./clientApi";

export const getQuizLists = async (params) => {
  const { data } = await clientAPI().get("/v1/quiz", { params });

  return data;
};

export const getQuizDetails = async (id) => {
  const { data } = await clientAPI().get(`/v1/quiz/${id}`);

  return data;
};

export const answerQuiz = async (payload) => {
  const { data } = await clientAPI().post("/v1/quiz/answer", payload);

  return data;
};
