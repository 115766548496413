import Button from "Components/Button";
import Pagination from "Components/Pagination";
import Underlined from "Components/Underlined";
import React, { useEffect, useState } from "react";
import { Colors } from "Themes";

const DateList = ({ title, items, value, currentPage, maxPage, onSelected, onNext, onPrev, onJump }) => {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <div className="bg-white min-w-[700px] rounded-[48px]">
      <div className="py-12">
        <Underlined className="mx-auto">{title}</Underlined>
      </div>

      <ol className="py-6 shadow-inner">
        {items.map((item, idx) => (
          <li key={idx} className="text-xl border-b-ileads-darkblue border-b-[5px] last:border-none">
            <button
              onClick={() => setSelected(selected !== item ? item : null)}
              className={`py-5 font-brandon_bold ${
                selected === item ? "bg-ileads-darkblue text-white" : "hover:bg-ileads-darkblue hover:text-white"
              } transition-colors w-full`}
            >
              {item.text}
            </button>
          </li>
        ))}
      </ol>

      <div className="shadow-xl py-[22px]">
        <Pagination
          className="mx-auto"
          current={currentPage}
          max={maxPage}
          onNext={onNext}
          onPrev={onPrev}
          onJump={onJump}
        />
        <Button
          className="mt-7"
          onClick={() => onSelected(selected.value)}
          text="Pilih Tanggal"
          bgColor={Colors.green}
          disabled={!selected}
          width={158}
          paddingVertical={12}
          fontSize={20}
        />
      </div>
    </div>
  );
};

export default DateList;
