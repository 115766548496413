import { getExistingLists, getFeedbacks, getRequests, requestFeedback } from "Api/feedback";
import { ReactComponent as BubbleHeartQuestion } from "Assets/Icons/bubble-heart-question.svg";
import { ReactComponent as BubbleHeart } from "Assets/Icons/bubble-heart.svg";
import { ReactComponent as BubbleQuestion } from "Assets/Icons/bubble-question.svg";
import { ReactComponent as Loading } from "Assets/Icons/loading.svg";
import { ReactComponent as Surprised } from "Assets/Icons/surprised.svg";
import { DateList, Modal, Pagination, Underlined } from "Components";

import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { convertDate } from "Utils/convertDate";

const renderList = (items, cb) => {
  return (
    <ul>
      {items.map((item, idx) => (
        <li
          className="pt-9 pb-5 pl-4 pr-16 flex items-center justify-between border-b-[5px] text-2xl border-b-ileads-darkblue"
          key={idx}
        >
          {cb(item, idx)}
        </li>
      ))}
    </ul>
  );
};

const emptyState = (desc) => {
  return (
    <div className="h-[400px] flex items-center">
      <div className="flex w-full flex-col justify-center items-center">
        <Surprised />
        <p className="text-xl max-w-[330px] text-center mt-5" dangerouslySetInnerHTML={{ __html: desc }} />
      </div>
    </div>
  );
};

const renderData = (items, cb, isLoading, err) => {
  if (isLoading)
    return (
      <div className="h-[400px] flex items-center">
        <Loading className="mx-auto my-20 animate-spin" />
      </div>
    );
  return !items?.length ? emptyState(err) : renderList(items, cb);
};

const Feedback = () => {
  const navigate = useNavigate();
  const [showDate, setShowDate] = useState(null);
  const [existingList, setExistingList] = useState([]);
  const [reqButtonDisabled, setReqButtonDisabled] = useState([]);
  const [requestList, setRequestList] = useState([]);
  const [existingPagination, setExistingPagination] = useState({
    isLoading: false,
    page: 1,
    total_pages: 0
  });
  const [requestPagination, setRequestPagination] = useState({
    isLoading: false,
    page: 1,
    total_pages: 0
  });
  const [dateFeedbackData, setDateFeedbackData] = useState({
    isLoading: false,
    page: 1,
    items: [],
    total_pages: 0
  });

  const onModalDateClose = () => {
    setShowDate(null);
    setDateFeedbackData((prevValue) => ({
      items: [],
      isLoading: false,
      page: 1,
      total_pages: 0
    }));
  };

  const handleOnRequest = (coachee_id, idx) => {
    setReqButtonDisabled((prevValue) => [...prevValue, idx]);
    requestFeedback({ coachee_id });
  };

  const handleShowDate = useCallback(
    (coachee_id, page = 1) => {
      getFeedbacks({ coachee_id, limit: 3, page })
        .then((data) => {
          setDateFeedbackData((prevValue) => ({
            items: data.data.map((item) => ({
              text: convertDate(item.fu_created_at),
              value: item.fu_id
            })),
            page,
            total_pages: data.meta.total_pages
          }));
          setShowDate(coachee_id);
        })
        .catch(console.error);
    },
    [dateFeedbackData, showDate]
  );

  const fetchExistingData = useCallback((page) => {
    setReqButtonDisabled([]);
    setExistingPagination((prevValue) => ({ ...prevValue, isLoading: true }));
    getExistingLists({ limit: 4, page })
      .then(({ data, meta }) => {
        setExistingList(data);
        setExistingPagination({
          ...meta,
          page: page
        });
      })
      .catch(console.error)
      .finally(() =>
        setExistingPagination((prevValue) => ({
          ...prevValue,
          isLoading: false
        }))
      );
  }, []);

  const fetchRequestData = useCallback((page) => {
    setRequestPagination((prevValue) => ({ ...prevValue, isLoading: true }));
    getRequests({ limit: 4, page })
      .then(({ data, meta }) => {
        setRequestList(data);
        setRequestPagination({
          ...meta,
          page: page
        });
      })
      .catch(console.error)
      .finally(() =>
        setRequestPagination((prevValue) => ({
          ...prevValue,
          isLoading: false
        }))
      );
  }, []);

  useEffect(() => {
    fetchExistingData(1);
    fetchRequestData(1);
  }, [fetchExistingData, fetchRequestData]);

  return (
    <section>
      <Modal show={showDate} onClose={onModalDateClose}>
        <>
          <DateList
            currentPage={dateFeedbackData.page}
            maxPage={dateFeedbackData.total_pages}
            title="Pilih tanggal feedback."
            items={dateFeedbackData.items}
            onSelected={(value) => navigate(`/feedback/${value}`)}
            onNext={() => {
              handleShowDate(showDate, dateFeedbackData.page + 1);
            }}
            onPrev={() => {
              handleShowDate(showDate, dateFeedbackData.page - 1);
            }}
            onJump={(value) => {
              handleShowDate(showDate, value);
            }}
          />
        </>
      </Modal>

      <div className="flex items-center mb-4">
        <Underlined>Feedback.</Underlined>
      </div>

      <div className="p-5 bg-white rounded-2xl shadow-card min-h-[565px] transition-all">
        <Underlined className="mx-auto mb-[50px]">Existing Coachees.</Underlined>

        {renderData(
          existingList,
          (item, idx) => (
            <>
              <span
                title={item.user_fullname}
                className="2xl:max-w-[250px] text-ellipsis overflow-hidden whitespace-nowrap text-xl font-brandon_bold"
              >
                {item.user_fullname}
              </span>
              <div>
                <button
                  disabled={item.is_button_disabled || reqButtonDisabled.includes(idx)}
                  onClick={() => handleOnRequest(item.coachee_id, idx)}
                  className="inline-block mr-14"
                >
                  <Underlined
                    isHover
                    disabled={item.is_button_disabled || reqButtonDisabled.includes(idx)}
                    className="text-ileads-lightblue flex items-center text-xl"
                  >
                    <BubbleQuestion
                      className={`mr-3 ${
                        item.is_button_disabled || reqButtonDisabled.includes(idx) ? "grayscale opacity-20" : ""
                      }`}
                    />{" "}
                    Request feedback.
                  </Underlined>
                </button>
                <button
                  onClick={() => handleShowDate(item.coachee_id)}
                  disabled={!item.has_previous_feedback}
                  className="inline-block"
                >
                  <Underlined
                    disabled={!item.has_previous_feedback}
                    isHover
                    className="text-ileads-lightblue flex items-center text-xl"
                  >
                    <BubbleHeart className={`mr-3 ${!item.has_previous_feedback ? "grayscale opacity-20" : ""}`} />{" "}
                    Feedback sebelumnya.
                  </Underlined>
                </button>
              </div>
            </>
          ),
          existingPagination.isLoading,
          "Belum ada Coachees sebelumnya!<br/>Kembali lagi saat sudah ada Existing Coachees ya!"
        )}
      </div>
      <Pagination
        current={existingPagination.page}
        max={existingPagination.total_pages}
        className="mx-auto my-3"
        onNext={() => fetchExistingData(existingPagination.page + 1)}
        onPrev={() => fetchExistingData(existingPagination.page - 1)}
        onJump={(value) => fetchExistingData(value)}
      />

      <div className="p-5 bg-white rounded-2xl shadow-card min-h-[565px] transition-all">
        <Underlined className="mx-auto mb-[50px]">Feedback Request.</Underlined>

        {renderData(
          requestList,
          ({ rfu_user_from_id, user_fullname, coach_id }) => (
            <>
              <span className="text-xl font-brandon_bold">{user_fullname}</span>
              <Link to={`/feedback/create/${rfu_user_from_id}/${coach_id}`} className="inline-block">
                <Underlined isHover className="text-ileads-lightblue flex items-center text-xl font-brandon_bold">
                  <BubbleHeartQuestion className="mr-3" /> Isi feedback.
                </Underlined>
              </Link>
            </>
          ),
          requestPagination.isLoading,
          "Belum ada request feedback!<br/>Kembali lagi saat sudah ada Feedback Request ya!"
        )}
      </div>
      <Pagination
        current={requestPagination.page}
        max={requestPagination.total_pages}
        className="mx-auto my-3"
        onNext={() => fetchRequestData(requestPagination.page + 1)}
        onPrev={() => fetchRequestData(requestPagination.page - 1)}
        onJump={(value) => fetchRequestData(value)}
      />
    </section>
  );
};

export default Feedback;
