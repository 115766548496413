import styled from "styled-components";

import { Colors } from "../../Themes";

const Wrapper = styled.div`
  background-color: ${({ type }) => type === "success" ? Colors.green : Colors.red };
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.03);
  font-family: Brandon-Medium;
  box-sizing: border-box;
  margin-top: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 10px;
`;

const Message = styled.span`
  padding-right: 20px;
  color: ${Colors.white};
  max-width: 300px;
`;

const ErrorWrapper = styled.div`
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
`;

export {
  ErrorWrapper,
  Wrapper,
  Message
};

