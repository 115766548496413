import { getAllUsers, updateUser } from "Api/user";
import { HappyIcon } from "Assets/Images";
import { Button } from "Components";
import { Constants } from "Constants";
import { useUsers } from "Context/UsersStateManagement";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Colors } from "Themes";

const MoodTracker = () => {
  const { user, setDataUser } = useUsers();
  const [selectedMood, setSelectedMood] = useState(user.user_mood);
  const [teams, setTeams] = useState([]);

  const alertComponent = useAlert();

  const handleSaveMood = async () => {
    try {
      await updateUser(user.user_id, { mood: selectedMood });
      setDataUser({ ...user, user_mood: selectedMood });
      alertComponent.success("Mood berhasil di ubah!");
    } catch (error) {
      alertComponent.show(error.response.data.message);
    }
  };

  const getUser = async () => {
    const { data } = await getAllUsers();

    setTeams(data);
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <section className="flex">
      <section className="flex-1">
        <h1
          className="
            mb-3
            border-b-8
            text-xl
            border-ileads-yellow
            inline-block
            pl-1
            pr-2
          "
        >
          Mood Tracker
        </h1>

        <div
          className="
            p-10
            bg-white
            rounded-2xl
            shadow-card
            relative
          "
        >
          <span className="block mb-8">Pilih mood Anda hari ini:</span>

          <div className="grid grid-cols-5 gap-8">
            {Constants.moodTracker.map((mood, idx) => (
              <button key={idx} onClick={() => setSelectedMood(mood.title)}>
                <img
                  className={`h-[55px] mx-auto transition-all ${
                    selectedMood === mood.title ? "grayscale-0" : "grayscale"
                  } hover:grayscale-0 hover:scale-110`}
                  src={mood.icon}
                  alt={mood.title}
                />
                <span className="text-center block mt-2">{mood.title}</span>
              </button>
            ))}
            <Button
              className="h-fit !mx-0 mt-5"
              paddingVertical={4}
              text="Pilih"
              onClick={handleSaveMood}
              bgColor={Colors.green}
              fontSize={14}
            />
          </div>
        </div>
      </section>

      <section className="w-[419px] ml-2">
        <h1
          className="
            mb-3
            border-b-8
            text-xl
            border-ileads-yellow
            inline-block
            pl-1
            pr-2
          "
        >
          How are my teams doing today?
        </h1>

        <div
          className="
            p-10
            bg-white
            rounded-2xl
            shadow-card
            relative
          "
        >
          <ul>
            {teams.map((team) => (
              <li
                key={team.id}
                className="flex items-center justify-between mb-10"
              >
                <div>
                  <span className="font-brandon_bold">{team.fullname}</span>
                  <span className="ml-3 text-ileads-darkblue/80">
                    merasa {team.mood || "senang"}
                  </span>
                </div>
                {team.mood && team.mood.toLowerCase() !== "marah" ? (
                  Constants.moodTracker.map((mood, idx) => {
                    if (mood.title.toLowerCase() === team.mood.toLowerCase())
                      return (
                        <img key={idx} src={mood.icon} className="w-8" alt="" />
                      );
                  })
                ) : (
                  <img src={HappyIcon} className="w-8 grayscale" alt="" />
                )}
              </li>
            ))}
          </ul>
        </div>
      </section>
    </section>
  );
};

export default MoodTracker;
