import {
  createCultureAnswer,
  getAnswerById,
  getCultureSections,
  getCultureUsers,
  getCultures,
  updateCultureAnswer
} from "Api/questionnaire";
import { updateUser } from "Api/user";
import { HappyIcon } from "Assets/Images";
import { Button, Underlined } from "Components";
import { useQuestionnaire } from "Context/questionnaireContext";
import { useUsers } from "Context/UsersStateManagement";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Colors } from "Themes";
import { StorageAPI } from "Utils";
import QuestionnaireDescription from "./components/Description";
import QuestionnaireForm from "./components/Forms";
import QuestionnaireProgress from "./components/Progress";

const Questionnaire = () => {
  const { user, refetchUserData } = useUsers();
  const { culture, payload, setPayload, currentUserData, setCultureUsers, setCultureData, clearPayload } =
    useQuestionnaire();
  const [step, setStep] = useState(0);
  const [currentHasTakers, setCurrentHasTakers] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [currentTitle, setCurrentTitle] = useState(null);
  const [currentHasSubmittedTakers, setCurrentHasSubmittedTakers] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const fetchSectionData = async ({ detail }) => {
    setCurrentTitle(detail.cm_objective_title);
    try {
      setIsLoading(true);

      const currentTaker = detail.culture_measurement_takers;
      const taker = currentTaker.find((cTaker) => cTaker.cm_taker_status === "draft") || null;
      const submittedTaker = currentTaker.find((cTaker) => cTaker.cm_taker_status === "submitted") || null;
      const takerStep = taker?.cm_taker_last_filled - 1;

      const fetchUsers = async () => {
        const cultureUserData = await getCultureUsers();
        setCultureUsers({
          ...cultureUserData,
          data: cultureUserData.data.map((cUser) => ({ text: cUser.fullname, value: cUser.id }))
        });
      };

      if (submittedTaker) {
        fetchUsers();
        setCurrentHasSubmittedTakers(submittedTaker);
        setStep(1);
      }

      if (!taker) {
        const { data } = await getCultureSections(detail.cm_objective_id);
        setPayload((prevValue) => ({
          ...prevValue,
          cmo_id: detail.cm_objective_id,
          temp_data: data.map((sData) => ({ ...sData, last_filled: false }))
        }));

        return;
      }

      setCurrentHasTakers(taker);

      if (takerStep !== 0) fetchUsers();

      setStep(takerStep);

      const {
        data: { rated_user_id, status, temp_data, structural_position, sn }
      } = await getAnswerById(taker.cm_taker_id);

      if (detail.cm_objective_max_answerred > 1) {
        submittedTaker
          ? setPayload({ cmo_id: detail.cm_objective_id, rated_user_id, status, temp_data })
          : setPayload({ cmo_id: detail.cm_objective_id, rated_user_id, sn, status, structural_position, temp_data });
      } else {
        setPayload({ status, temp_data });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      behavior: "smooth",
      top: 0
    });
  };

  const validateData = useCallback(() => {
    const questionnaireStatus = payload.temp_data.every((tData) =>
      tData.questionnaire.every((questionnaire) => questionnaire.point)
    );
    const payloadCheck = Object.values(payload).every((pData) => !!pData);
    const isValidData = questionnaireStatus && payloadCheck;

    return isValidData;
  }, [payload]);

  const handleCreateData = useCallback(async () => {
    const isValidData = validateData();

    if (isValidData) {
      payload.temp_data = payload.temp_data.map((tData) => ({ ...tData, last_filled: false }));
      payload.temp_data[payload.temp_data.length - 1].last_filled = true;
    } else {
      payload.temp_data = payload.temp_data.map((tData, idx) => {
        if (idx === step) return { ...tData, last_filled: true };
        return { ...tData, last_filled: false };
      });
    }

    if (isValidData) payload.status = "submitted";
    else payload.status = "draft";

    if (!currentHasTakers) {
      await createCultureAnswer(payload);
    } else {
      await updateCultureAnswer(currentHasTakers.cm_taker_id, payload);
    }

    setStep(0);
    setCurrentHasTakers(null);
    clearPayload();
    setCurrentTitle(null);

    const cultureData = await getCultures();

    setCultureData(cultureData.data);
  }, [clearPayload, currentHasTakers, payload, setCultureData, step, validateData]);

  const updateCurentUserData = useCallback(
    async (updateOnly) => {
      const isSameDataUser =
        currentUserData.position === user.user_position &&
        currentUserData.fullname === user.user_fullname &&
        currentUserData.team1Id === user.team1_id;

      if (!isSameDataUser) {
        const updateData = await updateUser(user.user_id, currentUserData);
        StorageAPI.setItem("token", updateData.data.token);
        await refetchUserData();
      }

      if (updateOnly) {
        await handleCreateData();
        clearPayload();
        return;
      }

      const cultureUserData = await getCultureUsers();

      setCultureUsers({
        ...cultureUserData,
        data: cultureUserData.data.map((cUser) => ({ text: cUser.fullname, value: cUser.id }))
      });
    },
    [clearPayload, currentUserData, handleCreateData, refetchUserData, setCultureUsers, user]
  );

  const handleNext = async () => {
    if (step === 0 && payload.temp_data[step].type === "appraiser") {
      await updateCurentUserData();
    }

    if (step < payload.temp_data.length - 1) {
      setStep(step + 1);
    } else {
      handleCreateData();
      setShowModal("submit");
    }
    scrollToTop();
  };

  const handlePrev = () => {
    if (step) setStep(step - 1);
    scrollToTop();
  };

  const handleSaveDraft = useCallback(async () => {
    const isValidData = validateData();
    if (step === 0) {
      setShowModal("draft");
      await updateCurentUserData(true);
      return;
    }
    if (isValidData && step === 0) {
      await updateCurentUserData(true);
      handleCreateData();
      return;
    }

    handleCreateData();
    setShowModal("draft");
  }, [handleCreateData, step, updateCurentUserData, validateData]);

  useEffect(() => {
    if (!Object.keys(culture).length) navigate("/homepage");
  }, [culture, navigate]);

  return (
    <main className="grid justify-center items-center py-20">
      <div
        className={`w-screen h-screen transition-all fixed top-0 left-0 bg-ileads-darkblue/50 z-[99] ${
          showModal ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
      >
        <div className="bg-white max-w-xs p-8 rounded-2xl w-fit mx-auto mt-64">
          <img className="mx-auto" src={HappyIcon} alt="" />
          <h2 className="text-2xl my-5 font-brandon_bold text-center">
            {showModal === "draft"
              ? "Data kuesioner anda berhasil di simpan!"
              : "Data kuesioner anda berhasil di submit!"}
          </h2>
          <Button
            onClick={() => setShowModal(null)}
            text="Tutup"
            bgColor={Colors.green}
            width={100}
            paddingVertical={10}
          />
        </div>
      </div>
      <div className="flex items-center">
        <Underlined className="font-brandon_bold !border-b-8">{currentTitle ? currentTitle : culture.title}</Underlined>
        <Button
          text="Batal"
          className="!ml-3 !mr-0"
          bgColor={Colors.red}
          paddingVertical={5}
          width={75}
          fontSize={14}
          onClick={() => {
            setCurrentTitle(null);
            if (payload.temp_data.length) {
              setStep(0);
              setPayload((prevValue) => ({
                ...prevValue,
                temp_data: []
              }));
            } else {
              navigate(-1);
            }
          }}
        />
        {!!payload.temp_data.length && (
          <Button
            className="!ml-3"
            text="Simpan Data"
            bgColor={Colors.yellow}
            paddingVertical={5}
            textColor={Colors.darkBlue}
            width={100}
            disabled={/(rated_person|appraiser)/.test(payload.temp_data[step].type) && !payload.rated_user_id}
            onClick={handleSaveDraft}
            fontSize={14}
          />
        )}
      </div>

      <section
        className="
          bg-white
          transition-all
          flex
          flex-col
          justify-between
          py-6
          px-9
          rounded-3xl
          min-h-[372px]
          w-[974px]
          mt-3
        "
      >
        {payload.temp_data.length ? (
          <QuestionnaireForm sectionList={payload.temp_data} currentStep={step} />
        ) : (
          <>
            <QuestionnaireDescription />
            <QuestionnaireProgress
              isLoading={isLoading}
              items={culture.culture_measurement_objectives}
              onClick={(value) => fetchSectionData(value)}
            />
          </>
        )}

        {!payload.temp_data.length ? null : (
          <div className="flex items-center justify-between mt-10">
            <Button
              className={`!mx-0 transition-all ${step === 0 ? "opacity-0 invisible" : "opacity-100 visible"}`}
              text="Sebelumnya"
              bgColor={Colors.lightBlue}
              paddingVertical={8}
              disabled={!!currentHasSubmittedTakers && step === 1 && payload.temp_data[step].type === "rated_person"}
              width={90}
              onClick={handlePrev}
              fontSize={14}
            />
            <div className="w-[434px]">
              <strong className="text-sm ml-auto block w-fit">
                {step + 1}/{payload.temp_data.length}
              </strong>
              <div className="relative bg-gray-200 h-2 w-full">
                <div
                  className="absolute bg-ileads-yellow h-full transition-all duration-500"
                  style={{ width: `${(step * 100) / (payload.temp_data.length - 1)}%` }}
                />
              </div>
            </div>
            <Button
              className="!mx-0 !transition-all"
              text={step + 1 === payload.temp_data.length ? "Submit Kuesioner" : "Selanjutnya"}
              bgColor={Colors.green}
              disabled={
                (step + 1 === payload.temp_data.length && !validateData()) ||
                (payload.temp_data[step].type === "rated_person" && !payload.rated_user_id)
              }
              paddingVertical={8}
              onClick={handleNext}
              width={step + 1 === payload.temp_data.length ? 130 : 87}
              fontSize={14}
            />
          </div>
        )}
      </section>
    </main>
  );
};

export default Questionnaire;
