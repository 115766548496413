import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API, convertResponseApi } from "../../Api";
import { DefaultAva, InvisiblePasswordIcons } from "../../Assets/Images";
import Image from "../../Components/Image";
import { useUsers } from "../../Context/UsersStateManagement";
import { StorageAPI } from "../../Utils";
import { CardWrapperPages } from "../Homepage/index.styles";

export default function Settings() {
  const { user, setDataUser } = useUsers();
  const navigate = useNavigate();
  const fileRef = useRef(null);
  const [fileUpload, setFileUpload] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isUpdateProfile, setIsUpdateProfile] = useState(false);
  const [profile, setProfile] = useState({
    email: "",
    fullname: "",
    nickname: "",
    photo: "",
    team1Id: "",
    team2Id: "",
    team3Id: ""
  });

  useEffect(() => {
    initDataProfile(user);
    return () => {};
  }, [user]);

  const initDataProfile = (userData) => {
    setProfile({
      email: userData?.user_email,
      fullname: userData?.user_fullname,
      nickname: userData?.user_nickname,
      photo: userData?.user_photo,
      team1Id: userData?.team1_id,
      team2Id: userData?.team2_id,
      team3Id: userData?.team3_id
    });
  };

  const handleChangeField = (e) => {
    setIsUpdateProfile(true);
    const { name, value } = e.target;
    setProfile((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const uploadFile = async (event) => {
    const fileUploaded = event.target.files[0];
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setFileUpload(reader.result);
    });
    reader.readAsDataURL(fileUploaded);

    let formData = new FormData();
    formData.append("files", fileUploaded);
    const responseUpload = await API.upload(formData);
    if (responseUpload.status === 200) {
      setFileName(convertResponseApi(responseUpload)["urls"]);
      setIsUpdateProfile(true);
    }
  };

  const handleLogout = () => {
    StorageAPI.removeItem("token");
    setDataUser({});
    navigate("/");
  };

  const updateProfile = async () => {
    if (fileName != "") {
      profile.photo = fileName;
    } else if (fileName == "") {
      profile.photo = profile.photo.replace(/^.*[\\/]/, "");
    }

    const bodyParam = {
      ...profile
    };
    const response = await API.updateProfile(user.user_id, bodyParam);
    if (response.status === 200) getProfile();
  };

  const getProfile = async () => {
    const response = await API.userProfile();
    setDataUser(convertResponseApi(response));
    setIsUpdateProfile(false);
  };

  const openFile = (e) => {
    fileRef.current.click();
  };

  return (
    <div className="flex flex-col">
      <div className="flex mb-3">
        <div className="flex flex-col space-y-1">
          <p className="text-xl">My Account</p>
          <div className="bg-ileads-yellow h-2"></div>
        </div>
      </div>
      <CardWrapperPages>
        <div className="flex py-10 justify-center space-x-12">
          {/* left */}
          <div className="flex flex-col space-y-3.5">
            <div className="space-y-1">
              <p className="text-lg">Foto profil</p>
              <div className="flex items-center space-x-4">
                {fileUpload == null && (
                  <Image
                    src={user.user_photo || DefaultAva}
                    width={120}
                    height={120}
                    alt={"iLead-ava"}
                  />
                )}

                {fileUpload != null && (
                  <Image
                    src={fileUpload}
                    width={120}
                    height={120}
                    alt={"iLead-ava"}
                  />
                )}
                <button
                  className="py-2.5 px-2 bg-ileads-green text-white rounded-btn h-12"
                  onClick={openFile}
                >
                  Ganti foto
                </button>
                <input
                  type="file"
                  ref={fileRef}
                  accept="image/jpg, image/jpeg, image/png"
                  className="hidden"
                  onChange={uploadFile}
                />
              </div>
            </div>
            <div className="space-y-4">
              {/* team */}
              <div className="flex flex-col space-y-1 relative">
                <p>Team:</p>
                <div className="input-primary flex pl-3.5 items-center w-80">
                  {user?.team1_name || ""}
                  {(user?.team2_name && `, ${user?.team2_name}`) || ""}
                  {(user?.team3_name && `, ${user?.team3_name}`) || ""}
                </div>
                <div
                  className="absolute right-0 top-[-8px]"
                  style={{ right: "-10px" }}
                >
                  <button
                    className="bg-ileads-green text-white py-2.5 text-center rounded-btn w-24 ml-2"
                    onClick={() => navigate("change-teams")}
                  >
                    Ganti
                  </button>
                </div>
              </div>

              {/* posisi winning */}
              <div className="flex flex-col space-y-1 relative">
                <p>Posisi Winning Team:</p>
                <div className="input-primary flex pl-3.5 items-center w-80">
                  {user.user_position === "cp" && (
                    <span>Counter Part (CP)</span>
                  )}
                  {user.user_position === "ca" && (
                    <span>Culture Agent (CA)</span>
                  )}
                  {user.user_position === "cl" && (
                    <span>Culture Leader (CL)</span>
                  )}
                  {user.user_position === "nwt" && (
                    <span>Non Winning Team (NWT)</span>
                  )}
                </div>
                <div
                  className="absolute right-0 top-[-8px]"
                  style={{ right: "-10px" }}
                >
                  <button
                    className="bg-ileads-green text-white py-2.5 text-center rounded-btn w-24 ml-2"
                    onClick={() => navigate("change-position")}
                  >
                    Ganti
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* right */}
          <div className="flex flex-col space-y-5">
            <div className="flex flex-col space-y-1">
              <p>Nama Panggilan:</p>
              <input
                name="nickname"
                onChange={(e) => handleChangeField(e)}
                className="input-primary text-center w-80"
                value={profile.nickname}
              />
            </div>

            <div className="flex flex-col space-y-1">
              <p>Alamat email:</p>
              <input
                className="input-primary text-center w-80"
                value={profile.email}
                name="email"
                onChange={(e) => handleChangeField(e)}
              />
            </div>

            <div className="flex flex-col space-y-1 relative">
              <p>Password:</p>
              <div className="input-primary w-80 flex items-center space-x-1.5 px-4">
                <Image src={InvisiblePasswordIcons} />
                <input
                  className="input-password-custom text-center w-full pr-10"
                  type="password"
                  readOnly
                  value="Testing"
                />
                <div
                  className="absolute right-0 top-0"
                  style={{ right: "-10px" }}
                >
                  <button
                    className="bg-ileads-green text-white py-2.5 text-center rounded-btn w-24 ml-2"
                    onClick={() => navigate("change-password")}
                  >
                    Ganti
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="justify-center flex text-white">
          <button
            disabled={!isUpdateProfile}
            className={
              isUpdateProfile
                ? "bg-ileads-lightblue px-8 py-3 w-fit rounded-full"
                : "px-8 py-3 w-fit bg-ileads-neutral-6 rounded-full cursor-not-allowed"
            }
            onClick={updateProfile}
          >
            Edit
          </button>
        </div>
      </CardWrapperPages>
      <div className="justify-end items-end flex mt-16">
        <button
          className={"bg-ileads-red px-8 py-2 w-fit rounded-full"}
          onClick={handleLogout}
        >
          <span className="text-white font-brandon_medium">Log Out</span>
        </button>
      </div>
    </div>
  );
}
