import * as yup from "yup";

export const RegisterSchema = yup.object({
  confirmEmail: yup.string()
    .required("E-mail tidak boleh kosong")
    .email("E-mail invalid")
    .oneOf([yup.ref("email"), null], "Email tidak sama"),
  email: yup.string()
    .required("E-mail tidak boleh kosong")
    .email("E-mail invalid"),
  password: yup.string()
    .required("Password tidak boleh kosong")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Password minimal 8 karakter, memiliki huruf besar dan kecil, serta memiliki angka dan simbol (!, %, &, dkk.)"
    )
}).required();
