import clientAPI from "./clientApi";

export const getMyLeaderBoard = async (id) => {
  const {
    data: { data }
  } = await clientAPI().get(`/v1/leaderboard/${id}`);

  return data.my_position;
};

export const getAllLeaderBoard = async () => {
  const {
    data: { data }
  } = await clientAPI().get("/v1/leaderboard");

  return data;
};
