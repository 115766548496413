import React from "react";
import { useAlert } from "react-alert";
import { Controller, useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";

import {
  FormInnerWrapper,
  FormLabel,
  FormWrapper,
  HintOuterWrapper,
  LoaderWrapper,
  OtpInputContainer,
  OtpInputStyle,
  ResendEmailText,
  Subtitle,
  TagWrapper,
  Title,
  Wrapper
} from "./index.styles";
import { API } from "../../Api";
import { PlaneIcon } from "../../Assets/Images";
import { AuthHeader, AuthHint, Button, Card, CircularLoader, Image } from "../../Components";
import { Constants } from "../../Constants";
import { Colors } from "../../Themes";
import { StorageAPI } from "../../Utils";

const {
  ErrorMessage: { GENERAL }
} = Constants;

export default function OtpVerifyPage({ page }) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const alert = useAlert();
  const {
    control,
    formState : { isSubmitting },
    getValues,
    handleSubmit
  } = useForm();
  const [loadingResendOtp, setLoadingResendOtp] = React.useState(false);
  const [otpData, setOtpData] = React.useState(state || {});

  React.useEffect(() => {
    if (!state) {
      navigate("/");
    }
  });

  const handleResendOtp = async () => {
    const { email } = otpData;
    setLoadingResendOtp(true);

    try {
      const { data: { data: responseData, message } } = await API.resendOtp({ email });
      setOtpData({ ...responseData, email });
      alert.success(message);
    } catch (err) {
      alert.show(err.response.data.message || GENERAL);
    } finally {
      setLoadingResendOtp(false);
    }
  };

  const getSignInVerifyPostData = () => {
    const { email, otp_hash, otp, user_id = "" } = otpData;

    return {
      email,
      otpCode: otp.toString(),
      otpHash: otp_hash,
      userId: user_id
    };
  };

  const getSignUpVerifyPostData = () => {
    const { email, otp_hash, otp } = otpData;

    return {
      email,
      otp_code: otp.toString(),
      otp_hash
    };
  };

  const handleMethodsVerify = async () => {
    try {
      const { data: { data: responseData } } = otpData.user_id ?
        await API.signInVerify(getSignInVerifyPostData()) :
        await API.signUpVerify(getSignUpVerifyPostData());
      StorageAPI.setItem("token", responseData.token);
      
      if (otpData.need_update_profile) {
        navigate("/create-profile", { state: responseData });
      } else {
        navigate("/homepage");
      }
    } catch (err) {
      alert.show(err.response.data.message || GENERAL);
    }
  };

  const handleVerifyOtp = async () => {
    if (otpData.otp != getValues("otp_code")) {
      alert.show("Kode OTP tidak sesuai");
    } else {
      await handleMethodsVerify();
    }
  };

  const renderHeader = () => (
    <React.Fragment>
      <TagWrapper>
        <Image
          src={ PlaneIcon }
          width={ 117 }
          height={ 117 }
          alt={ "iLead-Plane" }
        />
      </TagWrapper>
      <AuthHeader />
    </React.Fragment>
  );

  const renderOtpInput = () => (
    <OtpInputContainer>
      <Controller
        name="otp_code"
        control={ control }
        render={ ({ field }) => (
          <OtpInput
            value={ field.value }
            onChange={ field.onChange }
            numInputs={ 4 }
            shouldAutoFocus
            isInputNum
            inputStyle={ OtpInputStyle }
          />
        ) }
      />
    </OtpInputContainer>
  );

  const renderResendOtp = () => (
    <React.Fragment>
      { loadingResendOtp ? (
        <LoaderWrapper>
          <CircularLoader
            size={ 25 }
            color={ Colors.lightBlue }
            unfilledColor={ Colors.white }
          />
        </LoaderWrapper>
      ) : (
        <ResendEmailText onClick={ handleResendOtp }>
          Kirim ulang e-mail verifikasi
        </ResendEmailText>
      ) }
    </React.Fragment>
  );

  const renderOtpFillForm = () => (
    <FormInnerWrapper onSubmit={ handleSubmit(handleVerifyOtp) }>
      <Title>Selamat datang di iLEAD!</Title>
      <Subtitle>
        Berhasil! Kami sudah mengirimkan 4 digit nomor verifikasi ke alamat e-mailmu.
      </Subtitle>
      <FormLabel>Masukkan 4 digit nomor dari e-mail verifikasi:</FormLabel>
      { renderOtpInput() }
      <Button
        text={ page === "login" ? "Masuk" : "Verifikasi e-mail ini" }
        bgColor={ Colors.green }
        width={ 290 }
        paddingVertical={ 10 }
        fontSize={ 22 }
        isLoading={ isSubmitting }
      />
      { renderResendOtp() }
    </FormInnerWrapper>
  );

  return (
    <Wrapper>
      { renderHeader() }
      <FormWrapper>
        <Card
          isUpperCard
          content={ renderOtpFillForm() }
        />
      </FormWrapper>
      <HintOuterWrapper>
        <AuthHint />
      </HintOuterWrapper>
    </Wrapper>
  );
}
