import * as yup from "yup";

export const AddCoachingJournalSchema = yup.object({
  date: yup.string().required("Tidak boleh kosong"),
  title: yup.string().required("Tidak boleh kosong"),
  content: yup.string().required("Tidak boleh kosong"),
  strength: yup.string().required("Tidak boleh kosong"),
  improvement: yup.string().required("Tidak boleh kosong"),
  type: yup.string().required(""),
  label: yup.string().when("type", {
    is: (value) => value === "Others",
    then: yup.string().required()
  }),
  recommendationForCoachee: yup.string().required("Tidak boleh kosong"),
  learnerIds: yup.array().of(yup.string()).min(1),
  documentsUrl: yup.string()
});
