import { ReactComponent as CloseIcon } from "Assets/Icons/round-close.svg";
import { AttachImage, ConfuseMan, HappyIcon } from "Assets/Images";
import Button from "Components/Button";
import Modal from "Components/Modal";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Colors } from "Themes";

const UploadFile = ({ max, accept, fileDesc, disabled, onChange, onError, className, value, isDownload }) => {
  const fileRef = useRef(null);
  const [fileName, setFileName] = useState("");
  const [isError, setIsError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleDownload = () => {
    if (!value?.length) return;
    const anchor = document.createElement("a");
    anchor.href = value[0];
    anchor.click();
    anchor.remove();
    window.URL.revokeObjectURL(value[0]);
  };

  const handleClearFile = useCallback(() => {
    if (!fileRef.current.value) setShowModal(false);
    fileRef.current.value = "";
    onChange(null);
    setFileName("");
  }, [onChange]);

  const handleFileChange = useCallback(
    ({ target }) => {
      const file = target.files[0];
      setIsError(false);

      if (file.size / (1024 * 1024) > 2 || !accept.includes(file.name.split(".").pop())) {
        handleClearFile();
        setIsError(true);
        onError();
        return;
      }

      onChange(file);
      setFileName(file.name);
    },
    [accept, onChange, onError, handleClearFile]
  );

  useEffect(() => {
    setFileName("");
    if (value?.length) setFileName(value[0].split("/").pop());
  }, [value]);

  return (
    <div className={`flex flex-col items-end${className ? ` ${className}` : ""}`}>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        title={fileName ? "Kamu akan menghapus lampiran." : "Sukses!"}
        desc={
          fileName
            ? "Unggahan lampiran kamu akan dihapus. Apakah kamu<br>yakin ingin menghapus lampiran?"
            : "Lampiran telah sukses dihapuskan!"
        }
        icon={<img className={fileName ? "w-[400px]" : "w-[160px]"} src={!fileName ? HappyIcon : ConfuseMan} alt="" />}
        buttons={
          <>
            {fileName && (
              <Button
                type="button"
                onClick={() => setShowModal(false)}
                text="Batalkan"
                bgColor={Colors.lightBlue}
                disabled={disabled}
                width={124}
                paddingVertical={12}
                fontSize={20}
              />
            )}
            <Button
              type="button"
              onClick={handleClearFile}
              text={fileName ? "Hapus" : "Kembali ke Catatan"}
              bgColor={Colors.green}
              disabled={disabled}
              width={fileName ? 124 : 218}
              paddingVertical={12}
              fontSize={20}
            />
          </>
        }
      />
      <input
        ref={fileRef}
        type="file"
        max={max}
        accept={accept?.split(" dan ").join(" ")}
        className="hidden"
        onChange={handleFileChange}
      />
      <div className="flex items-center">
        {fileName && (
          <div className="flex items-center m-2">
            {!disabled && !isDownload && (
              <button type="button" onClick={() => setShowModal(true)}>
                <CloseIcon className="mr-1" />
              </button>
            )}
            <img src={AttachImage} alt="" className="mr-1" />
            <span
              title={fileName}
              className="text-sm font-brandon max-w-[127px] text-ellipsis overflow-hidden whitespace-nowrap"
            >
              {fileName}
            </span>
          </div>
        )}
        <Button
          type="button"
          className="!mx-0"
          onClick={() => {
            isDownload ? handleDownload() : fileRef.current?.click();
          }}
          text={isDownload ? "Unduh Lampiran" : "Lampirkan Dokumen"}
          bgColor={!disabled ? Colors.yellow : Colors.lightGrey}
          textColor={Colors.darkBlue}
          disabled={disabled || (isDownload && !fileName)}
          width={161}
          paddingVertical={8}
          fontSize={16}
        />
      </div>
      {!disabled && !isDownload && (
        <span className={`text-sm font-brandon ${isError ? "text-ileads-danger-3" : "text-ileads-darkblue"}`}>
          Lampiran maksimal berukuran {max} MB dan dapat berupa {fileDesc}.
        </span>
      )}
    </div>
  );
};

export default UploadFile;
