import * as yup from "yup";

export const UpdateJournalCoachSchema = yup
  .object({
    content: yup.string().required("Tidak boleh kosong"),
    strength: yup.string().required("Tidak boleh kosong"),
    improvement: yup.string().required("Tidak boleh kosong"),
    type: yup.string().required(""),
    label: yup.string().when("type", {
      is: (value) => value === "Others",
      then: yup.string().required()
    }),
    recommendationForCoachee: yup.string().required("Tidak boleh kosong"),
    documentsUrl: yup.string()
  })
  .required();
