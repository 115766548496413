import styled from "styled-components";

import { Colors } from "../../Themes";

export const Wrapper = styled.div`
  background-color: ${Colors.white};
  border-radius: 47px;
  box-shadow: 6px 7px 8px -1px rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: ${({ isUpperCard }) => isUpperCard && "0px"};
  border-bottom-right-radius: ${({ isUpperCard }) => isUpperCard && "0px"};
  border-bottom-left-radius: ${({ isUpperCard }) => isUpperCard && "0px"};
  box-shadow: ${({ isUpperCard }) => isUpperCard && "6px 0px 8px -1px rgba(0, 0, 0, 0.25)"};
`;
