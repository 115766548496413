/* eslint-disable */
import { request } from "./request";
import { StorageAPI } from "../Utils";

const { REACT_APP_BASE_API_URL } = process.env;

export const APIRequest = (path, method, data, isFormData = false) => {
  const authToken = StorageAPI.getItem("token");
  const requestData = {
    method,
    data: data || undefined
  };
  const requestMethod = request(authToken, isFormData);

  return requestMethod(`${REACT_APP_BASE_API_URL}${path}`, requestData);
};
