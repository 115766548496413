/* eslint-disable jsx-a11y/label-has-associated-control */
import { useQuestionnaire } from "Context/questionnaireContext";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { choiceLabels } from "./choiceLabel";

const QuestionnaireTabInput = ({ question, label, description, disabled, onChange = () => {} }) => {
  const { setPayload } = useQuestionnaire();
  const indicator = useRef(null);
  const containerChoice = useRef(null);
  const [selected, setSelected] = useState(null);
  const [selectedEl, setSelectedEl] = useState(null);

  const colors = [
    {
      background: "bg-ileads-yellow",
      gHover: "group-hover:text-black group-hover:bg-ileads-yellow",
      text: "text-black"
    },
    {
      background: "bg-ileads-green",
      gHover: "group-hover:text-black group-hover:bg-ileads-green",
      text: "text-black"
    },
    {
      background: "bg-ileads-lightblue",
      gHover: "group-hover:text-black group-hover:bg-ileads-lightblue",
      text: "text-black"
    },
    {
      background: "bg-ileads-mainblue",
      gHover: "group-hover:text-white group-hover:bg-ileads-mainblue",
      text: "text-white"
    },
    {
      background: "bg-ileads-darkblue",
      gHover: "group-hover:text-white group-hover:bg-ileads-darkblue",
      text: "text-white"
    }
  ];

  const handleIndicatorMove = (el, current) => {
    if (!el || !current) return;
    el.classList.remove("opacity-0");

    el.style.width = `${current.offsetWidth}px`;
    el.style.height = `${current.offsetHeight}px`;
    el.style.left = `${current.offsetLeft}px`;
  };

  const handleChoiceHover = useCallback(
    (e) => {
      const indicatorEl = indicator.current;
      const currentDetail = e.target;

      if (e.type === "mouseleave") {
        if (selected !== null && selectedEl) handleIndicatorMove(indicatorEl, selectedEl);
        else indicatorEl.classList.add("opacity-0");
        return;
      }

      handleIndicatorMove(indicatorEl, currentDetail);
    },
    [selected, selectedEl]
  );

  useEffect(() => {
    if (selected === null || !containerChoice?.current) return;
    const indicatorEl = indicator.current;

    const listButtons = Array.from(containerChoice.current.querySelectorAll("button"));
    const selectedButton = listButtons[selected];
    setSelectedEl(selectedButton);

    handleChoiceHover({ e: "fill", target: selectedButton });

    return () => {
      setSelectedEl(null);
      indicatorEl.classList.add("opacity-0");
    };
  }, [selected, handleChoiceHover]);

  useEffect(() => {
    setSelected(typeof question.point === "number" ? question.point - 1 : null);
  }, [question]);

  return (
    <div className="relative" ref={containerChoice}>
      <strong className="text-sm block text-ileads-darkblue" dangerouslySetInnerHTML={{ __html: label }} />
      {description && (
        <div
          className="text-sm mt-2 pl-6 leading-5 break-words max-w-[850px] prose"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      <div className="flex pl-5 mt-3">
        {question.type !== "essay" ? (
          choiceLabels[question.type]?.map((choice, idx) => (
            <button
              type="button"
              key={idx}
              onClick={() => {
                if (disabled) return;
                onChange(idx + 1);
                question.point = idx + 1;
                setPayload((prevValue) => ({ ...prevValue }));
                setSelected(idx);
              }}
              onMouseLeave={(e) => {
                if (!disabled) handleChoiceHover(e);
              }}
              onMouseEnter={(e) => {
                if (!disabled) handleChoiceHover(e);
              }}
              className={`w-fit flex group font-brandon_medium z-20 items-center text-sm rounded-full py-2 px-3 ${
                disabled ? "cursor-not-allowed" : ""
              }`}
            >
              <div
                className={`
                w-[17px]
                h-[17px]
                mr-2
                rounded-full
                flex
                text-xs
                items-center
                justify-center
                transition-all
                duration-700
                ${
                  selected === idx
                    ? `${colors[selected].background} ${colors[selected].text}`
                    : "bg-gray-100 text-gray-300"
                }
                ${!disabled ? colors[idx].gHover : ""}
              `}
              >
                {idx + 1}
              </div>
              <span className="text-xs" dangerouslySetInnerHTML={{ __html: choice.text }} />
            </button>
          ))
        ) : (
          <textarea
            value={question.point}
            onChange={(e) => {
              onChange(e.target.value);
              question.point = e.target.value;
              setPayload((prevValue) => ({ ...prevValue }));
            }}
            className="w-full rounded-2xl border-2 border-ileads-mainblue resize-none h-32"
            placeholder="Tuliskan masukan anda..."
          />
        )}
        <div
          ref={indicator}
          className="
            bg-ileads-bgblue
            pointer-events-none
            z-10
            duration-500
            rounded-full
            absolute
            opacity-0
            transition-all
          "
        />
      </div>
    </div>
  );
};

export default React.memo(QuestionnaireTabInput);
