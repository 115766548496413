import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import { NavbarContainer, TagWrapper } from "./index.styles";
import { PlaneIcon } from "../../Assets/Images";
import Image from "../Image";

const Menus = [
  {
    name: "Beranda",
    path: "homepage"
  },
  {
    name: "Coaching Journal",
    path: "coaching-journals"
  },
  {
    name: "Feedback",
    path: "feedback"
  },
  {
    name: "Feed",
    path: "feeds"
  },
  {
    name: "Idea Pools",
    path: "idea-pools"
  },
  {
    name: "JUARA Quiz",
    path: "juara-quiz"
  }
];

export default function Navbar() {
  const { pathname } = useLocation();

  const indicatorRef = useRef(null);

  useEffect(() => {
    const elementInfo = Array.from(document.querySelectorAll(".nav__link")).find((el) => pathname.includes(el.id));

    if (!elementInfo) indicatorRef.current.style.opacity = "0";
    else {
      indicatorRef.current.style.opacity = "1";
      indicatorRef.current.style.left = `${elementInfo.offsetLeft}px`;
      indicatorRef.current.style.width = `${elementInfo.offsetWidth}px`;
    }
  }, [pathname]);

  return (
    <NavbarContainer>
      <nav className="px-5 font-brandon h-full text-ileads-neutral-1 relative">
        <div className="flex items-center justify-between h-full">
          <ul className="flex items-center md:gap-7 lg:gap-10 xl:gap-20 2xl:gap-32 pl-10">
            {Menus.map((item, index) => (
              <li key={index}>
                <Link to={`/${item.path}`} id={item.path} className="nav__link text-xl font-brandon_bold">
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
          <div ref={indicatorRef} className="h-[10px] transition-all duration-500 absolute bottom-0 bg-ileads-yellow" />
          <TagWrapper>
            <Image src={PlaneIcon} width={117} height={117} alt={"iLead-Plane"} />
          </TagWrapper>
        </div>
      </nav>
    </NavbarContainer>
  );
}
