import React from "react";

import { ErrorWrapper, Message, Wrapper } from "./index.styles";
import { Colors } from "../../Themes";

const BaseIcon = ({ color, pushRight = true, children }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={ color }
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={ { marginRight: pushRight ? "20px" : "0", minWidth: 24 } }
  >
    { children }
  </svg>
);

const ErrorIcon = () => (
  <BaseIcon color={ Colors.white }>
    <circle cx="12" cy="12" r="10" />
    <line x1="12" y1="8" x2="12" y2="12" />
    <line x1="12" y1="16" x2="12" y2="16" />
  </BaseIcon>
);

const AlertTemplate = ({ options, message, close }) => {
  return (
    <Wrapper type={ options.type }>
      <ErrorWrapper>
        <ErrorIcon />
      </ErrorWrapper>
      <Message> { message }</Message>
    </Wrapper>
  );
};

export default AlertTemplate;
