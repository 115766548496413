import { useQuestionnaire } from "Context/questionnaireContext";
import React from "react";

const QuestionnaireDescription = () => {
  const { culture } = useQuestionnaire();

  const formatedDescriptions = culture?.description?.split("{{objective}}") || [];

  return (
    <div className="text-sm font-brandon">
      <p dangerouslySetInnerHTML={{ __html: formatedDescriptions[0] }} />
      <div className="flex bg-ileads-bgblue w-fit mx-auto rounded-full my-2">
        {culture?.culture_measurement_objectives?.map((label, idx) => (
          <div
            key={idx}
            className="
              w-fit
              flex
              font-brandon_medium
              z-20
              items-center
              text-sm
              rounded-full
              py-2
              px-3
            "
          >
            <span
              className={`
              w-[17px]
              h-[17px]
              mr-2
              rounded-full
              flex
              text-xs
              items-center
              justify-center
              ${label.color}
            `}
            >
              {idx + 1}
            </span>
            {label.cm_objective_title}
          </div>
        ))}
      </div>
      <p dangerouslySetInnerHTML={{ __html: formatedDescriptions[1] }} />{" "}
    </div>
  );
};

export default QuestionnaireDescription;
