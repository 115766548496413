import * as yup from "yup";

export const AddCoachingJournalFeedbackSchema = yup.object({
  q1: yup.number().required("Tidak boleh kosong"),
  q2: yup.number().required("Tidak boleh kosong"),
  q3: yup.number().required("Tidak boleh kosong"),
  q4: yup.number().required("Tidak boleh kosong"),
  q5: yup.number().required("Tidak boleh kosong"),
  q6: yup.number().required("Tidak boleh kosong")
});
